import React, { useState, useReducer, useEffect } from "react";
import { IntegrationScroller } from './IntegrationScroller';
import { addNewIntegrationReducer } from "../../reducers/addNewIntegration";
import { AddNewIntegrationContext } from "../../context/AddNewIntegrationContext";
import { AddNewIntegrationDispatchContext } from "../../context/AddNewIntegrationDispatchContext";
import Button, { ButtonShape } from "../../baseComponents/Button";
import { Integration_Type } from "../../../generated/graphql";
import integrationLogos from "../../../assets/integration-logos.png";
import { paramsToObject } from "../../pages/IntegrationsPage";

interface AddNewIntegrationCardProps {
  integrations: Integration_Type[];
}

export const AddNewIntegrationCard = (props: AddNewIntegrationCardProps) => {
    const [integrationScrollerState, dispatch] = useReducer(addNewIntegrationReducer, { isIntegrationScrollerOpen: false });
    const [urlParams, setUrlParams] = useState<URLSearchParams>(new URLSearchParams());
    const [originalIntegrations] = useState<Integration_Type[]>(props.integrations);
    const [integrations, setIntegrations] = useState<Integration_Type[]>(props.integrations);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        console.log(`Url params in AddNewIntegrationCard: ${urlParams}`);
        setUrlParams(urlParams);
        const urlObject = paramsToObject(urlParams?.entries());
        
        const { authToken, key: scraperKey, teamId: teamIdQuery, ...others } = urlObject;
        if (authToken && scraperKey) {
            dispatch({type: 'openIntegrationScroller'});

        }
        //clear url params
        window.history.replaceState({}, '', window.location.pathname);
    }, [])

    return (
        <AddNewIntegrationContext.Provider value={integrationScrollerState}>
            <AddNewIntegrationDispatchContext.Provider value={dispatch}>
                <div className="px-0 lg:pl-20 xl:px-20" id="add-new-integration-card">
                    <div className="bg-silver rounded-md pt-8 pb-11 px-8 text-center flex flex-col items-center gap-12">
                        <h3 className="font-semibold text-2xl text-blueberry">Add new integration</h3>
                        <div className="relative">
                            <div className="bg-[linear-gradient(to_right,rgba(247,247,247,0),rgba(247,247,247,1))] absolute right-0 w-1/2 h-full top-0"></div>
                            <div>
                                <img src={integrationLogos} alt="" />
                            </div>
                            <div className="bg-[linear-gradient(to_left,rgba(247,247,247,0),rgba(247,247,247,1))] absolute left-0 w-1/2 h-full top-0"></div>
                        </div>
                        <Button onClick={() => {dispatch({type: 'openIntegrationScroller'})}} text="New integration +" shape={ButtonShape.Pill}/>
                    </div>
                    {integrationScrollerState.isIntegrationScrollerOpen && 
                        <IntegrationScroller 
                            integrations={integrations} 
                            urlParams={urlParams}
                        />
                    }
                </div>
            </AddNewIntegrationDispatchContext.Provider>
        </AddNewIntegrationContext.Provider>
    );
};