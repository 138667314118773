import { useEffect, useState } from 'react';
import { ChartData, ChartDataset, ChartOptions, CoreScaleOptions, Scale, Tick } from 'chart.js';
import {
  Breakdown,
  BreakdownInput,
  Chart,
  Chart_Type,
  ChartFragment,
  GetCustomChartsQuery,
  Label,
  PlotFragment,
  PlotSeries,
  SeriesData,
  Y_Axis_Data,
} from '../../../generated/graphql';
import { ArrayElement } from '../../../utilities';
import { YAxisUnit } from '../../../v2/hooks/ChartHook';
import { getTooltipCallbacks } from './chartLabelStrategies';
import { truncateAndEllipsis } from '../../../v2/util';
import PlotView from './PlotView';
import { areAllChartValuesZero } from '../../lib/chart';
import LoadingSpinner from '../../baseComponents/LoadingSpinner';

const isHorizontalChart = (chartType: Chart_Type) => {
  return chartType === Chart_Type.HorizontalBar;
};
const isPercentageChart = (yAxisData: Y_Axis_Data) => yAxisData === Y_Axis_Data.Favorability || yAxisData === Y_Axis_Data.RelativeShare;
export const chartColors = [
  '#E06D87',
  '#414880',
  '#FEC6A3',
  '#C29ADD',
  '#F49EC4',
  '#D2D3FF',
  '#887AB9',
  '#C0BCD0',
  '#FCE7B4',
  '#F8A691',
  '#34495E',
  '#F5B7B1',
  '#7D3C98',
  '#F8C471',
  '#F0B27A',
  '#1ABC9C',
  '#E67E22',
  '#5D6D7E',
  '#AF7AC5',
];

const sentimentColorMap: Record<string, string> = {
  Negative: '#E06D87',
  Neutral: '#F5DB9C',
  Positive: '#4BACB2',
};

const npsBucketColorMap: Record<string, string> = {
  detractor: '#E06D87',
  passive: '#F5DB9C',
  promoter: '#4BACB2',
};

export const isCategoricalChart = (chartType: Chart_Type) => chartType === Chart_Type.Pie || chartType === Chart_Type.HorizontalBar;
const isStackedChart = (chartType: Chart_Type) => chartType === Chart_Type.StackedBar || chartType === Chart_Type.StackedArea;

/**
 * determine if the data set we have is an NPS breakdown. This is a bit of a hack because we don't have a distinct concept of an NPS breakdown in our data model.
 * so we have to snoop the breakdown from the labels
 */
const getIsNpsBreakdown = (breakdownLabels: Label[]) => {
  const npsNames = ['detractor', 'passive', 'promoter'];
  return breakdownLabels.length === 3 && breakdownLabels.every((obj, idx) => obj.name.toLowerCase() === npsNames[idx]);
};

/**
 *  What are we doing here??? NPS buckets and sentiment colors are being generated for each series?
 *
 */
const generateDataset = (
  data: PlotSeries[],
  xAxisLabels: string[],
  chartType: Chart_Type,
  breakdown?: Breakdown | null,
  fill?: boolean,
  differentColorPerBucket?: boolean
): ChartDataset[] => {
  const isNpsBreakdown = getIsNpsBreakdown(data.map((ser) => ser.seriesLabel));
  let colorBucket: string[] = [];
  if (differentColorPerBucket) {
    if (breakdown === Breakdown.Sentiment) {
      colorBucket = xAxisLabels.map((label) => sentimentColorMap[label]) || [];
    } else if (breakdown === Breakdown.Segment && isNpsBreakdown) {
      colorBucket = xAxisLabels.map((label) => npsBucketColorMap[label.toLowerCase()]) || [];
    } else {
      colorBucket = chartColors;
    }
  }

  if (isCategoricalChart(chartType)) {
    const colors = chartType === Chart_Type.HorizontalBar && breakdown !== Breakdown.Sentiment ? chartColors[0] : data.map((_, idx) => colorBucket[idx]);
    const dataSet: ChartDataset = {
      data: data.map((ser) => ser.dataSeries[0]),
      label: '',
      backgroundColor: colors,
      fill: false,
    };
    return [dataSet];
  }

  const datasets = data.map((curData, idx) => {
    let labelName: string | undefined = curData.seriesLabel.name;
    let color: string = chartColors[idx];
    if (labelName) {
      if (breakdown === Breakdown.Sentiment) color = sentimentColorMap[labelName];
      else if (breakdown === Breakdown.Segment && isNpsBreakdown) color = npsBucketColorMap[labelName.toLowerCase()];
    }

    return {
      label: labelName ?? '',
      data: curData.dataSeries,
      backgroundColor: differentColorPerBucket ? colorBucket : color,
      borderColor: differentColorPerBucket ? colorBucket : color,
      fill,
    };
  });

  return datasets;
};

export interface PlotProps {
  plotData: PlotFragment;
  showLegend?: boolean;
  loading: boolean;
  onSeriesClick?: (seriesIndex: number) => void;
}

export const Plot = ({ plotData, loading, showLegend = true, onSeriesClick }: PlotProps) => {
  const [chartData, setChartData] = useState<ChartData>();
  const [isChartEmpty, setIsChartEmpty] = useState<boolean>(false);
  /*
  We're using a randKey to force a re-render of the chart when the data changes.
  This is a workaround because Charts end up in broken states if we just pass updated data. Needs to be investigated further.
  */
  const [randKey, setRandKey] = useState<number>(Math.random());

  useEffect(() => {
    if (plotData.series && plotData.series.length > 0) {
      //   let labels: string[] = plotData.series[0].chartLabels; //Labels should be the same for all. Maybe we can add a check?
      let labels: string[] = isCategoricalChart(plotData.plotConfiguration.chartType)
        ? plotData.series.map((series) => series.seriesLabel.name)
        : plotData.xAxisLabels;
      let datasets: ChartDataset[] = [];
      // why was there a map here???
      //   plotData.series?.map((data, i) => {
      //Absolute Chart -> Aggregate Data ||| Percentage Chart -> Normalized Data
      datasets.push(
        ...generateDataset(
          plotData.series,
          plotData.xAxisLabels,
          plotData.plotConfiguration.chartType,
          plotData.plotConfiguration.breakdown,
          plotData.plotConfiguration.chartType === Chart_Type.StackedArea,
          plotData.plotConfiguration.chartType === Chart_Type.Pie ||
            (plotData.plotConfiguration.chartType === Chart_Type.HorizontalBar && plotData.plotConfiguration.breakdown === Breakdown.Sentiment)
        )
      );
      //   });
      const newChartData = { labels, datasets };
      setIsChartEmpty(areAllChartValuesZero(plotData.series));
      setChartData(newChartData);
      if (JSON.stringify(newChartData) !== JSON.stringify(chartData)) {
        setRandKey(Math.random());
      }
    }
  }, [plotData]);

  return (
    <div className="h-full">
      {isChartEmpty ? (
        <div className="flex flex-col items-center justify-center h-full px-2">
          <div className="text-center text-gray-500">No data matches the selected configurations and filters</div>
        </div>
      ) : chartData ? (
        <div className="relative h-full">
          <PlotView
            key={randKey}
            type={plotData.plotConfiguration.chartType}
            data={chartData}
            options={getChartOptions(
              plotData.plotConfiguration.yAxisMetric === Y_Axis_Data.RelativeShare
                ? YAxisUnit.CustomPercentage
                : plotData.plotConfiguration.yAxisMetric === Y_Axis_Data.Favorability
                ? YAxisUnit.Favorability
                : YAxisUnit.CustomAbsolute,
              plotData.plotConfiguration.chartType,
              plotData.tooltipLabels,
              //plotData.series.map((ser) => ser.seriesLabel.name),
              plotData.xAxisLabels
            )}
            hideLegends={!showLegend}
            onDataPointClick={onSeriesClick}
          />
          {loading && (
            <div className="absolute inset-0 bg-gray-500/30 z-10 flex items-center justify-center rounded-md animate-pulse">
              <LoadingSpinner />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

/**
 * This is some SCARY code... I have no idea what these options are controlling.
 * @returns
 */
const getChartOptions = (yAxisUnit: YAxisUnit, chart_type: Chart_Type, fullLabels: string[], chartLabels: string[]): ChartOptions => {
  const options: ChartOptions = {
    animations: {
      numbers: { duration: 500 },
    },
    layout: {
      padding: {
        top: chart_type === Chart_Type.Pie ? 10 : 0,
        bottom: 0,
      },
    },
    indexAxis: chart_type === Chart_Type.HorizontalBar ? 'y' : 'x',

    //@ts-ignore
    lineTension: 0.3,
    interaction: {
      mode: 'nearest',
      //This prevents incorrect tooltip positioning for horizontal bar charts
      axis: chart_type === Chart_Type.HorizontalBar ? 'y' : 'xy',
      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      annotation: {
        interaction: {
          intersect: false,
        },
      },
      tooltip: {
        // control tooltip visibility if all values are zero
        filter: function (tooltipItem) {
          const dataset = tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
          const data = dataset.data[tooltipItem.dataIndex];
          if (!data) {
            return false;
          }
          // If all values are zero, return false to hide tooltip.
          for (let i = 0; i < Number(data.valueOf()); i++) {
            if (data !== 0) {
              return true;
            }
          }
          return false;
        },
        backgroundColor: '#FFF',
        bodyColor: '#292E5B',
        titleColor: '#292E5B',
        footerColor: '#292E5B',
        mode: 'index',
        position: 'custom',
        intersect: false,
        enabled: true,
        titleFont: {
          family: 'SofiaPro',
          size: 10,
        },

        titleMarginBottom: 1,
        bodyFont: {
          family: 'SofiaPro',
          size: 12,
        },
        padding: 10,
        footerFont: {
          size: 10,
          family: 'SofiaPro',
          style: 'italic',
          weight: 'normal',
        },
        borderWidth: 1,
        borderColor: '#292E5B',
        footerMarginTop: 1,
        // sort visible tooltip items in descending order
        itemSort(a, b, data) {
          return Number(b.formattedValue) - Number(a.formattedValue);
        },
        callbacks: getTooltipCallbacks(chart_type, yAxisUnit, fullLabels),
      },
    },
    elements: {
      point: {
        radius: 3,
      },
      bar: {
        borderRadius: 4,
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: chart_type === Chart_Type.StackedBar,
        display: chart_type === Chart_Type.Pie ? false : true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 7,
          display: true,
          maxRotation: 0,
          color: '#6B7280',
          font: {
            size: 12,
            family: 'SofiaPro',
          },
          callback: function (value: string | number, index: number, values: Tick[]) {
            // we do this because the 'x' axis for horizontal bar charts is actually the 'y' axis in the data
            // so we need to display the value from the data array instead of the labels array
            // for other chart types we just pass the chart labels
            if (isHorizontalChart(chart_type)) {
              if (yAxisUnit === YAxisUnit.CustomPercentage || yAxisUnit === YAxisUnit.Favorability) {
                return `${value}%`;
              } else {
                return value.toLocaleString();
              }
            } else {
              return chartLabels[index];
            }
          },
        },
      },

      ...(chart_type === Chart_Type.HorizontalBar && {
        y: {
          display: true,

          grid: {
            display: false,
          },

          ticks: {
            display: true,
            color: '#6B7280',
            font: {
              size: 12,
              family: 'SofiaPro',
            },
            callback: function (value: string | number, index: number, values: Tick[]) {
              const label = this.getLabelForValue(Number(value));
              return truncateAndEllipsis(label, 40);
            },
            // Add these options to force all labels to show
            autoSkip: false,
          },
        },
      }),
      ...(yAxisUnit === YAxisUnit.CustomAbsolute &&
        !isCategoricalChart(chart_type) && {
          mentions: {
            stacked: isStackedChart(chart_type),
            position: 'left',
            grid: {
              display: true,
              color: '#f1f1f1',
              drawBorder: false,
            },
            ticks: {
              padding: 5,
              precision: 0,
              autoSkip: false,
              color: '#6B7280',
              font: {
                size: 12,
                family: 'SofiaPro',
              },
            },
            beginAtZero: true,
          },
        }),
      ...((yAxisUnit === YAxisUnit.CustomPercentage || yAxisUnit === YAxisUnit.Favorability) &&
        !isCategoricalChart(chart_type) && {
          percentage: {
            stacked: isStackedChart(chart_type),
            position: 'left',
            grid: {
              display: true,
              color: '#f1f1f1',
              drawBorder: false,
            },
            display: true,
            ticks: {
              padding: 15,
              autoSkip: false,
              color: '#6B7280',
              font: {
                size: 12,
                family: 'SofiaPro',
              },
              callback: function (value: string | number, index: number, values: Tick[]) {
                return value + '%';
              },
            },
            beginAtZero: true,
            afterDataLimits: (scale: Scale<CoreScaleOptions>) => {
              const datasets = scale.chart.data.datasets;

              //If there's a breakdown, get the max but with each point's values added together
              if (datasets && datasets.length >= 1 && isStackedChart(chart_type)) {
                const dataValues = datasets[0].data.map((_: any, index: number) =>
                  datasets.reduce((acc: number, cur: any) => {
                    return acc + cur.data[index];
                  }, 0)
                );
                const maxDataPoint = +Math.max(...dataValues).toFixed(1);

                scale.max = Math.max(maxDataPoint, 100);
                scale.min = 0;
              } else {
                //If no breakdown, get the max of all datasets
                const dataValues = datasets.flatMap((dataset) => dataset.data) as number[];
                const maxDataPoint = Math.max(...dataValues);
                const minDataPoint = Math.min(...dataValues);

                let padding;
                if (maxDataPoint < 10 && maxDataPoint - minDataPoint < 3) {
                  padding = 0.1;
                } else {
                  padding = 5;
                }

                scale.max = Math.min(maxDataPoint + padding, 100);
                scale.min = 0;
              }
            },
          },
        }),
    },
  };
  return options;
};
