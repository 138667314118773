import { client } from '../../client';
import { AmountOfEntriesQuery, AmountOfEntriesDocument, GetPartialEntriesQuery, PartialEntryFragment } from '../../generated/graphql';
import { GetEntriesInPages } from '../paginated_queries/GetEntriesInPages';
import { ParallelPagination } from '../paginated_queries/ParallelPagination';
import { GraphDataProvider } from './GraphDataProvider';
import { ApolloQueryResult } from '@apollo/client';
import { ProgressMonitor, ProgressState, Status } from '../ProgressMonitor';
import { EXPORT_LIMIT_EXCEEDED } from '../Errors';

const maxRecords = 100_000; // the max number of entries we allow users to fetch for report generation.

/**
 * The EntryFetcher fetches feedback_entries for the specified groupIds using the current filters.
 * It determines the total records it must fetch and then executes several batches in parallel.
 */
export class EntryFetcher implements GraphDataProvider<PartialEntryFragment[]>, ProgressMonitor {
  private teamId: number;
  private pageSize: number;
  private numThreads: number;
  private filterStateConsumable: string;
  private parallelExecution: ParallelPagination<ApolloQueryResult<GetPartialEntriesQuery>> | undefined;

  constructor(params: { teamId: number; filterStateConsumable: string; pageSize: number; numThreads: number }) {
    this.teamId = params.teamId;
    this.filterStateConsumable = params.filterStateConsumable;
    this.pageSize = params.pageSize;
    this.numThreads = params.numThreads;
  }

  /**
   * Returns the total number of records (feedback_entries) to fetch.
   * @param params
   * @returns
   */
  private async getTotalRecordCount(): Promise<ApolloQueryResult<AmountOfEntriesQuery>> {
    return client.query<AmountOfEntriesQuery>({
      fetchPolicy: 'no-cache',
      query: AmountOfEntriesDocument,
      variables: {
        teamId: this.teamId,
        filterStatement: this.filterStateConsumable,
      },
    });
  }

  public getProgress(): ProgressState {
    if (!this.parallelExecution) {
      return { status: Status.idle, percent: 0 };
    }

    return this.parallelExecution!.getProgress();
  }

  /**
   * Invoke fetching entries in batches of promises. Results are aggregated and collected on the entryQuery object
   *
   * Currently we fetch up to 3 pages of data in parallel. If this number is too large, it will throttle the DB's CPU.
   *
   * @returns entries fetched from the Unwrap GraphAPI
   */
  async getData(): Promise<PartialEntryFragment[]> {
    const recordCountResponse = await this.getTotalRecordCount();
    const totalRecords = recordCountResponse.data.amountOfEntries.amount;

    if (totalRecords <= 0) {
      return new Promise((resolve, reject) => resolve([]));
    }

    if (totalRecords > maxRecords) {
      const error = new Error(
        'The filter set you have selected includes over 100,000 feedback entries, which is too many to export. Please adjust your filters and try again.'
      );
      error.name = EXPORT_LIMIT_EXCEEDED;
      throw error;
    }

    const entryQuery = new GetEntriesInPages({ teamId: this.teamId, filterStateConsumable: this.filterStateConsumable });

    this.parallelExecution = new ParallelPagination(entryQuery, {
      pageSize: this.pageSize,
      numThreads: this.getNumThreads(totalRecords),
      totalRecords: totalRecords,
    });

    await this.parallelExecution.execute();
    return entryQuery.getEntries();
  }

  /**
   * Returns the number of queries to run in parallel
   * In some cases, we can fetch all the entries in fewer requests than the numThreads supplied through the constructor
   * We return the minimum number of threads necessary to fetch all records.
   * Otherwise, we may fire off several ajax requests that return empty arrays.
   *
   * @param totalRecords - total number of entries to fetch
   * @returns - the number of queries to run in parallel
   */
  private getNumThreads(totalRecords: number): number {
    const totalPages = Math.ceil(totalRecords / this.pageSize);
    return totalPages < this.numThreads ? totalPages : this.numThreads;
  }
}
