import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { FeedbackIntegrationFragment } from "../../../../generated/graphql";

interface FilterRowProps {
    segmentGroup: FeedbackIntegrationFragment["segmentConfig"][number]["segmentGroup"];
    onClick: (field: FeedbackIntegrationFragment["segmentConfig"][number]["segmentGroup"]) => void;
    id: string;
}

export const FilterRow = (props: FilterRowProps) => {
    return (
        <div 
            id={`${props.id}`} 
            className="flex px-4 py-2 items-center justify-between text-blueberry bg-white hover:bg-gray-200 transition-all duration-200" 
            onClick={() => {
                props.onClick(props.segmentGroup);
            }}
        >
            <strong>{props.segmentGroup.displayName}</strong>
            <ChevronRightIcon className="w-5 h-5 flex items-center relative top-0 " />
        </div>
    )
}