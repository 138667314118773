import { useContext, useState, useEffect } from 'react';
import { useValidTeamAppContext } from '../../../../../../v2/contexts/AppContext';
import Form from '../../../../../../baseComponents/Form';
import Button, { ButtonVariant } from '../../../../../baseComponents/Button';
import FormInput from '../../../../../../baseComponents/FormInput';
import { External_Tickets_Integration, Feedback_Integration, Integration_Type, Integration_Type_Requirement } from '../../../../../../generated/graphql';
import { ApolloError } from '@apollo/client';
import UserContext from '../../../../../../v2/contexts/UserContext';
import TooltipIcon from '../../../TooltipIcon';
import Toggle from '../../../../Toggle';
import { allRequirementsHaveValue, getSuccessOrError, getSuccessOrErrorIcon, integrationsOAuthRedirect } from './helpers';


interface QueryRes {
  success: boolean;
  error: string | null;
}

interface IntegrationFormComponentProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  integrationType: Integration_Type;
  existingIntegrations: Feedback_Integration[] | External_Tickets_Integration[];
  requirements: Integration_Type_Requirement[];
  setRequirements: (reqs: Integration_Type_Requirement[]) => void;
  updateIntegration: (integrationType: Integration_Type, requirements: Integration_Type_Requirement[], name: string) => void;
  mutationLoading: boolean;
  testConnection: () => Promise<QueryRes>;
  testConnectionLoading: boolean;
  testConnectionError?: ApolloError | null;
  setSuccessModalOpen?: (open: boolean) => void;
  name: string;
  setName: (name: string) => void;
}

/*
 * This is the base form for integration forms, such as Data Import and External Tickets. It handles the form inputs, validation, and submission.
 * The forms that implement this base form must provide the necessary props.
 * */

export const BaseIntegrationForm = ({
  modalOpen,
  setModalOpen,
  integrationType,
  existingIntegrations,
  requirements,
  setRequirements,
  updateIntegration,
  testConnection,
  testConnectionLoading,
  mutationLoading,
  testConnectionError,
  setSuccessModalOpen,
  name,
  setName,
}: IntegrationFormComponentProps) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const { user } = useContext(UserContext);

  // Initialize validation states based on whether required fields are populated
  const initialRequiredFieldsPopulated = requirements
    .filter(req => req.required)
    .every(req => req.value[0]?.value);

  const isNameValid = (name: string) => {
    return name.trim().length > 0;
  };
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [canSubmit, setCanSubmit] = useState(initialRequiredFieldsPopulated && isNameValid(name));
  const [lockSubmit, setLockSubmit] = useState(false);
  const [canValidate, setCanValidate] = useState(allRequirementsHaveValue(requirements) && isNameValid(name));
  const [validationTestSuccess, setValidationTestSuccess] = useState(initialRequiredFieldsPopulated);

  //This is some ugly hack from a long time ago...needs to be revisited and removed.
  const [zdskAdminSettingsShown, setZdskAdminSettingsShown] = useState(false);
  const isZendeskIntegration = integrationType.title === 'Zendesk';

  useEffect(() => {
    setErrorMessage(testConnectionError?.message);
  }, [testConnectionError]);

  

  const updateRequirementValue = (reqToUpdate: Integration_Type_Requirement, newValue: string) => {
    // Only reset validation if changing a required field that was previously validated
    if (reqToUpdate.required && validationTestSuccess) {
      setValidationTestSuccess(false);
      setCanSubmit(false);
      setErrorMessage(undefined);
    }

    const updatedRequirements = requirements.map((req) => {
      if (req.id === reqToUpdate.id) {
        return {
          ...req,
          value:
            req.value.length === 1
              ? req.value.map((value) => {
                  return { ...value, value: newValue, requirement: req };
                })
              : [{ value: newValue, id: 0, requirement: req }],
        };
      }
      return req;
    });
    
    setRequirements(updatedRequirements);
    setCanValidate(allRequirementsHaveValue(updatedRequirements));
  };

  const getDisplayErrorMessage = () => {
    if (!isNameValid(name)) {
      return 'Integration name must be specified to continue';
    }
    return errorMessage;
  };

  const handleTestConnection = async () => {
    if (!isNameValid(name)) {
      return;
    }
    
    setValidationTestSuccess(false);
    setErrorMessage(undefined);

    const res = await testConnection();

    if (res.success) {
      setValidationTestSuccess(true);
      setCanSubmit(allRequirementsHaveValue(requirements) && isNameValid(name));
    } else {
      setValidationTestSuccess(false);
      setCanSubmit(false);
      setErrorMessage(res.error ?? 'Unknown error - contact support');
    }
  };

  const handleUpdateFeedbackIntegration = async () => {
    updateIntegration(integrationType, requirements, name);
  };

  const getFormButtons = (loading: boolean, enableSubmit: boolean) => {
    return (
      <div className="mt-8 flex flex-row justify-between gap-x-4 text-center">
        <Button variant={ButtonVariant.Tertiary} onClick={() => setModalOpen(false)} text="Cancel"></Button>
        {validationTestSuccess && isNameValid(name) ? (
          <Button
            variant={ButtonVariant.Primary}
            id="next-integration-button"
            text="Next"
            onClick={() => {
              setLockSubmit(true);
              handleUpdateFeedbackIntegration();
            }}
            loadingConfirm={loading}
            disabled={loading || lockSubmit}
          />
        ) : (
          <Button
            variant={ButtonVariant.Primary}
            id={`validate-integration-${integrationType.title.replace(' ', '-').toLowerCase()}`}
            text="Validate"
            onClick={handleTestConnection}
            loadingConfirm={testConnectionLoading}
            disabled={
              !canValidate &&
              (!zdskAdminSettingsShown ||
              !(integrationType.title !== 'Zendesk' ? true : !!requirements.find((req) => req.key === 'authToken')?.value[0]?.value))
            }
          />
        )}
      </div>
    );
  };

  const handleNameChange = (value: string) => {
    setName(value);
    if (!isNameValid(value)) {
      setErrorMessage('Integration name must be specified to continue');
    } else {
      setErrorMessage(undefined);
      if (validationTestSuccess) {
        setCanSubmit(true);
      }
    }
    setCanValidate(allRequirementsHaveValue(requirements) && isNameValid(value));
  };

  return (
    <Form bottomRow={getFormButtons(mutationLoading, (canSubmit || existingIntegrations.length > 0) && isNameValid(name))}>
      <div className="space-y-2">
        <div className="flex justify-center mb-4">
          <div className="flex items-center gap-x-2">
            {validationTestSuccess && isNameValid(name) && (
              <>
                {getSuccessOrErrorIcon(true, null)}
                <p className="text-sm text-green-600">
                  Connection to {integrationType.title} validated. Click <b>Next</b> to complete setting up the integration
                </p>
              </>
            )}
            {getDisplayErrorMessage() && (
              <>
                {getSuccessOrErrorIcon(false, getDisplayErrorMessage())}
                <p className="text-sm text-red-600">{getDisplayErrorMessage()}</p>
              </>
            )}
          </div>
        </div>

        <div className="mb-10">
          <FormInput
            id="integration-name-input"
            type='text'
            description={"Name of the integration"}
            label="Integration name"
            key="integration_name"
            placeholder="My Integration"
            value={name}
            onChange={(e) => {
              handleNameChange(e.target.value);
            }}
            borderColor={validationTestSuccess ? 'border-green-500' : 'border-gray-300'}
          />
        </div>
        {requirements.map((req, index) => {
          if (!zdskAdminSettingsShown) {
            if (req.key === 'authToken' && !req.value[0]?.value) return;
          }
          if (req.category === 'reply') return;
          if (req.key === 'summarize') return;
          if (req.key === 'ticket_amount') {
            if (!user?.isUnwrapper) return;
            if (!zdskAdminSettingsShown) return;
          }
          const isCheckbox = req.key.includes('toggle_');
          const id = `integration-requirement-${index}`;
          return (
              <FormInput
                id={id}
                disabled={!zdskAdminSettingsShown && isZendeskIntegration && req.key === 'authToken'}
                type={req.obfuscate ? 'password' : isCheckbox ? 'checkbox' : 'text'}
                description={req.description}
                label={req.title}
                key={req.id}
                placeholder={req.example}
                value={req.value[0]?.value}
                onChange={(e) => {
                  updateRequirementValue(req, isCheckbox ? (e.target.checked ? '1' : '0') : e.target.value);
                }}
                borderColor={validationTestSuccess && isNameValid(name) ? 'border-green-500' : 'border-gray-300'}
              />
          );
        })}
        <div className="pt-5">
          <div className="flex flex-row justify-end">
            {user?.isUnwrapper && integrationType.title.includes('Zendesk') ? (
              <div className="flex flex-row gap-x-1 items-center">
                <div className="flex flex-row gap-x-1 items-center">
                  <p>Admin Settings</p>
                  <TooltipIcon
                    tooltipContent={
                      'For Unwrap users. This shows admin fields, and lets you enter the API key manually and click VALIDATE without having to Auth. Useful after user authed Zendesk once, then we grab the API key from the DB.'
                    }
                  />
                </div>
                <Toggle initialState={false} value={zdskAdminSettingsShown} onSwitch={() => setZdskAdminSettingsShown((prev) => !prev)} />
              </div>
            ) : null}
            <div className="items-right flex justify-end space-x-5 sm:col-span-6">
              {(integrationType.title.includes('Zendesk') || (integrationType.requirements.find((req) => req.requiredForAuthRedirect) && integrationType.authRedirect)) && (
                <Button
                  variant={ButtonVariant.Secondary}
                  text={'AUTHENTICATE'}
                  onClick={() => integrationsOAuthRedirect({ requirements, teamId, integration: integrationType })}
                  disabled={zdskAdminSettingsShown || (!!requirements.find((req) => req.key === 'authToken')?.value[0]?.value ?? false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};


/**
 * Get the requirements in shape to send to the backend, throwing an error on missing required values.
 * @param requirements
 * */
export const getIntegrationRequirementsToSend = (requirements: Integration_Type_Requirement[]): { id: number; key: string; value: string }[] => {
  return requirements
    .filter((req) => req.required || !!req.value?.[0]?.value)
    .map((requirement) => {
      if (requirement.value.length < 1) {
        throw new Error('Need an input for ' + requirement.title);
      }
      return {
        id: requirement.id,
        key: requirement.key,
        value: requirement.value[0].value,
      };
    });
};