import { useState, useRef, useEffect, useContext, useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import { ArrowTopRightOnSquareIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { truncateAndEllipsis } from '../../../v2/util';
import { Breakdown, PlotFragment } from '../../../generated/graphql';
import Tippy from '@tippyjs/react';
import { useChartFeedback } from '../../hooks/useChartFeedback';
import { FilterState } from '../../../reducers/filterStatement/filterStatementReducer';
import { ChartTabBody, TabItem } from './ChartTabBody';
import { areAllChartValuesZero } from '../../lib/chart';
import { SkeletonBar } from '../skeletons/SkeletonBar';

interface TabProps {
  item: TabItem;
  isActive: boolean;
  onClick: () => void;
  dataTabId: string;
}

const Tab = ({ item, isActive, onClick, dataTabId }: TabProps) => {
  const maxTitleLength = 25;
  return (
    <Tippy content={item.title} delay={[250, 0]} disabled={item.title.length <= maxTitleLength}>
      <button
        onClick={onClick}
        data-tab-id={dataTabId}
        className={`flex items-center gap-x-2 px-4 py-2 border-b-2 whitespace-nowrap transition-all duration-200 ease-in-out  ${
          isActive ? 'border-blueberry text-blueberry font-semibold' : 'border-gray-200 text-gray-400 hover:text-gray-600 hover:border-gray-300 font-medium'
        }`}
      >
        <div className="w-4 h-4 rounded transition-colors duration-200 ease-in-out" style={{ backgroundColor: item.color }} />
        <span>{truncateAndEllipsis(item.title, maxTitleLength)}</span>
      </button>
    </Tippy>
  );
};

const ChartFeedbackSkeleton = () => {
  return (
    <div className="flex gap-x-8 overflow-x-hidden border-b border-gray-200">
      {[...Array(3)].map((_, i) => (
        <div key={i} className="flex items-center py-2">
          <SkeletonBar width="w-32" height="h-6" />
        </div>
      ))}
    </div>
  );
};

interface ChartFeedbackSectionProps {
  currentChart: PlotFragment;
  loading?: boolean;
}

export interface ChartFeedbackRef {
  setActiveTab: (tabId: string) => void;
}

export const ChartFeedbackSection = forwardRef<ChartFeedbackRef, ChartFeedbackSectionProps>(
  ({ currentChart, loading }, ref) => {
    const tabs = useChartFeedback(currentChart);
    const [activeTabId, setActiveTabId] = useState<string>('');

    const handleTabChange = (tabId: string) => {
      setActiveTabId(tabId);
        const container = scrollContainerRef.current;
        if (!container) return;

        const selectedTab = container.querySelector(`[data-tab-id="${tabId}"]`) as HTMLElement;
        if (!selectedTab) return;

        const tabLeft = selectedTab.offsetLeft;
        const containerWidth = container.clientWidth;
        const scrollAmount = tabLeft - container.scrollLeft - (containerWidth / 2) + (selectedTab.offsetWidth / 2);
        
        container.scroll({
          left: container.scrollLeft + scrollAmount,
          behavior: 'smooth'
        });

        checkScrollButtons();
    };

    useImperativeHandle(ref, () => ({
      setActiveTab: handleTabChange
    }));

    useEffect(() => {
      if (tabs.length > 0 && (!activeTabId || !tabs.find(tab => tab.id === activeTabId))) {
        handleTabChange(tabs[0].id);
      }
    }, [tabs, activeTabId]);

    const activeTab = tabs.find((tab) => tab.id === activeTabId);

    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    const scroll = (direction: 'left' | 'right') => {
      const container = scrollContainerRef.current;
      if (!container) return;

      const scrollAmount = 500;
      container.scroll({
        left: container.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount),
        behavior: 'smooth',
      });
    };

    const checkScrollButtons = () => {
      const container = scrollContainerRef.current;
      if (!container) return;

      const hasHorizontalScroll = container.scrollWidth > container.clientWidth;
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(hasHorizontalScroll && container.scrollLeft < container.scrollWidth - container.clientWidth);
    };

    useEffect(() => {
      const container = scrollContainerRef.current;
      if (!container) return;

      const resizeObserver = new ResizeObserver(() => {
        checkScrollButtons();
      });

      resizeObserver.observe(container);
      checkScrollButtons();

      return () => {
        resizeObserver.disconnect();
      };
    }, [tabs]);

    useEffect(() => {
      const handleResize = () => {
        checkScrollButtons();
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      // Check scroll buttons when loading is complete and we have tabs
      if (!loading && tabs.length > 0) {
        checkScrollButtons();
      }
    }, [loading, tabs]);

    if (loading) return <ChartFeedbackSkeleton />;

    if (currentChart.series && areAllChartValuesZero(currentChart.series)) {
      return null;
    }
    
    return (
      <div className="flex flex-col gap-y-2 h-full">
        <div className="relative flex items-center">
          <div
            ref={scrollContainerRef}
            className="flex overflow-x-auto scrollbar-hide w-full overscroll-none"
            onScroll={checkScrollButtons}
          >
            {tabs.map((tab) => (
              <Tab 
                key={tab.id} 
                item={tab} 
                isActive={activeTabId === tab.id} 
                onClick={() => handleTabChange(tab.id)} 
                dataTabId={tab.id}
              />
            ))}
          </div>

          {showLeftArrow ? (
            <div className="absolute left-0 flex items-center h-full">
              <div className="absolute left-0 w-16 h-full bg-gradient-to-r from-white via-white/90 to-transparent z-10 pointer-events-none" />
              <button onClick={() => scroll('left')} className="absolute left-2 z-20 p-0.5 bg-white rounded-full border-2 border-blueberry text-blueberry">
                <ChevronLeftIcon className="w-4 h-4 stroke-2" />
              </button>
            </div>
          ) : null}

          {showRightArrow ? (
            <div className="absolute right-0 flex items-center h-full">
              <div className="absolute right-0 w-16 h-full bg-gradient-to-l from-white via-white/90 to-transparent z-10 pointer-events-none" />
              <button onClick={() => scroll('right')} className="absolute right-2 z-20 p-0.5 bg-white rounded-full border-2 border-blueberry text-blueberry">
                <ChevronRightIcon className="w-4 h-4 stroke-2" />
              </button>
            </div>
          ) : null}
        </div>

        <div className="flex-1 overflow-hidden">
          {activeTab ? <ChartTabBody key={activeTab.id} tab={activeTab} currentBreakdown={currentChart.plotConfiguration.breakdown} /> : null}
        </div>
      </div>
    );
  }
);
