import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { classNames } from '../v2/util';
import toast from 'react-hot-toast';

interface InlineEditableTextAreaProps {
  textAreaId?: string;
  value: string;
  additionalClassNames?: string;
  ignorePadding?: boolean;
  // this only fires when someone presses the enter key
  onEditComplete: (value: string) => void;
  editable?: boolean;
  placeholder?: string;
  textClassName?: string;
  preventEmpty?: boolean;
}

const InlineEditableTextArea = ({
  textAreaId,
  value,
  additionalClassNames,
  onEditComplete,
  editable = true,
  ignorePadding,
  placeholder,
  textClassName,
  preventEmpty,
  ...props
}: InlineEditableTextAreaProps) => {
  const [textValue, setTextValue] = useState(value);
  const { ref, inView } = useInView({ threshold: 0 });
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  /**
   * If the text changes, reset the textArea component's height based on text size
   */
  const resizeTextArea = () => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = '0px';
      let scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  };

  useEffect(() => {
    resizeTextArea();
  }, [textValue]); // Only resize when text changes, not when scrolling

  // Handle window resize events
  useEffect(() => {
    const debouncedResize = debounce(resizeTextArea, 100);
    window.addEventListener('resize', debouncedResize);
    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, []);

  // Debounce function to prevent too many resize calls. Otherwise scrolling starts behaving weirdly.
  function debounce(func: Function, wait: number) {
    let timeout: NodeJS.Timeout;
    return function executedFunction(...args: any[]) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!editable) return;
    if (event.key === 'Enter' || event.key === 'Escape') {
      // @ts-ignore
      event.target.blur();
    }
    if (event.key === 'Escape') {
      setTextValue(value);
    }
  };

  const handleEditComplete = () => {
    if (preventEmpty && textValue.trim() === '') {
      toast.error('This field cannot be empty');
      setTextValue(value); //Reset the text to the original value
      return;
    }
    onEditComplete(textValue);
  };
  return (
    <div ref={ref} className={`${ignorePadding ? 'flex' : 'py-2'} w-full`} {...props}>
      <textarea
        spellCheck="false"
        id={textAreaId ?? ''}
        data-testid={textAreaId ?? ''}
        rows={1}
        onClick={(e) => e.stopPropagation()}
        disabled={!editable}
        autoFocus={false}
        ref={textAreaRef}
        className={classNames(
          'scroll-none -ml-1 mt-0 w-full resize-none overflow-hidden border-0 leading-tight px-1 py-0 focus:border-2 focus:border-blueberry focus:ring-0 placeholder:text-gray-400',
          editable ? 'hover:cursor-text hover:bg-blueberry-lighter' : '',
          textClassName ?? 'text-lg',
          additionalClassNames,
          !additionalClassNames?.includes('font-') ? 'font-semibold' : ''
        )}
        aria-label="text"
        value={textValue}
        onKeyDown={onKeyDown}
        onBlur={handleEditComplete}
        onChange={(e) => {
          setTextValue(e.target.value);
        }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InlineEditableTextArea;
