import { useContext, useEffect, useState, useCallback, useRef } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Chart_Type, useGetPlotLazyQuery, useGetPlotPreviewLazyQuery } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import LoadingSpinner from '../../baseComponents/LoadingSpinner';
import { SeriesConfiguration } from './SeriesConfiguration';
import { BaseChartCard, BaseChartCardBody } from './CustomChartCard';
import { FilterContext } from '../../../context/filterStatementContext';
import { useChartState, useChartDispatch } from '../../../context/chartContext';
import { ChartActionType } from '../../../reducers/charts/chartReducer';
import EditChartSettings from './EditChartSettings';
import ChartHeader from './ChartHeader';
import { Plot } from './Plot';
import InheritedBoardFiltersSection from './InheritedBoardFiltersSection';
import ChartTitle from './ChartTitle';
import ActionBar from './ActionBar';
import Button from '../../baseComponents/Button';
import { ButtonVariant } from '../../baseComponents/Button';
import { ButtonShape } from '../../baseComponents/Button';
import { ButtonSize } from '../../baseComponents/Button';
import TooltipIcon from '../../../v2/components/Modals/TooltipIcon';
import { ChartFeedbackSection, ChartFeedbackRef } from './ChartFeedbackSection';

export const EditChartPage = () => {
  const { curTeamId: teamId, curOrgId: orgId, organizations } = useValidTeamAppContext();
  const teams = organizations.find((org) => org.id === orgId)?.teams;
  const teamsList = teams?.map((team) => ({ id: team.id, name: team.name })) || [];

  /**
   * What is the initialLoadComplete for, what are we preventing from showing a loading spinner or something?
   */
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [disableBreakdown, setDisableBreakdown] = useState(false);

  /**
   * This is the filter state from the boards page.
   * This controls the date filters on the charts page.
   * If you set the date filter on the charts page and go to the boards page it'll be what you set the date to.
   *
   * If you set any filters on the boards page they automatically get consumed on the charts page through this context
   */
  const pageLevelFilterState = useContext(FilterContext);

  const chartState = useChartState();
  const chartDispatch = useChartDispatch();

  const { chartId } = useParams<{ chartId: string }>();

  const [getPlot] = useGetPlotLazyQuery();

  const [getPreview] = useGetPlotPreviewLazyQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    setDisableBreakdown(chartState.chartConfigs?.series ? chartState.chartConfigs?.series?.length > 1 : false);
  }, [chartState.chartConfigs]);

  const fetchPlotPreview = async () => {
    chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: true } });
    await getPreview({
      variables: { teamId, pageLevelFilterNode: pageLevelFilterState.filterConsumable, plotConfigurationInput: chartState.chartConfigs },
      onCompleted(data) {
        chartDispatch({ type: ChartActionType.SetCurrentChart, payload: { currentChart: data.getPlotPreview } });
        chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: false } });
      },
      onError(error) {
        toast.error('Error fetching chart data');
        chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: false } });
      },
    });
  };

  const isFirstRender = useRef(true);
  const prevPlotChangedRef = useRef(chartState.plotChanged);

  useEffect(() => {
    /**
     * I want to guarantee that this useEffect does not run on the first render of the page.
     *
     * On first render we want to use the useEffect below that handles loading the initial page data. This useEffect is only necessary for updating the plot.
     */
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!initialLoadComplete) return;
    fetchPlotPreview();
  }, [chartState.plotChanged, pageLevelFilterState.filterConsumable]);

  useEffect(() => {
    if (chartState.currentChart) {
      /**
       * When clicking in from a board the chartState.currentChart is already set.
       * If this property is already defined we can skip fetching the plot.
       * However, we still need to set the chart ID if we have one from the URL.
       */
      if (chartId) chartDispatch({ type: ChartActionType.SetChartId, payload: { id: Number(chartId) } });
      setInitialLoadComplete(true);
      return;
    }
    if (chartId) {
      // If we have a chartId we want to fetch the plot otherwise we're creating a new plot
      fetchPlotById().then(() => {
        setInitialLoadComplete(true);
      });
    } else {
      fetchPlotPreview().then(() => {
        setInitialLoadComplete(true);
      });
    }
  }, []);

  const fetchPlotById = async () => {
    chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: true } });
    chartDispatch({ type: ChartActionType.SetChartId, payload: { id: Number(chartId) } });
    await getPlot({
      variables: { teamId, chartId: Number(chartId), pageLevelFilterNode: pageLevelFilterState.filterConsumable },
      onCompleted(data) {
        chartDispatch({ type: ChartActionType.SetChartConfigs, payload: { chartConfigs: data.getPlot.plotConfiguration } });
        chartDispatch({ type: ChartActionType.SetCurrentChart, payload: { currentChart: data.getPlot } });
        chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: false } });
      },
      onError(error) {
        toast.error('Error fetching chart data');
        chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: false } });
      },
    });
  };

  const chartFeedbackRef = useRef<ChartFeedbackRef>(null);

  const handleSeriesClick = useCallback(
    (seriesIndex: number) => {
      if (chartState.currentChart?.series?.[seriesIndex]?.seriesLabel?.id) {
        chartFeedbackRef.current?.setActiveTab(chartState.currentChart.series[seriesIndex].seriesLabel.id);
      }
    },
    [chartState.currentChart]
  );

  return (
    <div id="edit-chart-page" className="flex flex-col">
      {initialLoadComplete ? (
        <div className="flex flex-col gap-y-2 mb-3">
          <ActionBar />
          <ChartTitle />
        </div>
      ) : null}

      <div className="grid grid-cols-11 gap-y-8 xl:gap-y-0 xl:gap-x-12 w-full">
        {!initialLoadComplete && chartState.loadingChart ? (
          <div className="col-span-12">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-y-4 col-span-11 xl:col-span-6" id="left-column">
              <div className="flex flex-col gap-y-3">
                <ChartHeader />
                <div className="flex flex-col h-80" id="section">
                  {chartState.currentChart ? (
                    <BaseChartCard>
                      <BaseChartCardBody>
                        <Plot
                          loading={chartState.loadingChart}
                          plotData={chartState.currentChart}
                          showLegend={chartState.chartConfigs?.chartType !== Chart_Type.HorizontalBar}
                          onSeriesClick={handleSeriesClick}
                        />
                      </BaseChartCardBody>
                    </BaseChartCard>
                  ) : (
                    <LoadingSpinner />
                  )}
                </div>
              </div>
              <EditChartSettings disableBreakdown={disableBreakdown} />
              <div className="flex flex-col gap-y-1">
                <div className="flex flex-row gap-x-2 items-center justify-between">
                  <h1 className="font-semibold text-lg text-blueberry">Data Source</h1>
                  <div className="flex flex-row gap-x-2 items-center mt-1">
                    <Button
                      variant={ButtonVariant.Bordered}
                      shape={ButtonShape.Pill}
                      size={ButtonSize.XSmall}
                      text="Add data view +"
                      onClick={() => chartDispatch({ type: ChartActionType.AddSeries, payload: {} })}
                    />
                    <TooltipIcon tooltipContent="You can add a new data series to the chart to compare data between views and/or between different filter sets" />
                  </div>
                </div>
                <InheritedBoardFiltersSection />
                <div className="flex flex-col bg-silver divide-y divide-gray-300 rounded-md">
                  {chartState.chartConfigs?.series.map((series, index) => {
                    return <SeriesConfiguration key={index} teamsList={teamsList} defaultTeamId={teamId} series={series} index={index} />;
                  })}
                </div>
              </div>
            </div>
            <div className="col-span-11 xl:col-span-5 flex flex-col max-h-screen">
              {chartState.currentChart ? (
                <ChartFeedbackSection ref={chartFeedbackRef} currentChart={chartState.currentChart} loading={chartState.loadingChart} />
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
