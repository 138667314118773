import { Group_Status, Group_Type, GroupTaxonomyFragment, TaxonomyTreeNode } from '../../generated/graphql';
import { Ancestry } from '../../v2/hooks/GroupHook';
import { TaxonomyGroup } from '../reducers/taxonomy';
import { getGroupIdsFromLocalStorage } from './taxonomy';

/**
 * Converts TaxonomyTreeNode to TaxonomyGroups. The frontend only understands TaxonomyGroups, so we need to convert the data to the correct format.
 * We do this by recursively calling the function on the children of the TaxonomyElement.
 * @param taxonomyElements
 * @param parent
 * @returns
 */
export const adaptTaxonomyElements = (taxonomyElements: TaxonomyTreeNode[], parent: TaxonomyTreeNode | null = null): TaxonomyGroup[] => {
  if (!taxonomyElements || taxonomyElements.length < 1) return [];
  return taxonomyElements.map((taxonomyElement) => convertToTaxonomyGroup(taxonomyElement, parent));
};

/**
 * Maps a TaxonomyElement to a TaxonomyGroup
 * @param element
 * @param parent
 * @returns
 */
export function convertToTaxonomyGroup(element: TaxonomyTreeNode, parent: TaxonomyTreeNode | null): TaxonomyGroup {
  const group = element.node;
  const parentId = parent?.groupId ?? null;

  const savedIds = getGroupIdsFromLocalStorage();

  const relativeShare = calculateRelativeShareFiltered(element, parent);
  const relativeShareFull = calculateRelativeShareUnfiltered(element, parent);

  return {
    parentId: parentId,
    showChildren: !!savedIds.includes(element.groupId),
    children: adaptTaxonomyElements(element.children, element),
    trending: group.trending,
    canAddChildren: group.canAddChildren,
    creator: { isUnwrapGenerated: group.creator?.isUnwrapGenerated ?? true, creatorEmail: group.creator?.user?.email },
    id: group.id,
    title: group.title,
    totalEntries: group.uniqueEntries,
    isPinnedByUser: group.isPinnedByUser,
    tags: group.tags ?? [],
    relativeShare,
    relativeShareFull,
    date: group.dateCreated ?? 0,
    totalDescendents: group.totalDescendents,
    ancestors: group.ancestors as Ancestry[],
    isNew: group.isNew ?? false,
    processing: group.processing ?? false,
    progress: group.progress ?? 0,
  };
}

/**
 *
 * @param element
 * @param parent
 * @returns
 */
const calculateRelativeShareFiltered = (element: TaxonomyTreeNode, parent: TaxonomyTreeNode | null): number => {
  if (element.node.statistics.denominator.denominatorFiltered == 0) return 0;

  const denominatorElement = parent ?? element;

  return (element.node.uniqueEntries * 100) / denominatorElement.node.statistics.denominator.denominatorFiltered;
};

const calculateRelativeShareUnfiltered = (element: TaxonomyTreeNode, parent: TaxonomyTreeNode | null): number => {
  if (element.node.statistics.denominator.denominatorUnfiltered == 0) return 0;

  const denominatorElement = parent ?? element;

  return (element.node.uniqueEntries * 100) / denominatorElement.node.statistics.denominator.denominatorUnfiltered;
};
