import { useContext } from "react";
import { SearchInput } from "../../../baseComponents/SearchInput";
import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../../context/FeedbackIntegrationContext";
import { FilterRow } from "./FilterRow";
import { FeedbackIntegrationFragment } from "../../../../generated/graphql";
import { useState } from "react";

interface FilterFieldSelectionModalProps {
    onSelect: (field: FeedbackIntegrationFragment["segmentConfig"][number]["segmentGroup"]) => void;
    id: string;
}

export const FilterFieldSelectionModal = (props: FilterFieldSelectionModalProps) => {
    const [search, setSearch] = useState("");


    const data = useContext(FeedbackIntegrationContext);
    const nonLabelConfigs = data?.feedbackIntegration?.segmentConfig.filter(config => !config.override);
    const segmentGroups = nonLabelConfigs?.map(config => config.segmentGroup);
    const uniqueSegmentGroups = segmentGroups?.filter((group, index, self) => index === self.findIndex(t => t.displayName === group.displayName));

    return (
        <div className="w-80 relative flex flex-col" id="filter-field-selection-modal">
            <div className="p-4">
                <SearchInput 
                    rounded="md"
                    placeholder="Search for fields..."
                    onChange={(e) => {setSearch(e.target.value)}} 
                    onSearch={(text) => {setSearch(String(text))}} 
                />
            </div>
            <div className="pb-2 h-full overflow-y-auto">
                {uniqueSegmentGroups?.map((segmentGroup, index) => (
                    segmentGroup.displayName.includes(search) && (
                        <FilterRow 
                            key={segmentGroup.id}
                            segmentGroup={segmentGroup}
                            onClick={(segmentGroup) => props.onSelect(segmentGroup)} 
                            id={`${props.id}-filter-row-${index}`}
                        />
                    )
                ))}
            </div>
        </div>
    )
}