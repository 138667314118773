import { useContext, useEffect, useState } from 'react';
import { FilterContext } from '../../../../context/filterStatementContext';
import { FilterField, FilterFieldValue, Operator, useGetFilterFieldValuesLazyQuery } from '../../../../generated/graphql';
import { FilterStatementSchema } from '../../../../reducers/filterStatement/filterStatementReducer';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import Button, { ButtonSize, ButtonVariant } from '../../../baseComponents/Button';
import { adaptForFilterNode, normalizeOperator } from '../operatorUtils';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { FilterOperatorValueSelector } from './FilterOperatorValueSelector';
import { LoadingDots } from '../filterBar/DateFilterComponent';

export const FieldValueConfiguration = ({
  selectedField,
  setSelectedField,
  resetFunction,
  onComplete,
  prePopulatedValues,
  prePopulatedOperator,
}: {
  selectedField: FilterField;
  setSelectedField: (field: FilterField | null) => void;
  resetFunction: () => void;
  onComplete: (node: FilterStatementSchema) => void;
  prePopulatedValues?: FilterFieldValue[];
  prePopulatedOperator?: Operator;
}) => {
  const [selectedOperator, setSelectedOperator] = useState<Operator>(prePopulatedOperator || selectedField.allowedOperators[0]);

  const [selectedValues, setSelectedValues] = useState<FilterFieldValue[]>(prePopulatedValues || []);
  const { curTeamId } = useValidTeamAppContext();
  const { filterConsumable } = useContext(FilterContext);

  const [getFilterFieldValues, { data, loading }] = useGetFilterFieldValuesLazyQuery();

  useEffect(() => {
    setSelectedValues(prePopulatedValues || []);
    setSelectedOperator(prePopulatedOperator || selectedField.allowedOperators[0]);
    if (selectedField) {
      getFilterFieldValues({
        variables: {
          fieldName: selectedField.fieldName,
          teamId: curTeamId,
          fieldStatementFilters: filterConsumable,
          skip: 0,
          take: 5000,
        },
      });
    }
  }, [selectedField, filterConsumable]);

  /**
   * Prepare the new filter node and call the onComplete function
   */
  const selectionApplied = () => {
    const newNode: FilterStatementSchema = {
      type: 'statement',
      fieldName: selectedField.fieldName,
      operator: adaptForFilterNode(selectedOperator || Operator.Equal),
      value: selectedValues.length > 1 ? JSON.stringify(selectedValues.map((value) => value.filterValue)) : selectedValues[0].filterValue,
      fieldDisplayName: selectedField.displayName,
      valueDisplayName: selectedValues.map((value) => value.displayName),
    };
    onComplete(newNode);
    resetFunction();
  };

  return (
    <div className="flex w-full flex-col max-h-[398px] overflow-y-visible rounded-r-lg">
      <div className="text-blueberry-light font-semibold bg-blueberry-light/10">
        <div className="flex items-center p-2 relative">
          <button
            className="p-0.5 rounded-md hover:bg-gray-300 hover:cursor-pointer border-blueberry text-blueberry absolute left-2"
            onClick={() => {
              setSelectedField(null);
            }}
          >
            <ChevronLeftIcon className="w-4 h-4 font-bold stroke-4" />
          </button>
          <div className="flex-1 text-center">{selectedField.displayName}</div>
        </div>
      </div>

      <div className="flex flex-col justify-center h-full">
        {loading ? (
          <div className="flex flex-col pt-[23px] pb-[23px] items-center justify-center h-full">
            <LoadingDots />
          </div>
        ) : (
          <FilterOperatorValueSelector
            operators={selectedField?.allowedOperators.map((operator) => ({
              id: operator,
              label: normalizeOperator(operator),
            }))}
            valueOptions={data?.getFilterFieldValues ?? []}
            selectedOperator={selectedOperator}
            selectedValues={selectedValues}
            onOperatorChange={setSelectedOperator}
            onValueChange={setSelectedValues}
          />
        )}
        <div className="flex flex-row justify-between p-2 mt-auto border-t border-gray-200 ">
          <Button size={ButtonSize.Small} variant={ButtonVariant.Tertiary} text="Cancel" onClick={resetFunction} />
          <Button
            size={ButtonSize.Small}
            text="Apply"
            id="apply-filter-button"
            disabled={selectedValues.length === 0}
            onClick={() => {
              selectionApplied();
            }}
          />
        </div>
      </div>
    </div>
  );
};
