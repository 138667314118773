import { UserCircleIcon, BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import { NavLink } from 'react-router-dom';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';
import { useBoardsListDispatch } from '../../../context/boardsListContext';
import { BoardLightFragment, useTogglePinBoardMutation } from '../../../generated/graphql';
import { BoardsListActionTypes } from '../../../reducers/boards/boardsListReducer';
import { AppRoutes } from '../../../Routes';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import toast from 'react-hot-toast';
import { BookmarkIcon } from '@heroicons/react/24/outline';

export const BoardPreviewCard = ({ board }: { board: BoardLightFragment }) => {
  const { curTeamId: teamId } = useValidTeamAppContext();

  const dispatch = useBoardsListDispatch();
  const [togglePinBoard, togglePinBoardRes] = useTogglePinBoardMutation();
  async function handleTogglePin(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    await togglePinBoard({
      variables: {
        teamId,
        boardId: board.id,
      },
      onCompleted() {
        dispatch({ type: BoardsListActionTypes.TogglePinBoard, payload: { boardId: board.id } });
      },
      onError(err) {
        toast.error('Failed to toggle board pin');
        console.error(err);
      },
    });
  }

  let fullName = board.creator?.firstName;
  if (board.creator?.lastName) fullName = board.creator.firstName + ' ' + board.creator.lastName;

  const isUnwrapGenerated = board.creator?.email?.includes('unwrap.ai');

  return (
    <NavLink to={AppRoutes.v3FullPath.boards + '/' + board.id}>
      <div id="board-preview-card" className="flex flex-col rounded-lg border border-gray-200 cursor-pointer hover:border-gray-400 duration-150">
        <div className="flex flex-row justify-between items-center bg-silver px-4 h-[44px] rounded-t-lg">
          {board.creator?.firstName ? (
            <div className="flex flex-row gap-x-2 items-center text-gray-400 w-2/3">
              <UserCircleIcon className="h-5 w-5" />
              <p className="line-clamp-1">{isUnwrapGenerated ? 'Unwrap Team' : fullName}</p>
            </div>
          ) : (
            <div></div>
          )}
          {togglePinBoardRes.loading ? (
            <AdjustableLoadingIcon width={5} height={5} color="white" />
          ) : board.isPinnedByUser ? (
            <Tippy content="Unpin board" placement="top" duration={150}>
              <BookmarkIconSolid className="h-5 w-5 self-center text-blueberry" id="unpin-group" onClick={(e) => handleTogglePin(e)} />
            </Tippy>
          ) : (
            <Tippy content="Pin board" placement="top" duration={150}>
              <BookmarkIcon className="h-5 w-5 self-center" id="pin-group" onClick={(e) => handleTogglePin(e)} />
            </Tippy>
          )}
        </div>
        <div className="flex flex-col gap-y-3 bg-white justify-end pb-6 px-6 h-[164px] rounded-b-lg">
          <h1 className="text-2xl font-semibold text-blueberry line-clamp-2">{board.title}</h1>
          <p className="text-gray-500 font-normal text-sm line-clamp-2">{board.description}</p>
        </div>
      </div>
    </NavLink>
  );
};
