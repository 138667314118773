import { Integration_Type } from '../../../generated/graphql';
import Button, { ButtonShape, ButtonVariant } from '../../baseComponents/Button';

interface IntegrationScrollerItemProps {
    integration: Integration_Type;
    onSelect: (integration: Integration_Type) => void;
}

export const IntegrationScrollerItem = ({ integration, onSelect }: IntegrationScrollerItemProps) => {
    const integration_without_requirements = { 
        ...integration, 
        requirements: integration.requirements.map(requirement => ({ 
            ...requirement, 
            value: []
        }))
    };

    return (
        <div className="border-b border-gray-200">
            <div className="flex items-center justify-between py-2">
                <div className="flex items-center gap-4">
                    <img src={integration.logoUrl} alt="" className="w-6 h-6 object-contain"/>
                    <h5 className="text-blueberry text-lg font-semibold">{integration.title}</h5>
                </div>
                <Button 
                    text="Select" 
                    shape={ButtonShape.Pill} 
                    variant={ButtonVariant.Bordered} 
                    onClick={() => onSelect(integration_without_requirements)} 
                />
            </div>
        </div>
    );
};