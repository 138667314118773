import { useContext, useEffect, useState } from 'react';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import Button, { ButtonType } from '../../baseComponents/Button';
import { IDropDownItem } from '../../baseComponents/DropDown';
import {
  useCopyTeamEntriesMutation, useLaunchNlpMutation,
  useMoveTeamMutation
} from '../../generated/graphql';
import Card from '../components/Card';
import AppContext, { OrganizationWithTeamInfo, useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { PageWrapper } from './PageWrapper';
import { VirtualizedComboBox } from '../components/VirtualizedComboBox';
import { TeamDefaultsCard } from '../components/admin/TeamDefaultsCard';
import toast from 'react-hot-toast';
import { PermissionsCard } from '../components/admin/PermissionsCard';

const AdminPage = (): JSX.Element => {
  const { organizations, orgsHaveLoaded, curTeamId } = useContext(AppContext);
  /** Can show many cards here. */
  return (
    <PageWrapper title="Admin">
      <h1 className="text-xl font-semibold text-blueberry">General Admin Tools</h1>
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-row flex-wrap gap-x-3">
          <KickOffNLPPipelineCard organizations={organizations} orgsHaveLoaded={orgsHaveLoaded} />
          <CopyEntriesFromTeamCard organizations={organizations} orgsHaveLoaded={orgsHaveLoaded} />
          <MoveTeamCard organizations={organizations}  />
        </div>
        <div className="flex flex-row flex-wrap gap-x-3">
          <PermissionsCard />
        </div>
        {curTeamId ? <CurrentTeamSettingsSection /> : null}
      </div>
    </PageWrapper>
  );
};

export const MoveTeamCard = ({ organizations }: { organizations: OrganizationWithTeamInfo[] }): JSX.Element => {
  const [view, setView] = useState<IDropDownItem>({name: 'Select a team', id: -1});
  const [sourceOrg, setSourceOrg] = useState<IDropDownItem>({name: 'Select a source organization', id: -1});
  const [destinationOrg, setDestinationOrg] = useState<IDropDownItem>({name: 'Select a destination organization', id: -1});
  const [enableMoveButton, setEnableMoveButton] = useState(false);
  const [moveTeam, moveTeamData] = useMoveTeamMutation();
  
  useEffect(() => {
    const orgsAreDifferent = sourceOrg.id !== destinationOrg.id;
    const teamIdIsValid = view.id !== -1;
    const orgIdsAreValid = sourceOrg.id !== -1 && destinationOrg.id !== -1;

    organizations.forEach((org) => {
      org.teams.forEach((team) => {
        if (team.id === view.id) {
          if(sourceOrg.id != org.id) {
            setSourceOrg({name: org.name, id: org.id});
          }
        }
      });
    });

    setEnableMoveButton(orgsAreDifferent && teamIdIsValid && orgIdsAreValid);
  }, [sourceOrg.id, destinationOrg.id, view.id]);

  return (
    <Card title="Move Team" subTitle="Moves a team to a different organization">
      <div className="grid grid-cols-8 items-center px-6 pt-2 pb-2">
        <div className="col-span-5">
          <VirtualizedComboBox
            comboBoxData={organizations.flatMap((org) =>
              org.teams.map((team) => {
                return { name: `${team.name} (${team.id})`, id: team.id };
              })
            )}
            setSelectedItem={(item) => {
              if(!item) throw new Error('No item selected');
              setView({name: item.name, id: item.id as number});
            }}
            id="team-combobox"
            selectedItem={view}
            onClear={() => {
              setView({name: 'Select a team', id: -1});
            }}
          />

          <VirtualizedComboBox
            comboBoxData={organizations.map((org) => {
              return {name: org.name, id: org.id}
            })}
            id="source-org-combobox"
            setSelectedItem={(item) => {
              if(!item) throw new Error('No item selected');
              setSourceOrg({name: item.name, id: item.id as number});
            }}
            selectedItem={sourceOrg}
            onClear={() => {
              setSourceOrg({name: 'Select a source organization', id: -1});
            }}
          />

          <VirtualizedComboBox
            comboBoxData={organizations.map((org) => {
              return {name: org.name, id: org.id}
            })}
            id="dest-org-combobox"
            setSelectedItem={(item) => {
              if(!item) throw new Error('No item selected');
              setDestinationOrg({name: item.name, id: item.id as number});
            }}
            selectedItem={destinationOrg}
            onClear={() => {
              setDestinationOrg({name: 'Select a destination organization', id: -1});
            }}
          />
        </div>
        <div className="col-span-3 flex flex-row justify-end">
          <Button
            buttonType={ButtonType.Secondary}
            enabled={enableMoveButton}
            loading={moveTeamData.loading}
            text="Move Team"
            onClick={() => {
              moveTeam({
                variables: {
                  destinationOrgId: destinationOrg.id,
                  originOrgId: sourceOrg.id,
                  teamId: view.id
                },
                onCompleted: () => {
                  toast.success('Team moved successfully');
                },
                onError: (error) => {
                  toast.error(`Error moving team: ${error.message}`);
                }
              });
            }}
          />
        </div>
      </div>
    </Card>
  );
};

const CurrentTeamSettingsSection = () => {
  const { currentTeam, curTeamId } = useValidTeamAppContext();
  return (
    <div className="text-blueberry">
      <h1 className="text-xl font-semibold">Current Team Admin Tools</h1>
      <p>
        Team: {currentTeam.name} ({curTeamId})
      </p>
      <TeamDefaultsCard />
    </div>
  );
};

const KickOffNLPPipelineCard = ({ organizations, orgsHaveLoaded }: { organizations: OrganizationWithTeamInfo[]; orgsHaveLoaded?: boolean }): JSX.Element => {
  const [nlpKickoffTeam, setNlpKickoffTeam] = useState<IDropDownItem | undefined>();
  const [launchNLP, launchNlpData] = useLaunchNlpMutation();
  const [message, setMessage] = useState<string | undefined>();
  if (!orgsHaveLoaded || !organizations) {
    return <AdjustableLoadingIcon width={5} height={5} />;
  }
  
  return (
    <Card title="Kick off NLP for Team" subTitle="Launches the full NLP pipeline for the chosen team">
      <div className="grid grid-cols-8 items-center px-6 pt-2 pb-2">
        {!orgsHaveLoaded || !organizations ? (
          <AdjustableLoadingIcon width={5} height={5} />
        ) : (
          <>
            <div className="col-span-5">
              <VirtualizedComboBox
                comboBoxData={organizations.flatMap((org) =>
                  org.teams.map((team) => {
                    return { name: `${team.name} (${team.id})`, id: team.id };
                  })
                )}
                //@ts-ignore
                setSelectedItem={setNlpKickoffTeam}
                selectedItem={nlpKickoffTeam}
              />
            </div>
            <div className="col-span-3 flex flex-row justify-end">
              <Button
                buttonType={ButtonType.Secondary}
                enabled={nlpKickoffTeam !== undefined}
                loading={launchNlpData.loading}
                text="Kick Off NLP"
                onClick={() => {
                  setMessage(`Kicking off NLP for team: ${nlpKickoffTeam!.id}`);
                  launchNLP({
                    variables: { teamId: nlpKickoffTeam!.id },
                    onCompleted: () => setMessage(`NLP Launched for team: ${nlpKickoffTeam?.id}`),
                    onError: (err) => setMessage(err.message),
                  });
                }}
              />
            </div>
            {message ? <div className="col-span-8 flex flex-row justify-start pt-2">{message}</div> : <div className="pb-8"></div>}
          </>
        )}
      </div>
    </Card>
  );
};

const CopyEntriesFromTeamCard = ({ organizations, orgsHaveLoaded }: { organizations: OrganizationWithTeamInfo[]; orgsHaveLoaded?: boolean }): JSX.Element => {
  const [originTeam, setOriginTeam] = useState<IDropDownItem | undefined>();
  const [message, setMessage] = useState<string | JSX.Element | undefined>();
  const { currentTeam } = useContext(AppContext);

  const [copyTeamEntriesMutation, _] = useCopyTeamEntriesMutation({});

  function handleCopyEntries() {
    if (originTeam && currentTeam) {
      setMessage(
        <h1>
          Copying entries from team: <br />
          <b>{originTeam.name} </b> <br />
          into team: <br />
          <b>
            {currentTeam.name} ({currentTeam.id}){' '}
          </b>
        </h1>
      );
      copyTeamEntriesMutation({
        variables: {
          originTeamId: originTeam.id,
          destinationTeamId: currentTeam.id,
        },
        onCompleted: () =>
          setMessage(
            <h1>
              <p className="text-green-600">Success!</p>
              Copied entries from team: <br />
              <b>{originTeam.name} </b> <br />
              into team: <br />
              <b>
                {currentTeam.name} ({currentTeam.id}){' '}
              </b>
            </h1>
          ),
        onError: (err) => setMessage(err.message + ' (check the team entries in case it timed out)'),
      });
    }
  }

  return (
    <Card title="Copy data from Team" subTitle="Copies all entries FROM the selected team into the current one">
      {!orgsHaveLoaded || !organizations ? (
        <AdjustableLoadingIcon width={5} height={5} />
      ) : (
        <div className="grid grid-cols-8 gap-x-2 items-center px-6 pt-2 pb-2">
          <div className="col-span-5">
            <VirtualizedComboBox
              comboBoxData={organizations.flatMap((org) =>
                org.teams.map((team) => {
                  return { name: team.name + ` (${team.id})`, id: team.id };
                })
              )}
              //@ts-ignore
              setSelectedItem={setOriginTeam}
              selectedItem={originTeam}
            />
          </div>
          <div className="col-span-3 flex flex-row justify-end">
            <Button
              buttonType={ButtonType.Secondary}
              enabled={originTeam !== undefined}
              loading={false}
              text="Copy Entries from team"
              onClick={handleCopyEntries}
            />
          </div>
          {message ? <div className="col-span-8 flex flex-row justify-start pt-2">{message}</div> : <div className="pb-8"></div>}
        </div>
      )}
    </Card>
  );
};

export default AdminPage;
