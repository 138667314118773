import { useContext } from 'react';

import { useState } from 'react';
import { RootFilter } from './RootFilter';
import { FilterContext, FilterDispatchContext } from '../../../../context/filterStatementContext';
import { FilterConfigurationPopup } from '../filterConfiguration/FilterConfiguration';
import { FilterGroupActionType, FilterStatementSchema } from '../../../../reducers/filterStatement/filterStatementReducer';
import { DeleteButton } from './DeleteButton';
import Button, { ButtonSize } from '../../../baseComponents/Button';
import { ButtonShape } from '../../../baseComponents/Button';
import { ButtonVariant } from '../../../baseComponents/Button';

export const FilterBar = () => {
  const { appliedFilter } = useContext(FilterContext);
  const dispatch = useContext(FilterDispatchContext);

  // We use this to determine whether to show the save and delete buttons
  // the applied filter is always a collection, but it may be empty (no items)
  // If the filter has items, it means the user has added at least one filter, so we show the save and delete buttons
  const isFilterPopulated = appliedFilter && appliedFilter.type === 'collection' && appliedFilter.items.length > 0;

  const onFilterCreateComplete = (node: FilterStatementSchema) => {
    dispatch({
      type: FilterGroupActionType.AddFilterNode,
      payload: {
        filterNode: node,
      },
    });
  };

  return (
    <div className="relative">
      <div className="flex flex-row gap-x-2 items-center relative">
        <div className="flex flex-row flex-wrap gap-x-2 gap-y-2 items-center" id="add-filter-button">
          {isFilterPopulated ? <RootFilter filter={appliedFilter} /> : null}
          <div className="flex flex-row gap-x-2 relative">
            <FilterConfigurationPopup onComplete={onFilterCreateComplete}>
              <Button variant={ButtonVariant.Bordered} shape={ButtonShape.Pill} size={ButtonSize.Small} text="+ Add filter" />
            </FilterConfigurationPopup>
          </div>
          {isFilterPopulated ? (
            // show save button here when you ready for SAVED FILTERS!!!
            <DeleteButton
              onClick={() => {
                dispatch({
                  type: FilterGroupActionType.RemoveFilterNode,
                  payload: {
                    id: appliedFilter.id,
                  },
                });
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
