import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonShape, ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';
import { Action, GetPlotDocument, Resource, useSavePlotMutation } from '../../../generated/graphql';
import { AppRoutes } from '../../../Routes';
import { useBoardState } from '../../../context/boardContext';
import { ChartActionType } from '../../../reducers/charts/chartReducer';
import { useChartDispatch } from '../../../context/chartContext';
import { useChartState } from '../../../context/chartContext';
import toast from 'react-hot-toast';

const ActionBar = () => {
  let navigate = useNavigate();
  const { board } = useBoardState();
  const { hasPermission } = useContext(PermissionsContext);
  const canCreateCharts = hasPermission(Resource.Charts, Action.Create) && hasPermission(Resource.Charts, Action.Update);
  const [loading, setLoading] = useState(false);

  const navigateToBoard = () => {
    let navigatePath;
    if (board.id !== -1) navigatePath = `${AppRoutes.v3FullPath.boards}/${board.id}`;
    else navigatePath = `${AppRoutes.v3FullPath.boards}`;
    navigate(navigatePath);
    /**
     * When navigating back to the board we need to clear the chartState
     * This is to avoid showing the previous chart in the edit page when creating a new widget or clicking into a chart that failed to load.
     */
    chartDispatch({ type: ChartActionType.ClearChartState, payload: {} });
  };

  const [savePlot] = useSavePlotMutation();

  const { id, chartConfigs, teamId } = useChartState();
  const chartDispatch = useChartDispatch();

  const handleSaveChart = async () => {
    if (chartConfigs.title === '') {
      toast.error('Chart title cannot be empty');
      return;
    }
    setLoading(true);
    await savePlot({
      variables: {
        id,
        plotConfigurationInput: chartConfigs,
        teamId,
        boardId: board.id,
      },
      onCompleted(data) {
        chartDispatch({ type: ChartActionType.SetChartId, payload: { id: data.savePlot } });
      },
      //If there are already widgets, the layout update brings the new widget for free.
      //If it's the very first, this simplifies the logic of adding it to the layout by just refetching it.
      refetchQueries: board.widgets.length === 0 ? ['GetBoard'] : [],
    });

    navigateToBoard();
    setLoading(false);
  };

  return (
    <div className="flex flex-row justify-between">
      <Button
        id="back-button"
        variant={ButtonVariant.Tertiary}
        shape={ButtonShape.Pill}
        size={ButtonSize.Small}
        text="Back"
        onClick={() => navigateToBoard()}
        icon={<p>{'<'}</p>}
        iconPosition="left"
      />
      <div className="flex gap-x-2">
        <Button
          id="cancel-chart-creation"
          shape={ButtonShape.Pill}
          size={ButtonSize.Small}
          variant={ButtonVariant.Tertiary}
          text="Cancel"
          onClick={navigateToBoard}
        />
        <Button
          id="save-chart"
          shape={ButtonShape.Pill}
          size={ButtonSize.Small}
          variant={ButtonVariant.Primary}
          loadingConfirm={loading}
          disabled={!canCreateCharts}
          disabledTooltip="You don't have permission to create charts"
          text="Save Chart"
          onClick={async () => {
            await handleSaveChart();
          }}
        />
      </div>
    </div>
  );
};

export default ActionBar;
