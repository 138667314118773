import { createContext, useReducer } from 'react';
import { DataSourceField, FeedbackIntegrationReducerResult, feedbackIntegrationReducer } from '../reducers/feedbackIntegration';
import { FeedbackIntegrationAction } from '../actions/feedbackIntegration';
import { Feedback_Integration_Type, SegmentGroupQuery } from '../../generated/graphql';
import { FeedbackIntegrationState } from '../reducers/feedbackIntegration';

export const DUMMY_FEEDBACK_INTEGRATION = {
  filters: [],
  integrationName: '',
  requirementValues: [],
  segmentConfig: [],
  defaultDistillateDisplay: false,
  type: {
    logoUrl: '',
    title: '',
    feedbackIntegrationType: Feedback_Integration_Type.DataImport,
    id: 0,
    description: '',
    scraperKey: ''
  },
  integrationTypeRequirements: []
}

export const DUMMY_DATA_SOURCE_FIELDS = [
  {
    path: 'path',
    values: ['value1', 'value2']
  }
]

const defaultState = {
    feedbackIntegration: DUMMY_FEEDBACK_INTEGRATION,
    oldFeedbackIntegration: DUMMY_FEEDBACK_INTEGRATION,
    segmentGroups: {},
    dataSourceFields: DUMMY_DATA_SOURCE_FIELDS,
    nextNegativeSegmentGroupId: -1,
    nextNegativeSegmentConfigId: -1,
    sourceFieldsLoading: false
};

export const FeedbackIntegrationContext = createContext<FeedbackIntegrationReducerResult>(defaultState);

export const FeedbackIntegrationDispatchContext = createContext<React.Dispatch<FeedbackIntegrationAction>>(() => {});

export function FeedbackIntegrationProvider({ children, initialFeedbackIntegration, initialOldFeedbackIntegration, initialSegmentGroups, initialDataSourceFields }: { children: React.ReactNode, initialFeedbackIntegration: FeedbackIntegrationState, initialOldFeedbackIntegration: FeedbackIntegrationState, initialSegmentGroups: SegmentGroupQuery, initialDataSourceFields: DataSourceField[] }) {
    const [feedbackIntegration, dispatch] = useReducer(
        feedbackIntegrationReducer,
        {
            feedbackIntegration: initialFeedbackIntegration,
            oldFeedbackIntegration: initialOldFeedbackIntegration,
            segmentGroups: initialSegmentGroups,
            dataSourceFields: initialDataSourceFields,
            nextNegativeSegmentGroupId: -1,
            nextNegativeSegmentConfigId: -1,
            sourceFieldsLoading: false
        }
    );
  
    return (
      <FeedbackIntegrationContext.Provider value={feedbackIntegration}>
        <FeedbackIntegrationDispatchContext.Provider value={dispatch}>
          {children}
        </FeedbackIntegrationDispatchContext.Provider>
      </FeedbackIntegrationContext.Provider>
    );
}




