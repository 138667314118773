import { DataSourceField, FeedbackIntegrationFilter, FeedbackIntegrationRequirementValue, FeedbackIntegrationState, FeedbackSegmentConfig } from "../reducers/feedbackIntegration";



export enum FeedbackIntegrationActionType {
    UpdateIntegration,
    UpdateDataSourceFields,
    UpdateOldFeedbackIntegration,
    AddSegments,
    AddFilter,
    UpdateExistingFilter,
    DeleteFilter,
    UpdateRequirementsAndName,
    DeleteSegment,
}

type UpdateIntegrationPayload = { feedbackIntegration: FeedbackIntegrationState };
type UpdateDataSourceFieldsPayload = { dataSourceFields: DataSourceField[], sourceFieldsLoading: boolean; };
type DeleteFilterPayload = { filterIndex: number };
type UpdateFilterPayload = { filter: FeedbackIntegrationFilter, filterIndex: number };
type AddFilterPayload = { filter: FeedbackIntegrationFilter };
type UpdateSegmentsPayload = { segment: FeedbackSegmentConfig };
type UpdateRequirementsAndNamePayload = { requirements: FeedbackIntegrationRequirementValue[], name: string };

export type FeedbackIntegrationAction =
| { type: FeedbackIntegrationActionType.UpdateIntegration; payload: UpdateIntegrationPayload }
| { type: FeedbackIntegrationActionType.UpdateDataSourceFields; payload: UpdateDataSourceFieldsPayload }
| { type: FeedbackIntegrationActionType.UpdateOldFeedbackIntegration; payload: UpdateIntegrationPayload }
| { type: FeedbackIntegrationActionType.AddSegments; payload: UpdateSegmentsPayload }
| { type: FeedbackIntegrationActionType.DeleteSegment; payload: UpdateSegmentsPayload }
| { type: FeedbackIntegrationActionType.AddFilter; payload: AddFilterPayload }
| { type: FeedbackIntegrationActionType.UpdateExistingFilter; payload: UpdateFilterPayload }
| { type: FeedbackIntegrationActionType.DeleteFilter; payload: DeleteFilterPayload }
| { type: FeedbackIntegrationActionType.UpdateRequirementsAndName; payload: UpdateRequirementsAndNamePayload };