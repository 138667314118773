import { XMarkIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import Button, { ButtonShape, ButtonVariant } from "../../baseComponents/Button";
import { useContext } from "react";
import { FeedbackIntegrationContext } from "../../context/FeedbackIntegrationContext";

interface Segment {
    value: string,
    feedbackSegmentConfigId: string,
    feedbackSegmentGroupId: string,
}

export interface FeedbackEntry {
    fullText: string,
    segments: Segment[],
}

interface ValidatedDataModalProps {
    onClose: () => void;
    onSave:() => void;
    validatedData: FeedbackEntry[];
}

export const ValidatedDataModal = (props: ValidatedDataModalProps) => {
    const data = useContext(FeedbackIntegrationContext);
    const segmentGroups = data.feedbackIntegration.segmentConfig.map(segment => segment.segmentGroup);
    return (
        <>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center pt-20 z-50"
                onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        props.onClose();
                    }
                }}
                id="validated-data-modal"
            >
                <div className="bg-white border border-gray-200 shadow-md rounded-xl overflow-hidden w-full max-w-[550px]">
                    <div className="bg-blueberry_lighter bg-opacity-10 relative py-8 px-8 lg:px-14 text-center">
                        <button className="absolute top-3 right-3" onClick={props.onClose} id="validated-data-modal-close-button">
                            <XMarkIcon className="w-5 h-5 text-gray-400"/>                                              
                        </button>
                        <h3 className="text-2xl font-semibold text-blueberry mb-0">Validation process is complete</h3>
                        <h4 className="text-lg text-gray-400 mt-1 font-semibold">Review the results that match your criteria</h4>
                    </div>
                    <div className="max-h-[60vh] overflow-y-auto">
                        <div className="pt-10">
                            {props.validatedData.map((entry, index) => (
                                <div key={index} className="mb-8 border-b border-gray-200 px-8 lg:px-14 pb-8 last:border-b-0">
                                    <p className="text-blueberry">{entry.fullText}</p>
                                    <div className="mt-3 flex gap-2 flex-wrap">
                                        {entry.segments.map((segment, index) => (
                                            <span key={index} className="pt-0.5 pb-1 px-2 rounded-md bg-gray-200 text-blueberry text-sm">{`${segmentGroups.find(segmentGroup => segmentGroup.id === Number(segment.feedbackSegmentGroupId))?.displayName || "Could not find segment name"}: ${segment.value}`}</span>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="border-t-2 border-gray-200 py-6 px-8 lg:px-14 flex flex-col lg:flex-row gap-4 items-center justify-between">
                        <Button 
                            shape={ButtonShape.Pill}
                            variant={ButtonVariant.Tertiary}
                            onClick={() => {props.onClose()}}
                            text="Cancel"
                            className="w-full lg:w-auto min-w-48 h-10 flex flex-row items-center justify-center gap-x-2 whitespace-nowrap font-sofiapro font-semibold transition-all disabled:cursor-not-allowed disabled:opacity-50 duration-200 pl-5 pr-4 pt-1 pb-2 text-md rounded-full bg-blueberry text-white hover:opacity-80 hover:bg-blueberry"
                        />
                        <Button
                            shape={ButtonShape.Pill}
                            variant={ButtonVariant.Primary}
                            onClick={() => {
                                props.onClose();
                                props.onSave();
                            }}
                            text="Apply"
                            icon={<CheckCircleIcon className="w-5 h-5 text-white"/>}
                            className="w-full lg:w-auto min-w-48 h-10 flex flex-row items-center justify-center gap-x-2 whitespace-nowrap font-sofiapro font-semibold transition-all disabled:cursor-not-allowed disabled:opacity-50 duration-200 pl-5 pr-4 pt-1 pb-2 text-md rounded-full bg-blueberry text-white hover:opacity-80 hover:bg-blueberry"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}