import { IntegrationFilters } from "./IntegrationFilters";
import { useContext, useState } from "react";
import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../context/FeedbackIntegrationContext";
import { IntegrationFiltersModal } from "./IntegrationFiltersModal";
import { FeedbackIntegrationActionType } from "../../actions/feedbackIntegration";
export default function IntegrationFiltersSection() {
    const data = useContext(FeedbackIntegrationContext);
    const dispatch = useContext(FeedbackIntegrationDispatchContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingFilterIndex, setEditingFilterIndex] = useState<number | null>(null);

    const filters = data.feedbackIntegration!.filters;

    return (
        <>
            <div className="flex flex-row items-center gap-x-2">
                {filters.length > 0 ? filters.map((filter, index) => (
                    <>
                            <IntegrationFilters 
                                onClose={() => {}} 
                                onEdit={() => {
                                    setEditingFilterIndex(index);
                                    setIsModalOpen(true);
                                }} 
                                filter={filter} 
                                deleteFilter={() => {
                                    dispatch({ 
                                        type: FeedbackIntegrationActionType.DeleteFilter, 
                                        payload: { filterIndex: index } 
                                    });
                                }}
                            />
                        {index < filters.length - 1 && <p className="text-blueberry font-semibold">and</p>}
                    </>
                )) : <div className="italic text-gray-400">No filters yet ... Set up some custom fields to add some.</div>}
            </div>
            {isModalOpen && (
                <IntegrationFiltersModal 
                    onClose={() => {
                        setIsModalOpen(false);
                        setEditingFilterIndex(null);
                    }}
                    editingFilterIndex={editingFilterIndex}
                />
            )}
        </>
    );
}