import { SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APIV2 } from '../../../api/apiv2';
import { FeedbackIntegrationFragment, Integration_Type, Integration_Type_Requirement } from '../../../generated/graphql';
import CSVIntegrationModal from '../Modals/CSVIntegrationModal';
import { IIntegrationRedirect } from '../../pages/IntegrationsPage';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import EditIntegrationModal, { Page } from '../Modals/integrations/EditIntegrationModal';
import { AppRoutes } from '../../../Routes';

/**
 * This component needs to take in the integrationType, and a map of the requirements and their values.
 * For the integrations row on the Integrations page, we will want to wrap this in a component that makes a query to pull in the integration based off of the integrationid.
 * For the integrations scroller, we will want to pass it with no requirement values.
 */

interface IntegrationCardNewProps {
  isConnected?: boolean;
  integrationType: Integration_Type;
  integration: FeedbackIntegrationFragment | undefined;
  redirect?: IIntegrationRedirect;
  setSuccessModalOpen?: React.Dispatch<SetStateAction<boolean>>;
  setModalOpen: (bool: boolean) => void;
  modalOpen: boolean;
}

export const IntegrationCardNew = ({ integrationType, integration, redirect, setSuccessModalOpen, modalOpen, setModalOpen }: IntegrationCardNewProps): JSX.Element => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const navigate = useNavigate();

  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState(null);
  const [modalSuccessMsg, setModalSuccessMsg] = useState<string | null>(null);
  const [modalRawErrorMsg, setModalRawErrorMsg] = useState(null);
  const [name, setName] = useState('');
  const [requirements, setRequirements] = useState<Integration_Type_Requirement[]>(() => {
    return integrationType.requirements.map(req => ({
      ...req,
      value: integration?.requirementValue
        ?.filter(r => r.requirement.id === req.id)
        ?.map(r => ({
          value: r.value,
          id: req.id,
          requirement: req
        })) || []
    }));
  });

  const confirmIntegration = async ({ csvToUpload, source, sourceName, isCsv, isRedshift }: any) => {
    setIsLoadingConfirm(true);
    try {
      if (isCsv) await APIV2.teams.integrations.createCSVIntegration(teamId, sourceName, csvToUpload);
      else if (isRedshift) await APIV2.teams.integrations.createRedshiftIntegration(teamId, sourceName, csvToUpload);
      else await APIV2.teams.integrations.createIntegration(teamId, source, csvToUpload);
      setModalErrorMsg(null);
      setModalSuccessMsg(`Successfully integrated ${isCsv ? 'CSV' : source}. Items have been added to your dashboard.`);
      setModalOpen(false);
    } catch (err: any) {
      console.error('error uploading data', err.response?.data);
      setModalSuccessMsg(null);
      setModalErrorMsg(err.response?.data?.msg || 'Unknown error');
      setModalRawErrorMsg(err.response?.data?.raw_error);
    }
    setIsLoadingConfirm(false);
  };
  useEffect(() => {
    setModalErrorMsg(null);
    setModalSuccessMsg(null);
    if (redirect && redirect.scraperKey === integrationType.scraperKey) {
      const requirements: Integration_Type_Requirement[] = integrationType.requirements.map((req) => {
        if (req.key === 'authToken') {
          return { ...req, value: [{ value: redirect.authToken, id: 0, requirement: req }] };
        }
        if (req.key === 'url_zendesk' && redirect.url_zendesk) {
          return { ...req, value: [{ value: redirect.url_zendesk, id: 0, requirement: req }] };
        }
        if (redirect.additionalObjects[req.key]) {
          return { ...req, value: [{ value: redirect.additionalObjects[req.key], id: 0, requirement: req }] };
        }
        return req;
      });
      setRequirements([...requirements]);
      setModalOpen(true);
    }
  }, []);

  const callbackResetData = () => {
    /*Hack to reset error/success/disabled when another file is selected. Can be improved */
    setModalErrorMsg(null);
    setModalSuccessMsg(null);
  };

  const updateIntegration = (integrationType: Integration_Type, requirements: Integration_Type_Requirement[], name: string) => {
    navigate(`${AppRoutes.v3FullPath.integrations}/add`, { state: { integrationType, requirements, name } });

  }

  return (
    <div>
      {integrationType.requirements.length > 0 ? (
        <EditIntegrationModal
          name={name}
          setName={setName}
          updateIntegration={updateIntegration}
          integrationType={integrationType}
          setRequirements={setRequirements}
          requirements={requirements}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setSuccessModalOpen={setSuccessModalOpen}
          page={Page.import}
        />
      ) : (
        <CSVIntegrationModal
          modalOpen={modalOpen}
          source={integrationType.title}
          callbackModal={() => setModalOpen(false)}
          confirmButton={confirmIntegration}
          loadingConfirm={isLoadingConfirm}
          errorMsg={modalErrorMsg}
          successMsg={modalSuccessMsg}
          callbackResetData={callbackResetData}
          rawErrorMsg={modalRawErrorMsg}
        />
      )}
    </div>
  );
};

export default IntegrationCardNew;
