import { useLocation, useNavigate } from "react-router-dom";
import { DUMMY_FEEDBACK_INTEGRATION, FeedbackIntegrationProvider } from "../context/FeedbackIntegrationContext";
import { Integration_Type, Integration_Type_Requirement, useSegmentGroupQuery } from "../../generated/graphql";
import { FeedbackIntegrationState } from "../reducers/feedbackIntegration";
import { IntegrationSettingsPageContent } from "../components/integrationsSettings/IntegrationSettingsPageContent";
import { useValidTeamAppContext } from "../../v2/contexts/AppContext";
import LoadingSpinner from "../baseComponents/LoadingSpinner";
import { AppRoutes } from "../../Routes";
import { useEffect } from 'react';


interface AddNewIntegrationPageProps {
    pageName: string;
}

export default function AddNewIntegrationPage (props: AddNewIntegrationPageProps) {
    const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
    const { integrationType, requirements, name } = useLocation().state;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location.state) {
            navigate(`${AppRoutes.v3FullPath.integrations}`, { replace: true });
        }
    }, []);

    const feedbackIntegrationState = createFeedbackIntegrationState(integrationType, requirements, name);

    const { data: segmentGroupsData, loading: segmentGroupsLoading, error: segmentGroupsError } = useSegmentGroupQuery({
        variables: { teamId: teamId},
    });


    const loading = segmentGroupsLoading || 
                   !segmentGroupsData;

    return (
        (loading ? 
            <div className="fixed inset-0 flex flex-col items-center justify-center">
                <LoadingSpinner/> 
                <div className="text-gray-500 mt-4">Pulling metadata for integration...</div>
            </div>
        :
            <FeedbackIntegrationProvider 
                initialFeedbackIntegration={feedbackIntegrationState} 
                initialOldFeedbackIntegration={DUMMY_FEEDBACK_INTEGRATION} // This will always be different from the initialFeedbackIntegration
                initialSegmentGroups={segmentGroupsData}
                initialDataSourceFields={[]}
            >
                <IntegrationSettingsPageContent 
                    onSaveSuccess={async () => {
                        navigate(`${AppRoutes.v3FullPath.integrations}`, {
                            state: { displayModal: true },
                            replace: true
                        });
                        return {newIntegration: feedbackIntegrationState};
                    }} 
                />
            </FeedbackIntegrationProvider>
        )
    );
}

function createFeedbackIntegrationState(integrationType: Integration_Type, requirements: Integration_Type_Requirement[], name: string): FeedbackIntegrationState {
    return {
        integrationName: name,
        filters: [],
        requirementValues: requirements.map(requirement => ({
            requirement: requirement,
            value: requirement.value?.[0]?.value ?? "",
        })),
        segmentConfig: [],
        defaultDistillateDisplay: false,
        type: integrationType,
        integrationTypeRequirements: requirements.map(req => ({
            __typename: "integration_type_requirement",
            id: req.id,
            key: req.key,
            obfuscate: req.obfuscate,
            title: req.title,
            description: req.description,
            example: req.example,
            frontendReplacementKey: req.frontendReplacementKey,
            regex: req.regex,
            required: req.required,
            requiredForAuthRedirect: req.requiredForAuthRedirect,
            category: req.category,
        }))
    };
}