import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../context/FeedbackIntegrationContext";
import { FeedbackIntegrationActionType } from "../../actions/feedbackIntegration";
import { useContext, useState, useEffect, useCallback } from "react";
import Button, { ButtonShape, ButtonVariant } from "../../baseComponents/Button";
import { ConfigureIntegrationButton } from "./ConfigureIntegrationButton";
import { ConfigurationTypeHeader } from "./ConfigurationTypeHeader";
import { AddLabelsModal } from "./AddLabelsModal";
import { AddCustomFieldsModal } from "./AddCustomFieldsModal";
import { IntegrationFiltersModal } from "./IntegrationFiltersModal";
import { buildFeedbackIntegrationMutationFromState } from "../../lib/feedbackIntegration";
import CustomFieldsSection from "./CustomFieldsSection";
import LabelsSection from "./LabelsSection";
import IntegrationFiltersSection from "./IntegrationFiltersSection";
import { ValidateIntegrationConfigurationCard } from "./ValidateIntegrationConfigurationCard";
import { useValidTeamAppContext } from "../../../v2/contexts/AppContext";
import { IExecutionResult, useFeedbackIntegrationMutation, useGetIntegrationDataSourceFieldsQuery, usePollExecutionQuery } from "../../../generated/graphql";
import LoadingSpinner from "../../baseComponents/LoadingSpinner";
import { toast } from "react-hot-toast";
import { FeedbackIntegrationState } from "../../reducers/feedbackIntegration";
import InformationModal from "../Modals/InformationModal";
import { Page } from "../Modals/integrations/EditIntegrationModal";
import { ArrowRightIcon } from "@heroicons/react/24/outline";


interface QueryRes {
    success: boolean;
    error: string | null;
  }

interface IntegrationSettingsPageContentProps {
    onSaveSuccess: () => Promise<{ newIntegration: FeedbackIntegrationState | null}>;
}

export const IntegrationSettingsPageContent = ({ onSaveSuccess }: IntegrationSettingsPageContentProps) => {
    const [isAddCustomFieldsOpen, setIsAddCustomFieldsOpen] = useState(false);
    const [isAddLabelsOpen, setIsAddLabelsOpen] = useState(false);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [changesMade, setChangesMade] = useState(false);

    const data = useContext(FeedbackIntegrationContext);
    const dispatch = useContext(FeedbackIntegrationDispatchContext);
    const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
    const [setFeedbackIntegrationMutation, setFeedbackIntegrationMutationInput] = useFeedbackIntegrationMutation();
    const [isSaving, setIsSaving] = useState(false);
    const [modalState, setModalState] = useState(false);

    const { refetch: initiateDescribeFields } = useGetIntegrationDataSourceFieldsQuery({
        skip: true
    });

    const { refetch: pollDescribeFields } = usePollExecutionQuery({
        skip: true
    });

    // Update changesMade when integration data changes
    useEffect(() => {
        const sortRequirementValues = (integration: FeedbackIntegrationState) => {
            return {
                ...integration,
                requirementValues: [...integration.requirementValues].sort((a, b) => 
                    a.requirement.id - b.requirement.id
                )
            };
        };

        const oldStr = JSON.stringify(sortRequirementValues(data.oldFeedbackIntegration));
        const newStr = JSON.stringify(sortRequirementValues(data.feedbackIntegration));
        
        if (oldStr !== newStr) {
            console.log('Integration changed');
            setChangesMade(true);
        } else {
            setChangesMade(false);
        }
    }, [data.feedbackIntegration, data.oldFeedbackIntegration]);

    const handleSuccessDescribeFields = (executionResult: IExecutionResult): void => {
        const sourceFields = JSON.parse(executionResult?.responsePayload || "{sourceFields: []}").sourceFields;
        const formattedFields = sourceFields.map((field: any) => ({
            path: field.path,
            values: field.sample_values,
        }));
        dispatch({ 
            type: FeedbackIntegrationActionType.UpdateDataSourceFields, 
            payload: {
                dataSourceFields: formattedFields,
                sourceFieldsLoading: false
            }
        });
    }

    const handleFailedDescribeFields = (): void => {
        toast.error("Failed to get integration source fields");
        dispatch({ 
            type: FeedbackIntegrationActionType.UpdateDataSourceFields, 
            payload: {
                dataSourceFields: [],
                sourceFieldsLoading: false
            }
        });
    }
    // Fetch source fields
    useEffect(() => {
        const fetchDataSourceFields = async () => {
            dispatch({ 
                type: FeedbackIntegrationActionType.UpdateDataSourceFields, 
                payload: {
                    dataSourceFields: [], 
                    sourceFieldsLoading: true 
                } 
            });
            try {
                const describeResult = await initiateDescribeFields({
                    input: buildFeedbackIntegrationMutationFromState(data.feedbackIntegration!, teamId)
                });

                if (describeResult.data?.describeSourceFields?.describeSourceFieldsUuid) {
                    const uuid = describeResult.data.describeSourceFields.describeSourceFieldsUuid;
                    
                    while (true) {
                        const pollResult = await pollDescribeFields({
                            executionUuid: uuid,
                            teamId: teamId,
                            orgId: orgId
                        });

                        const status = pollResult.data?.pollExecution?.status;
                        if (status === "SUCCEEDED") {
                            handleSuccessDescribeFields(pollResult.data!.pollExecution!);
                            break;
                        } else if (status === "FAILED") {
                            handleFailedDescribeFields();
                            break;
                        }
                        await new Promise(resolve => setTimeout(resolve, 200));
                    }
                }
            } catch (error) {
                toast.error("Failed to get integration source fields");
                dispatch({ 
                    type: FeedbackIntegrationActionType.UpdateDataSourceFields, 
                    payload: {
                        dataSourceFields: [],
                        sourceFieldsLoading: false
                    }
                });
            }
        };

        fetchDataSourceFields();
    }, []);

    async function sendFeedbackIntegrationMutation(feedbackIntegration: FeedbackIntegrationState, teamId: number) {
        return new Promise<QueryRes>(async (resolve) => {
            try {
                const result = await setFeedbackIntegrationMutation({
                    variables: { input: buildFeedbackIntegrationMutationFromState(feedbackIntegration, teamId) },
                    refetchQueries: ['Integrations'],
                    awaitRefetchQueries: true,
                    fetchPolicy: 'network-only'
                });
                
                if (result.data) {
                    resolve({ success: true, error: null });
                } else {
                    resolve({ success: false, error: 'No data returned' });
                }
            } catch (error) {
                resolve({ 
                    success: false, 
                    error: error instanceof Error ? error.message : 'Unknown error' 
                });
            }
        });
    }

    const handleSave = async () => {
        setIsSaving(true);
        try {
            const res = await sendFeedbackIntegrationMutation(data.feedbackIntegration!, teamId);
            if (!res.success) {
                console.error('Save failed:', res.error);
                toast.error("Save failed");
                setIsSaving(false);
                return;
            }
            const newData = await onSaveSuccess();
            if (newData.newIntegration) {
                dispatch({
                    type: FeedbackIntegrationActionType.UpdateOldFeedbackIntegration,
                    payload: {
                        feedbackIntegration: newData.newIntegration,
                    }
                });
                setChangesMade(false);
                setIsSaving(false);
                toast.success("Successfully saved changes");
                setModalState(true);
            } else {
                setIsSaving(false);
                toast.error("Failed to save changes. Please try again later.");
            }
        } catch (error) {
            setIsSaving(false);
            console.error('Save failed:', error);
            toast.error("An error occurred while saving");
        }
    };

    return (
        <>
            {isSaving && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <LoadingSpinner />
                </div>
            )}
            {data?.feedbackIntegration ? (
                <div className="pt-5 pb-10" id="integration-settings-page-content">
                    <InformationModal
                        modalOpen={modalState}
                        callbackModal={() => setModalState(false)}
                        text={'Integration connected successfully!'}
                        subtext={'Your integration was created successfully! Please check your dashboard for insights in ~60 minutes.'}
                    />
                    <div className="font-sofiapro">
                        <div id="page-wrapper" className="flex flex-col gap-y-2 bg-white undefined mx-8">
                            <div className="flex flex-col gap-y-8 bg-white">
                                <div className="flex justify-between items-center">
                                    <h1 className="text-blueberry text-3xl font-semibold">
                                        <img 
                                            src={data.feedbackIntegration.type?.logoUrl} 
                                            alt="" 
                                            className="w-6 h-6 lg:h-8 lg:w-8 object-contain inline -mt-2"
                                        />
                                        {` ${data.feedbackIntegration.type?.title}: `}
                                        <span className="text-gray-500">
                                            {data.feedbackIntegration.integrationName || `${data.feedbackIntegration.type?.title} Integration`}
                                        </span>
                                    </h1>
                                </div>
                                <div className="flex flex-col gap-14">
                                    <div className="flex flex-col gap-8">
                                        <div className="lg:hidden flex justify-center gap-3">
                                            <Button 
                                                type="button" 
                                                shape={ButtonShape.Pill} 
                                                variant={ButtonVariant.Tertiary} 
                                                text="Discard changes" 
                                                disabled={!changesMade}
                                                onClick={() => {
                                                    dispatch({
                                                        type: FeedbackIntegrationActionType.UpdateIntegration,
                                                        payload: {
                                                            feedbackIntegration: data.oldFeedbackIntegration,
                                                        }
                                                    });
                                                    toast.success("Changes discarded");
                                                }}
                                                id="discard-integration-changes-button-small"
                                            />
                                            <Button 
                                                type="button" 
                                                shape={ButtonShape.Pill} 
                                                disabled={!changesMade}
                                                variant={ButtonVariant.Primary} 
                                                text="Save changes" 
                                                onClick={handleSave}
                                                id="save-integration-changes-button-small"
                                            />
                                        </div>

                                        <div className="lg:hidden flex justify-center">
                                            <ConfigureIntegrationButton id="configure-integration-button-small" page={Page.import} buttonText="Establish Connection" />
                                        </div>

                                        <div className="hidden lg:flex items-center justify-between w-full">
                                            <div className="flex-none">
                                                <ConfigureIntegrationButton id="configure-integration-button-large" page={Page.import} buttonText="Establish Connection" />
                                            </div>
                                            <div className="flex-grow mx-8">
                                                <div className="h-[1px] bg-gray-300 w-full"></div>
                                            </div>
                                            <div className="flex-none flex gap-3">
                                                <Button 
                                                    type="button" 
                                                    shape={ButtonShape.Pill} 
                                                    variant={ButtonVariant.Tertiary} 
                                                    text="Discard changes" 
                                                    disabled={!changesMade}
                                                    onClick={() => {
                                                        dispatch({
                                                            type: FeedbackIntegrationActionType.UpdateIntegration,
                                                            payload: {
                                                                feedbackIntegration: data.oldFeedbackIntegration,
                                                            }
                                                        });
                                                        toast.success("Changes discarded");
                                                    }}
                                                    id="discard-integration-changes-button-large"
                                                />
                                                <Button 
                                                    type="button" 
                                                    shape={ButtonShape.Pill} 
                                                    disabled={!changesMade}
                                                    variant={ButtonVariant.Primary} 
                                                    text="Save changes" 
                                                    onClick={handleSave}
                                                    id="save-integration-changes-button-large"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col lg:flex-row lg:gap-20">
                                        <div className="flex flex-col gap-14 w-full lg:w-3/5">
                                            <div className="mb-8 lg:mb-0">
                                                <ConfigurationTypeHeader 
                                                    title="Labels" 
                                                    onIconClick={() => setIsAddLabelsOpen(true)} 
                                                    showIcon={true} 
                                                    showChildren={isAddLabelsOpen}
                                                    id="labels"
                                                    disableButton={false}
                                                    children={
                                                        <AddLabelsModal 
                                                            onClose={() => setIsAddLabelsOpen(false)} 
                                                        />
                                                    }
                                                />
                                                <LabelsSection/>
                                            </div>
                                            <div>
                                                <ConfigurationTypeHeader 
                                                    title="Custom fields" 
                                                    onIconClick={() => {setIsAddCustomFieldsOpen(true)}} 
                                                    showIcon={true}
                                                    showChildren={isAddCustomFieldsOpen}
                                                    id="custom-fields"
                                                    disableButton={false}
                                                    children={
                                                        <AddCustomFieldsModal 
                                                            onClose={() => setIsAddCustomFieldsOpen(false)} 
                                                        />
                                                    }
                                                />
                                                <CustomFieldsSection />
                                            </div>
                                            <div className={data.feedbackIntegration.segmentConfig.length > 0 ? 'mb-8 lg:mb-0' : ''}>
                                                <ConfigurationTypeHeader 
                                                    title="Filters" 
                                                    onIconClick={() => {setIsFiltersOpen(true)}} 
                                                    showIcon={true}
                                                    showChildren={isFiltersOpen}
                                                    id="filters"
                                                    disableButton={data.feedbackIntegration.segmentConfig.filter(segment => segment.path !== "OVERRIDE").length === 0}
                                                    children={
                                                        <IntegrationFiltersModal 
                                                            onClose={() => setIsFiltersOpen(false)} 
                                                        editingFilterIndex={null}
                                                    />
                                                }
                                                />
                                                <IntegrationFiltersSection />
                                            </div>
                                            {data.feedbackIntegration.integrationTypeRequirements.some(req => req.category === 'reply') && (
                                                <div className="mb-8 lg:mb-0">
                                                    <ConfigurationTypeHeader 
                                                        title="Reply to reviews" 
                                                        onIconClick={() => {}} 
                                                        showIcon={false} 
                                                        showChildren={false}
                                                        id="reply"
                                                        disableButton={false}
                                                    />
                                                    <ConfigureIntegrationButton id="reply-button" page={Page.reply} buttonText="Connect" icon={<ArrowRightIcon className="w-4 h-4 stroke-2"/>} />
                                                </div>
                                            )}
                                        </div>

                                        <div className="w-full lg:w-2/5">
                                            <ValidateIntegrationConfigurationCard onSave={handleSave} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

