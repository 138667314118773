import moment from "moment";
import { FilterInput } from "../../generated/graphql";

export class GroupLinkBuilder {
  private teamId: number;
  private orgId: number;
  private url: URL;
  private urlParams: URLSearchParams;

  constructor(teamId: number, orgId: number) {
    this.teamId = teamId;
    this.orgId = orgId;
    this.url = new URL(window.location.href);
    this.urlParams = new URLSearchParams(this.url.search);
  }

  public fromConsumable(filterConsumable: string): string {
    this.urlParams.set('filterConsumable', encodeURIComponent(filterConsumable));
    return this.build();
  }

  private build(): string {
    this.urlParams.set('teamId', this.teamId.toString());
    this.urlParams.set('orgId', this.orgId.toString());
    return this.url.toString();
  }

  public fromFilterInput(groupId: string, filterInput: FilterInput, urlKey?: string): string {
    const filter: FilterInput = {
      ...filterInput,
      startDate: filterInput.startDate,
      endDate: !moment().endOf('day').isSame(filterInput.endDate) ? filterInput.endDate : undefined,
    };

    this.urlParams.set('teamId', this.teamId.toString());
    this.urlParams.set('orgId', this.orgId.toString());
    this.urlParams.set(urlKey ?? 'filters', encodeURIComponent(JSON.stringify(filter)));

    const pathname = window.location.pathname.replace('home', 'explore') + (window.location.pathname.includes('/group') ? '' : '/group/' + groupId);
    const newUrl = window.location.protocol + '//' + window.location.host + pathname + '?' + this.urlParams.toString();
    return newUrl;
  }
}
