import { FC } from 'react';

interface TagProps {
  text: string;
}

export const Tag: FC<TagProps> = ({ text }) => {
  return (
    <div
      className={`
      bg-silver-darker
      py-1
      px-2
      justify-center
      items-center
      rounded-md
      text-xs
      font-medium
      text-blueberry
    `}
    >
      {text}
    </div>
  );
};

export default Tag;
