import { TrashIcon } from "@heroicons/react/24/outline";
import Button, { ButtonShape, ButtonVariant } from "../../baseComponents/Button";   
import { FeedbackIntegrationFilter, FeedbackIntegrationState } from "../../reducers/feedbackIntegration";

interface IntegrationFiltersProps {
    onClose: () => void;
    onEdit: () => void;
    filter: FeedbackIntegrationFilter;
    deleteFilter: () => void;
}

export const IntegrationFilters = (props: IntegrationFiltersProps) => {

    return (
        <div className="inline-flex">
            <div className="flex items-center bg-gray-200 rounded-3xl overflow-hidden">
                <div className="flex flex-row items-center group px-4 py-1.5 relative">
                    <div className="pr-1 flex items-center">
                        <button className="text-left whitespace-nowrap text-gray-500 [&>strong]:text-blueberry [&>*]:hover:text-raspberry [&>*]:duration-200 [&>*]:transition-all [&>*]:ease-in-out leading-tight flex items-center" onClick={props.onEdit}>
                            <span className="mr-1 max-w-32 truncate text-ellipsis">{props.filter.segmentGroup.displayName}</span>
                            <strong className="max-w-32 truncate text-ellipsis">{props.filter.comparison} {props.filter.value}</strong>
                        </button>
                    </div>      
                    
                    <div className="flex items-center transition-all duration-200">
                        <div className="w-4 bg-[linear-gradient(to_right,rgba(229,231,235,0),rgba(229,231,235,1))] h-full"></div>
                        <Button 
                            shape={ButtonShape.Circle} 
                            icon={<TrashIcon className="h-5 w-5 text-blueberry hover:text-raspberry" />} 
                            iconPosition={"right"} 
                            variant={ButtonVariant.IconRawWithHover} 
                            onClick={props.deleteFilter} 
                            className="bg-gray-200" 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const deleteIntegrationFilter = (filter: FeedbackIntegrationFilter, feedbackIntegration: FeedbackIntegrationState) => {
    // Create a deep copy of the entire object structure
    const newFeedbackIntegration = {
        ...feedbackIntegration,
        segmentConfig: feedbackIntegration.segmentConfig.map(config => ({
            ...config,
            segmentGroup: { ...config.segmentGroup }
        }))
    };

    // Remove the filter from the newFeedbackIntegration
    newFeedbackIntegration.filters = newFeedbackIntegration.filters.filter(f => f !== filter);

    return newFeedbackIntegration;
}