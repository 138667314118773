import uuid from 'react-uuid';
import { FilterType } from '../../generated/graphql';
import moment from 'moment';

export type FilterStatementSchema = {
  type: 'statement';
  fieldName: string;
  operator: string;
  value: string;
  fieldDisplayName?: string;
  valueDisplayName?: string[];
};

export const getDefaultState = (props: { teamId: number }): FilterState => {
  const defaultStartDate = moment().subtract(90, 'days').startOf('day').toDate();

  const dateFilter: FilterStatementState = {
    type: 'statement',
    fieldName: 'Entry.date',
    operator: '>=',
    value: defaultStartDate.toISOString(),
    id: uuid(),
  };

  const defaultAppliedFilter: FilterNodeState = {
    type: 'collection',
    operator: FilterType.And,
    items: [],
    id: uuid(),
  };

  return {
    teamId: props.teamId,
    appliedFilter: defaultAppliedFilter,
    staticConditions: [dateFilter],
    filterConsumable: computeFilterConsumable([dateFilter], undefined),
  };
};

export type FilterStatementState = FilterStatementSchema & {
  id: string;
};

export type FilterCollectionSchema = {
  type: 'collection';
  operator: string; // defaults to AND if not specified
  items: FilterNodeSchema[];
};

export type FilterCollectionState = {
  id: string;
  type: 'collection';
  operator: string; // defaults to AND if not specified
  items: FilterNodeState[];
};

export type FilterNodeSchema = FilterStatementSchema | FilterCollectionSchema;
export type FilterNodeState = FilterStatementState | FilterCollectionState;

export type FilterState = {
  /**
   * This is the filter that is currently applied to the page.
   *
   * Set the appliedFilter to always be a collection instead of either a collection or statement
   */
  appliedFilter: FilterNodeState;
  /**
   * This is the teamId that the filters are applied to.
   *
   * This is specifically to support charts that support n teams with no guarantee they belong to the same team.
   */
  teamId: number;
  /**
   * Is this a good idea? I'm not sure. The point is, on the Feedback page, we have the date filters and the filter bar
   * We need to ensure that those are always ANDed together and with the rest of the filters.
   *
   * _Someone_ needs to know what needs to be ANDed with what. We could have the reducer enforce this but I'm worried that implementation detail leak between pages and this reducer.
   * This staticConditions allows the page to specify any of these additional ANDed filters.
   *
   * Effectively how do we Guarantee that the date filters are always ANDed with the rest of the filters?
   *
   */
  staticConditions: FilterStatementState[];

  /**
   * This is the stringified json of the total filter statement.
   */
  filterConsumable: string;
};

export enum FilterGroupActionType {
  AddFilterNode,
  RemoveFilterNode,
  UpdateFilterNode,
  /**
   * How do we do this? Now that we're using something much more flexible how do we set the top level operator?
   */
  AlterStaticFilters,
  UpdateCollectionOperator,
  SetAppliedFilter,
}

export interface AddFilterNodePayload {
  filterNode: FilterStatementSchema;
}

export interface RemoveFilterNodePayload {
  id: string;
}

export interface SetAppliedFilterPayload {
  filterNode: FilterNodeSchema;
}

export interface UpdateCollectionOperatorPayload {
  id: string;
  operator: string;
}

export interface UpdateFilterNodePayload {
  id: string;
  filterNode: FilterNodeSchema;
}

export interface UpsertStaticFiltersPayload {
  filterNodes: FilterStatementSchema[];
}

export interface RemoveStaticFiltersPayload {
  fieldNames: string[];
}

export interface AlterStaticFiltersPayload {
  upsert: UpsertStaticFiltersPayload;
  remove: RemoveStaticFiltersPayload;
}

export type FilterGroupPayload =
  | AddFilterNodePayload
  | RemoveFilterNodePayload
  | UpdateFilterNodePayload
  | AlterStaticFiltersPayload
  | UpdateCollectionOperatorPayload
  | SetAppliedFilterPayload;
export type FilterGroupAction = {
  type: FilterGroupActionType;
  payload: FilterGroupPayload;
};

export const filterStatementReducer = (state: FilterState, action: FilterGroupAction): FilterState => {
  switch (action.type) {
    case FilterGroupActionType.AddFilterNode:
      const payload = action.payload as AddFilterNodePayload;
      return addFilterNode(state, payload);
    case FilterGroupActionType.RemoveFilterNode:
      const removePayload = action.payload as RemoveFilterNodePayload;
      return removeFilterNode(state, removePayload);
    case FilterGroupActionType.UpdateFilterNode:
      const updatePayload = action.payload as UpdateFilterNodePayload;
      return updateFilterNode(state, updatePayload);
    case FilterGroupActionType.UpdateCollectionOperator:
      const updateCollectionOperatorPayload = action.payload as UpdateCollectionOperatorPayload;
      return updateCollectionOperator(state, updateCollectionOperatorPayload);
    case FilterGroupActionType.SetAppliedFilter:
      const setAppliedFilterPayload = action.payload as SetAppliedFilterPayload;
      return setAppliedFilter(state, setAppliedFilterPayload);
    /**
     * I'm not sure about this top level filter idea.... wanna discuss with you
     */
    case FilterGroupActionType.AlterStaticFilters:
      const alterStaticPayload = action.payload as AlterStaticFiltersPayload;
      return alterStaticFilters(state, alterStaticPayload);
    default:
      throw new Error('Unknown action type');
  }
};

const setAppliedFilter = (state: FilterState, payload: SetAppliedFilterPayload): FilterState => {
  const newAppliedFilter = convertToFilterNodeState(payload.filterNode);
  return {
    ...state,

    appliedFilter: newAppliedFilter,
    filterConsumable: computeFilterConsumable(state.staticConditions, newAppliedFilter),
  };
};

const convertToFilterNodeState = (filterNode: FilterNodeSchema): FilterNodeState => {
  if (filterNode.type === 'statement') {
    return { ...filterNode, id: uuid() } as FilterStatementState;
  }
  return { ...filterNode, id: uuid(), items: filterNode.items.map((item) => convertToFilterNodeState(item)) } as FilterCollectionState;
};

/**
 * This is meant to handle the page level filters, that the page has explicitly set.
 * The examples of this are the:
 * - Date filters on the Feedback, Explore, Group, Boards pages
 * - The search bar on the Feedback page
 * - The search bar on the Explore page
 * - The trending / new groups filters on the Explore page
 * etc.
 *
 * Here the page needs a way to modify these filters without having to parse through and find what's currently there.
 * Example:
 * - Changing the search term on the search bar on the Feedback page
 * -- The page should just be able to fire off an event to say upsert a new search term filter, the page should have to know that a search was already applied
 * -- then remove the old search term filter and apply the new one.
 * - Changing the date range on the Feedback page
 * -- If there's a date filter already applied, the page should just be able to say remove the old date filter and apply the new one.
 *
 * Actually, should this just all happen on upsert?
 *
 * @param state
 * @param payload
 * @returns
 */
const alterStaticFilters = (state: FilterState, payload: AlterStaticFiltersPayload): FilterState => {
  // remove any filters that match the remove fields to avoid duplicates
  const filtersPostRemove = state.staticConditions.filter((filter) => {
    return !payload.remove.fieldNames.some((fieldName) => fieldName === filter.fieldName);
  });

  // First remove any filters that match the upsert fields to avoid duplicates
  const existingFilters = filtersPostRemove.filter((filter) => {
    return !payload.upsert.filterNodes.some((newFilter) => newFilter.fieldName === filter.fieldName && newFilter.operator === filter.operator);
  });

  // Add the new filters with generated IDs
  const newFilters = payload.upsert.filterNodes.map((filter) => ({
    ...filter,
    id: uuid(),
  })) as FilterStatementState[];

  const newTopLevelAndConditions = [...existingFilters, ...newFilters];

  return {
    ...state,
    staticConditions: newTopLevelAndConditions,
    filterConsumable: computeFilterConsumable(newTopLevelAndConditions, state.appliedFilter),
  };
};

const updateCollectionOperator = (state: FilterState, payload: UpdateCollectionOperatorPayload): FilterState => {
  if (!state.appliedFilter) {
    return state;
  }

  // Helper function to recursively process nodes
  const updateNode = (node: FilterNodeState): FilterNodeState => {
    if (node.type !== 'collection') {
      return node;
    }
    // If this is the node to update
    if (node.id === payload.id) {
      return {
        ...node,
        operator: payload.operator,
      } as FilterNodeState; // I don't know why we need this type script fuckery.
    }

    // Recursive case: process collection
    return {
      ...node,
      items: node.items.map((item) => updateNode(item)),
    };
  };

  // Start the recursion from the top-level filter
  const updatedFilter = updateNode(state.appliedFilter);

  return {
    ...state,
    appliedFilter: updatedFilter,
    filterConsumable: computeFilterConsumable(state.staticConditions, updatedFilter),
  };
};

const updateFilterNode = (state: FilterState, payload: UpdateFilterNodePayload): FilterState => {
  if (!state.appliedFilter) {
    return state;
  }

  // Helper function to recursively process nodes
  const updateNode = (node: FilterNodeState): FilterNodeState => {
    // If this is the node to update
    if (node.id === payload.id) {
      return {
        id: node.id, // Preserve the original ID
        ...payload.filterNode,
      } as FilterNodeState;
    }
    // If this is a statement node (leaf)
    if (node.type === 'statement') {
      return node;
    }

    // Recursive case: process collection
    if (node.type === 'collection') {
      return {
        ...node,
        items: node.items?.map((item) => updateNode(item)),
      };
    }

    throw new Error('Unknown node type');
  };

  // Start the recursion from the top-level filter
  const updatedFilter = updateNode(state.appliedFilter);

  return {
    ...state,
    appliedFilter: updatedFilter,
    filterConsumable: computeFilterConsumable(state.staticConditions, updatedFilter),
  };
};
/**
 * Given the id of a filter node, remove it from the filter tree.
 * @param state
 * @param payload
 * @returns
 */
const removeFilterNode = (state: FilterState, payload: RemoveFilterNodePayload): FilterState => {
  if (!state.appliedFilter) {
    return state;
  }

  if (!payload.id) {
    throw new Error('No id provided to removeFilterNode');
  }

  // Helper function to recursively process nodes
  const removeFromNode = (node: FilterNodeState): FilterNodeState | undefined => {
    // Base case: if this is the node to remove
    if (node.id === payload.id) {
      return undefined;
    }

    // Base case: if this is a statement node (leaf)
    if (node.type === 'statement') {
      return node;
    }

    // Recursive case: process collection
    const filteredItems = node.items.map((item) => removeFromNode(item)).filter((item): item is FilterStatementState => item !== undefined);

    if (filteredItems.length === 0) {
      return undefined;
    }

    return {
      ...node,
      items: filteredItems,
    };
  };

  // Start the recursion from the top-level filter
  const updatedFilter = removeFromNode(state.appliedFilter);

  return {
    ...state,
    appliedFilter: updatedFilter ?? { type: 'collection', operator: FilterType.And, items: [], id: uuid() },
    filterConsumable: computeFilterConsumable(state.staticConditions, updatedFilter),
  };
};

const addFilterNode = (state: FilterState, payload: AddFilterNodePayload): FilterState => {
  let newAppliedFilter: FilterNodeState;
  if (state.appliedFilter) {
    // we have a currently applied filter
    if (state.appliedFilter.type === 'statement') {
      newAppliedFilter = {
        type: 'collection',
        operator: FilterType.And,
        items: [state.appliedFilter, { ...payload.filterNode, id: uuid() }],
        id: uuid(),
      };
    } else {
      // we have a currently applied collection
      newAppliedFilter = {
        ...state.appliedFilter,
        items: [...state.appliedFilter.items, { ...payload.filterNode, id: uuid() }],
      };
    }
    return {
      ...state,
      appliedFilter: newAppliedFilter,
      filterConsumable: computeFilterConsumable(state.staticConditions, newAppliedFilter),
    };
  }

  newAppliedFilter = { ...payload.filterNode, id: uuid() };
  return {
    ...state,
    appliedFilter: newAppliedFilter,
    filterConsumable: computeFilterConsumable(state.staticConditions, newAppliedFilter),
  };
};

export const computeFilterConsumable = (topLevelAndConditions: FilterNodeState[], appliedFilter?: FilterNodeState): string => {
  const newFilterConsumable: FilterCollectionState = {
    type: 'collection',
    operator: FilterType.And,
    items: [...topLevelAndConditions],
    id: uuid(),
  };
  if (appliedFilter) {
    newFilterConsumable.items.push(appliedFilter);
  }

  // strip the id from the filter consumable and all of the items recursively
  const stripId = (node: FilterNodeState): any => {
    if (node.type === 'statement') {
      // remove the id from the statement don't just null it out
      const { id, ...rest } = node;
      return rest;
    } else if (node.type === 'collection') {
      const { id, ...rest } = node;

      return {
        ...rest,
        items: node.items.map((item) => stripId(item)),
      };
    }

    throw new Error('Unknown node type');
  };

  return JSON.stringify(stripId(newFilterConsumable));
};
