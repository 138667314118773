import { useContext } from 'react';
import { useChartState, useChartDispatch } from '../../../context/chartContext';
import { ChartActionType } from '../../../reducers/charts/chartReducer';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';
import { Action, Resource } from '../../../generated/graphql';
import InlineEditableTextArea from '../../../baseComponents/InlineEditableTextArea';

const ChartTitle = () => {
  const { hasPermission } = useContext(PermissionsContext);
  const canEditCharts = hasPermission(Resource.Charts, Action.Update);
  const chartState = useChartState();
  const chartDispatch = useChartDispatch();

  return (
    <InlineEditableTextArea
      placeholder={'Enter a title...'}
      ignorePadding
      editable={canEditCharts}
      textAreaId="chart-title"
      value={chartState.chartConfigs?.title ?? ''}
      onEditComplete={(newText: string) => {
        chartDispatch({ type: ChartActionType.UpdateChartTitle, payload: { title: newText } });
      }}
      additionalClassNames={
        'items-center text-blueberry h-auto focus:border-none hover:bg-silver focus:bg-silver bg-transparent transition-[background-color] duration-100 rounded-md '
      }
      textClassName="text-2xl xl:text-3xl font-semibold"
    />
  );
};

export default ChartTitle;
