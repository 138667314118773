import { FilterState } from '../../reducers/filterStatement/filterStatementReducer';
import { AppRoutes } from '../../Routes';
import { FilterNode } from './filterNode';

export const getGroupPageUrl = (teamId: number | null, orgId: number | null, groupId: string, filterState?: FilterState, extraParams?: string[]) => {
  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);

  urlParams.set('teamId', teamId?.toString() ?? '');
  urlParams.set('orgId', orgId?.toString() ?? '');
  if (filterState) {
    const filterNode = new FilterNode(filterState);
    urlParams.set('filterSet', filterNode.getUrlEncodedFilterNode());
  }

  const pathname = AppRoutes.v3FullPath.explore + '/group/' + groupId;
  let newUrl = pathname + '?' + urlParams.toString();

  if (extraParams) {
    extraParams.forEach((param) => {
      newUrl += '&' + param;
    });
  }
  return newUrl;
};
