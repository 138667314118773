import { useContext, useEffect } from 'react';
import { GroupDispatchContext } from '../../../context/groupContext';
import { useAmountOfEntriesLazyQuery, useGetGroupByIdQuery } from '../../../generated/graphql';
import { GroupActions } from '../../../reducers/group/GroupDispatch';
import { FilterContext } from '../../../context/filterStatementContext';
import { FilterCollectionSchema } from '../../../reducers/filterStatement/filterStatementReducer';

/**
 * This module should start to own fetching all the data for the group page
 * @param props
 */
export const GroupPageDataFetch = (props: { teamId: number; groupId: string }) => {
  const dispatch = useContext(GroupDispatchContext);
  const filterState = useContext(FilterContext);

  const { data, loading, error } = useGetGroupByIdQuery({
    fetchPolicy: 'network-only',
    variables: { teamId: props.teamId, groupId: props.groupId, filterStatement: filterState.filterConsumable },
  });

  const [getFilteredAmountOfEntries] = useAmountOfEntriesLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: { teamId: props.teamId, filterStatement: filterState.filterConsumable },
  });

  const [getUnfilteredEntries] = useAmountOfEntriesLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    // only fetch the entries with the static conditions like date and what not
  });

  /**
   * We do two separate fetches to load in the filtered and unfiltered denominator data... :/
   *
   */
  useEffect(() => {
    getFilteredAmountOfEntries({
      variables: { teamId: props.teamId, filterStatement: filterState.filterConsumable },
      onCompleted: (data) => {
        dispatch({ type: GroupActions.UPDATE_DENOMINATOR, payload: { totalEntries: data.amountOfEntries.amount } });
      },
    });
  }, [filterState.filterConsumable]);

  useEffect(() => {
    /**
     * This is a code smell. I'm sorry, this should be better encapsulated within the filterState I'd think
     */
    const staticConditionsPayload: FilterCollectionSchema = {
      items: filterState.staticConditions,
      operator: 'AND',
      type: 'collection',
    };

    getUnfilteredEntries({
      variables: { teamId: props.teamId, filterStatement: JSON.stringify(staticConditionsPayload) },
      onCompleted: (data) => {
        dispatch({ type: GroupActions.UPDATE_UNFILTERED_DENOMINATOR, payload: { totalEntries: data.amountOfEntries.amount } });
      },
    });
  }, [filterState.staticConditions]);

  useEffect(() => {
    if (loading) {
      dispatch({ type: GroupActions.SET_AUXILLARY_GROUP_DATA_LOADING, payload: {} });
    }
    if (error) {
      dispatch({ type: GroupActions.SET_AUXILLARY_GROUP_DATA_ERROR, payload: { error: error.message } });
      return;
    }
    if (data) {
      dispatch({
        type: GroupActions.UPDATE_AUXILLARY_GROUP_DATA,
        payload: {
          id: data.getGroup.id,
          summaryText: data.getGroup.summary,
          title: data.getGroup.title,
          isPinnedByUser: data.getGroup.isPinnedByUser,
          uniqueEntries: data.getGroup.uniqueEntries,
          tags: data.getGroup.tags,
          dateCreated: data.getGroup.createdAt,
          creator: data.getGroup.creator,
        },
      });
    }
  }, [data, loading, error]);
};
