import { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import 'tippy.js/themes/light.css';
import { useTeamGroupsTaxonomyFlatLazyQuery, useTeamGroupsTaxonomyTreeLazyQuery } from '../../generated/graphql';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { TaxonomyAction } from '../actions/taxonomy';
import { TaxonomyContext } from '../context/TaxonomyContext';
import { TaxonomyDispatchContext } from '../context/TaxonomyDispatchContext';
import { pageSize } from '../pages/explore/ExplorePageRouter';
import { GroupDeleter } from './taxonomy/GroupDeleter';
import { TaxonomyFolder } from './TaxonomyFolder';
import { FilterContext } from '../../context/filterStatementContext';
import { TaxonomyFolderSkeleton } from './taxonomy/TaxonomyFolderSkeleton';

import { TaxonomyGroup } from '../reducers/taxonomy';
interface TaxonomyViewProps {
  flatView?: boolean;
  discardSearchGroup: (groupId: string) => void;
  openAnnouncementModal: (d: number) => void;
  pageName: string;
}

export const  TaxonomyView = ({ flatView }: TaxonomyViewProps) => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({ threshold: 0 });
  const { curTeamId: teamId } = useValidTeamAppContext();
  const [curGroupIdToDelete, setCurGroupIdToDelete] = useState<string | null>(null);
  const [activeId, setActiveId] = useState<string | undefined>(undefined);

  const taxonomy = useContext(TaxonomyContext);
  const dispatch = useContext(TaxonomyDispatchContext);

  const [skip, setSkip] = useState(pageSize); // There will already be {pageSize} taxonomy groups loaded on page load

  const filterState = useContext(FilterContext);

  const [getTaxonomyTree, { loading: loadingTree }] = useTeamGroupsTaxonomyTreeLazyQuery({
    variables: { teamId, filterStatement: filterState.filterConsumable, take: 10000, skip: 0 },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const action: TaxonomyAction = {
        type: 'updateTaxonomy',
        payload: data,
      };
      dispatch(action);
    },
    onError(error) {
      dispatch({ type: 'error', payload: { error } });
    },
  });

  const [getTaxonomyFlat, { loading: loadingFlat }] = useTeamGroupsTaxonomyFlatLazyQuery({
    variables: { teamId, filterStatement: filterState.filterConsumable, take: 10000, skip: 0 },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const action: TaxonomyAction = {
        type: 'updateFlatTaxonomy',
        payload: data,
      };
      dispatch(action);
    },
    onError(error) {
      dispatch({ type: 'error', payload: { error } });
    },
  });

  useEffect(() => {
    if (inView && !loadingTree && !loadingFlat) {
      if (flatView) {
        getTaxonomyFlat({
          variables: { teamId, filterStatement: filterState.filterConsumable, take: pageSize, skip },
        });
      } else {
        getTaxonomyTree({
          variables: { teamId, filterStatement: filterState.filterConsumable, take: pageSize, skip },
        });
      }
      setSkip(skip + pageSize);
    }
  }, [inView, teamId, flatView]);

  const sortTaxonomyGroups = (a: TaxonomyGroup, b: TaxonomyGroup) => {
    // Processed items first
    if (a.processing && !b.processing) return -1;
    if (!a.processing && b.processing) return 1;

    // Then sort by totalEntries
    return b.totalEntries - a.totalEntries;
  };
  const topLevelGroups = Array.from(taxonomy.values())
    .filter((t: TaxonomyGroup) => t.parentId == undefined)
    .sort((a: TaxonomyGroup, b: TaxonomyGroup) => sortTaxonomyGroups(a, b));

  const renderGroup = (group: TaxonomyGroup, show: boolean, depth: number) => {
    const childItems =
      group.children
        ?.map((child) => child.id)
        .map((id) => taxonomy.get(id) ?? null)
        .filter((c) => c)
        .sort((a, b) => sortTaxonomyGroups(a as TaxonomyGroup, b as TaxonomyGroup))
        .map((c) => renderGroup(c as TaxonomyGroup, false, depth + 1)) ?? [];
    return (
      <TaxonomyFolder
        flatGroup={false}
        trending={group.trending}
        taxonomyGroup={taxonomy.get(group.id)!}
        itemRef={show ? ref : null}
        key={group.id}
        children={childItems}
        teamId={teamId}
        setActiveId={(id) => setActiveId(id)}
        setCurrentGroupId={(id) => setCurGroupIdToDelete(id)}
        zIndex={activeId === group.id ? 'z-20' : 'z-0'}
        depth={depth}
      />
    );
  };

  const renderFlatGroup = () => {
    const show: number = Array.from(taxonomy.values()).length - 2;

    const groups = Array.from(taxonomy.values()).map((group: TaxonomyGroup, index: number) => {
      return (
        <TaxonomyFolder
          flatGroup={true}
          trending={group.trending}
          taxonomyGroup={group}
          itemRef={show === index ? ref : null}
          key={group.id}
          children={[]}
          teamId={teamId}
          setActiveId={(id) => {
            setActiveId(id);
          }}
          setCurrentGroupId={(id) => {
            setCurGroupIdToDelete(id);
          }}
          zIndex={activeId === group.id ? 'z-20' : 'z-0'}
          depth={0}
        />
      );
    });
    return <>{groups}</>;
  };

  return (
    <>
      {/* {announcementId != null && (
        <AnnouncementModal
          groupId={announcementId}
          modalOpen={true}
          setModalOpen={() => setAnnouncementId(undefined)}
          teamId={teamId}
          orgId={orgId}
          orgName={currentOrg.name}
        />
      )} */}

      {curGroupIdToDelete !== null ? (
        <GroupDeleter
          groupToDelete={curGroupIdToDelete}
          closeCallback={() => {
            setCurGroupIdToDelete(null);
          }}
          deleteCallback={() => {
            navigate('/dashboard/explore', { replace: true });
          }}
        />
      ) : (
        <></>
      )}

      <div>
        {taxonomy.size === 0 ? (
          <p className="mt-12 flex flex-col items-center gap-y-4 pt-2">No search groups match the selected filters.</p>
        ) : flatView ? (
          renderFlatGroup()
        ) : (
          topLevelGroups.map((group, index, arr) => {
            const show: boolean = index === arr.length - 2;
            return renderGroup(group, show, 0);
          })
        )}
        {loadingTree || loadingFlat ? (
          <div className="flex flex-col gap-y-4 w-full my-4">
            {[...Array(3)].map((_, index) => (
              <TaxonomyFolderSkeleton key={index} flatGroup={flatView} depth={0} />
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TaxonomyView;
