import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import {
  DigestDataFragment,
  Digest_Group_Status,
  GetInsightsDocument,
  InsightLightFragment,
  Insight_Status,
  useGetInsightsQuery,
  useUpdateInsightMutation,
} from '../../../../generated/graphql';
import { ISettingsItem } from '../../../baseComponents/SettingsMenu';
import Badge from '../../Badge';
import InsightChartSection from '../insightCharts/InsightChartSection';
import EditInsightModal from './EditInsightModal';
import { useMemo, useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';
import InsightEntryReview from './InsightEntryReview';
import { getCollectionLink } from './MutateInsightModal';
import { capitalizeFirstLetter, truncateAndEllipsis } from '../../../../v2/util';
import { buildFilterInputFromSavedFilterInput } from '../../../pages/ChartsPage';
import { VirtualizedComboBox } from '../../VirtualizedComboBox';
import { toPresentableCase } from '../../../../reducers/utilities/enumHandling';
import Tippy from '@tippyjs/react';
import { IDropDownItem } from '../../../baseComponents/DropDown';

/**
 * This list loads insights in chunks as you scroll, using an observer+pagination, letting you know once you've reached the end.
 *
 * When new insights are added (e.g., via "Suggest Insights" on a component accessible from DigestMainSection), we need to
 * allow the user to keep scrolling to see more, even if they previously reached the end of the list.
 *
 * To achieve this:
 * - The component exposes reenablePagination() through a ref
 * - DigestMainSection calls this after adding new insights
 * - This resets the "hasMore" flag so scrolling/loading can continue until the new end is reached
 */

interface InsightsListContainerProps {
  digest: DigestDataFragment;
}

export interface InsightListContainerRef {
  reenablePagination: () => void;
}

const INSIGHTS_PER_PAGE = 15;

const EndMessage = () => <div className="text-center py-4 text-gray-500 italic">You've reached the end of insights for this digest</div>;

const InsightsListContainer = forwardRef<InsightListContainerRef, InsightsListContainerProps>(({ digest }, ref) => {
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setSkip(0);
    setHasMore(true);
  }, [digest.id]);

  const { loading, data, error, fetchMore } = useGetInsightsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      digestId: digest.id,
      take: INSIGHTS_PER_PAGE,
      skip: 0,
    },
  });

  const reenablePagination = useCallback(() => {
    setHasMore(true);
  }, []);

  useImperativeHandle(ref, () => ({
    reenablePagination,
  }));


  const loadMoreRef = useRef(null);

  const loadMoreInsights = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && !loading && hasMore) {
        const newSkip = skip + INSIGHTS_PER_PAGE;
        setSkip(newSkip);
        fetchMore({
          variables: {
            skip: newSkip,
            take: INSIGHTS_PER_PAGE,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            // If we get back fewer items than the page size, we've reached the end
            if (fetchMoreResult.getInsights.length < INSIGHTS_PER_PAGE) {
              setHasMore(false);
            }
            return {
              getInsights: [...prev.getInsights, ...fetchMoreResult.getInsights],
            };
          },
        });
      }
    },
    [loading, data, skip, fetchMore, hasMore]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(loadMoreInsights, {
      rootMargin: '500px',
    });

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => observer.disconnect();
  }, [loadMoreInsights]);


  if (loading && !data) {
    return <InsightListSkeleton />;
  } else if (data?.getInsights && data.getInsights.length > 0) {
    return (
      <>
        <InsightList digest={digest} insights={data.getInsights} />
        {hasMore && <div ref={loadMoreRef} className="h-10" />}
        {loading && <InsightListSkeleton />}
        {!hasMore && <EndMessage />}
      </>
    );
  } else if (error) {
    return <div>{error.message}</div>;
  }
  return <></>;
});

interface InsightsListProps extends InsightsListContainerProps {
  insights: InsightLightFragment[];
}

const settings: ISettingsItem[] = [
  {
    name: 'Approve',
    id: 1,
    group: 'approve',
    htmlId: 'approve-insight',
    icon: <CheckBadgeIcon className="h-6 w-6" />,
    onClick: () => {},
  },
  {
    name: 'Reject',
    id: 1,
    group: 'approve',
    htmlId: 'reject-insight',
    icon: <CheckBadgeIcon className="h-6 w-6" />,
    onClick: () => {},
  },
];

const InsightList = ({ digest, insights }: InsightsListProps) => {
  // when you click on an item we need to open the MutateInsightModal
  const [modelOpen, setOpen] = useState<boolean>(false);
  const [insight, setChoosenInsight] = useState<InsightLightFragment>(insights[0]);
  // Memoize the sorting and grouping of insights
  const sortedInsights = useMemo(() => {
    const approved = insights.filter((insight) => insight.insightStatus === Insight_Status.Approved);
    const nonApproved = insights.filter((insight) => insight.insightStatus !== Insight_Status.Approved);
    const sortedApproved = approved.sort((a, b) => {
      //Sorting by order, placing all -1 at the end
      if (a.order < 0) return 1;
      if (b.order < 0) return -1;
      return a.order - b.order;
    });
    return [...sortedApproved, ...nonApproved];
  }, [insights]);

  return (
    <div className="flex flex-col gap-y-3 mt-1 text-blueberry">
      <EditInsightModal isModalOpen={modelOpen} setOpen={setOpen} digest={digest} insight={insight} />
      {/* approved */}
      <InsightListByStatus
        digest={digest}
        insights={sortedInsights}
        setChoosenInsight={setChoosenInsight}
        setOpen={setOpen}
        statuses={[Insight_Status.Approved]}
      />
      {/* draft */}
      <InsightListByStatus
        digest={digest}
        insights={sortedInsights}
        setChoosenInsight={setChoosenInsight}
        setOpen={setOpen}
        statuses={[Insight_Status.Draft]}
      />
      {/* rejected */}
      <InsightListByStatus
        digest={digest}
        insights={sortedInsights}
        setChoosenInsight={setChoosenInsight}
        setOpen={setOpen}
        statuses={[Insight_Status.Denied]}
      />
    </div>
  );
};

const InsightListByStatus = (props: {
  insights: InsightLightFragment[];
  setOpen: (open: boolean) => void;
  setChoosenInsight: (insight: InsightLightFragment) => void;
  statuses: Insight_Status[];
  digest: DigestDataFragment;
}) => {
  return (
    <>
      {props.insights
        .filter((insight) => props.statuses.includes(insight.insightStatus))
        .map((insight) => {
          return (
            <InsightListItem
              key={insight.id}
              amountOfInsights={props.insights.length}
              digest={props.digest}
              insight={insight}
              onClick={() => {
                props.setOpen(true);
                props.setChoosenInsight(insight);
              }}
            />
          );
        })}
    </>
  );
};

const InsightListItem = ({
  insight,
  digest,
  onClick,
  amountOfInsights,
}: {
  insight: InsightLightFragment;
  digest: DigestDataFragment;
  onClick: () => void;
  amountOfInsights: number;
}) => {
  const collectionLink = getCollectionLink({
    collection: insight.collection,
    filterInput: buildFilterInputFromSavedFilterInput(insight.filterInput, insight.filterInput.startDate, insight.filterInput.endDate),
    teamId: insight.team.id,
    orgId: insight.team.orgId!,
  });
  // to make sure each insight is not too long, we truncate it to 400 characters
  const insight_display = truncateAndEllipsis(insight.text, 400);

  return (
    <Tippy content={`Insight created via: ${insight.creationSource}`}>
      <div className="border border-gray-300 rounded-md hover:cursor-pointer hover:bg-gray-100" key={insight.id} onClick={onClick}>
        <div className="grid grid-cols-8">
          {/* Chart */}
          <div className="col-span-4">
            <InsightChartSection insight={insight} />
          </div>

          {/* Insight */}
          <div className="flex flex-col justify-between col-span-4">
            <div className="flex flex-col p-3 gap-y-1">
              {/* Title Section */}

              <div className="grid grid-cols-8 justify-between gap-x-1">
                <div className="col-span-6">
                  <h1 className="font-bold text-xl capitalize">{insight.title}</h1>
                </div>
                {insight.insightStatus === Insight_Status.Approved ? (
                  <div
                    className="flex col-span-2"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <InsightOrderChooser insight={insight} digest={digest} amountOfInsights={amountOfInsights} />
                  </div>
                ) : null}
              </div>
              {/* Collection Info Section */}
              <a
                href={collectionLink}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                target="_blank"
                rel="noreferrer"
                className="ring-0 text-sm hover:underline focus:outline-none w-fit"
              >
                <b>{capitalizeFirstLetter(insight.collection.type)}</b> - {insight.collection.title}
              </a>

              {/* Text Section */}
              <div className="flex flex-row justify-between">
                <p className="font-normal italic text-md ">{insight_display}</p>
              </div>
              <ul className="flex flex-col gap-y-1 pl-5 list-disc list-inside">
                {insight.entries.map((entry) => {
                  // this needs to show the appropriate text here.
                  // Ideally this would be the same component that decides the text to show...
                  return <InsightEntryReview key={entry.id} entry={entry} />;
                })}
              </ul>
            </div>
            <div className="xl:h-11 w-full flex flex-col xl:flex-row justify-start xl:justify-between items-start xl:items-center p-3">
              {/* expiration date */}
              <p className="text-gray-400 italic text-sm">Exp: {new Date(insight.expirationDate).toDateString()}</p>
              <div className="flex flex-row gap-x-1">
                <Badge
                  color="bg-silver"
                  border={`border ${insight.feedbackStatus === Digest_Group_Status.GoingWell ? 'border-green-400' : 'border-red-400'}`}
                  textColor="text-blueberry"
                  badge={{ text: toPresentableCase(insight.feedbackStatus), id: '0' }}
                  smaller={true}
                  onEdit={() => {}}
                />
                <Badge
                  color={insightStatusColors[insight.insightStatus]}
                  badge={{ text: capitalizeFirstLetter(insight.insightStatus), id: '1' }}
                  smaller={true}
                  onEdit={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tippy>
  );
};

const insightStatusColors = {
  [Insight_Status.Approved]: 'bg-success',
  [Insight_Status.Denied]: 'bg-failure',
  [Insight_Status.Draft]: 'bg-blueberry-lighter',
};

const InsightOrderChooser = ({
  insight,
  digest,
  amountOfInsights,
}: {
  insight: InsightLightFragment;
  digest: DigestDataFragment;
  amountOfInsights: number;
}) => {
  const [updateInsight, _] = useUpdateInsightMutation({});

  const unorderedItem: IDropDownItem = { id: -1, name: 'Unordered', displayName: 'Unordered' };
  const comboBoxData: IDropDownItem[] = useMemo(() => {
    return Array.from({ length: amountOfInsights + 1 }, (_, i) => {
      if (i === 0) return unorderedItem;
      return { id: i - 1, name: (i - 1).toString() };
    });
  }, [amountOfInsights]);
  return (
    <VirtualizedComboBox
      useDisplayName
      disableClear
      disableAlphabeticalSort
      comboBoxData={comboBoxData}
      setSelectedItem={(item) => {
        updateInsight({
          variables: {
            entryIds: insight.entries.map((e) => e.id),
            expirationDate: insight.expirationDate,
            feedbackStatus: insight.feedbackStatus,
            filterInput: buildFilterInputFromSavedFilterInput(insight.filterInput, insight.filterInput.startDate, insight.filterInput.endDate),
            insightId: insight.id,
            insightStatus: insight.insightStatus,
            text: insight.text,
            title: insight.title,
            chartBin: insight.chartBin,
            order: Number(item?.id) ?? -1,
            excludeFromDigest: insight.excludeFromDigest,
          },
          refetchQueries: [{ query: GetInsightsDocument, variables: { digestId: digest.id } }],
          awaitRefetchQueries: true,
        });
      }}
      selectedItem={comboBoxData.find((cBItem) => cBItem.id === insight.order) ?? unorderedItem}
    />
  );
};

const InsightListSkeleton = () => {
  return (
    <div className="flex flex-col gap-y-8 mt-10">
      <InsightSkeletonItem />
      <InsightSkeletonItem />
      <InsightSkeletonItem />
      <InsightSkeletonItem />
      <InsightSkeletonItem />
    </div>
  );
};

const InsightSkeletonItem = () => {
  return (
    <div className="bg-white rounded-lg shadow-md p-4 animate-pulse">
      <div className="w-2/3 h-4 bg-gray-300 rounded mb-2"></div>
      <div className="w-full h-8 bg-gray-300 rounded mb-2"></div>
      <div className="w-full h-8 bg-gray-300 rounded mb-2"></div>
      <div className="w-1/2 h-8 bg-gray-300 rounded"></div>
    </div>
  );
};

export default InsightsListContainer;
