import { LinkIcon } from '@heroicons/react/24/outline';
import { writeToastMessage } from '../../../../../v2/hooks/GroupHook';
import { ToolbarBaseButton } from './ToolbarBaseButton';
import { useContext } from 'react';
import AppContext from '../../../../../v2/contexts/AppContext';
import { FilterContext } from '../../../../../context/filterStatementContext';
import { FilterNode } from '../../../../lib/filterNode';
import { AppRoutes } from '../../../../../Routes';

export function CopyGroupUrlButton(props: { buttonRounding?: 'left' | 'right'; groupId: string }) {
  const { curOrgId, curTeamId } = useContext(AppContext);
  const filterState = useContext(FilterContext);

  /**
   * Need to fetch the filters from the context
   */
  async function handleCopy() {
    const filterNode = new FilterNode(filterState);
    const groupLink = getGroupLink(props.groupId, filterNode, curTeamId!, curOrgId!);
    await navigator.clipboard.writeText(groupLink);
    writeToastMessage('Copied group link to clipboard');
  }
  return (
    <ToolbarBaseButton
      id="toolbar-copy-group-url-button"
      dataCy="toolbar-copy-group-url-button"
      icon={<LinkIcon className="h-5 w-5 stroke-2" />}
      label={'Copy Group URL'}
      onClick={handleCopy}
      buttonRounding={props.buttonRounding}
    />
  );
}

export const getGroupLink = (groupId: string, filterNode: FilterNode, teamId: number, orgId: number) => {
  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);

  urlParams.set('teamId', teamId.toString());
  urlParams.set('orgId', orgId.toString());
  urlParams.set('filterSet', filterNode.getUrlEncodedFilterNode());

  const pathname = AppRoutes.v3FullPath.explore + '/group/' + groupId;
  const newUrl = window.location.protocol + '//' + window.location.host + pathname + '?' + urlParams.toString();
  return newUrl;
};
