import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Group_Status, GroupDependencies, useEditGroupMutation } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { TaxonomyDispatchContext } from '../../context/TaxonomyDispatchContext';
import DeleteGroupModal from '../Modals/DeleteGroupModal';

export const GroupDeleter = ({
  groupToDelete,
  deleteCallback,
  closeCallback,
}: {
  groupToDelete: string;
  closeCallback: () => void;
  deleteCallback?: () => void;
}) => {

  const [dependencies, setDependencies] = useState<GroupDependencies | undefined>();
  const { curTeamId: teamId } = useValidTeamAppContext();
  const [editGroup, { loading: loadingDelete }] = useEditGroupMutation();
  const dispatch = useContext(TaxonomyDispatchContext);
  
  const confirmDeleteGroup = async () => {
    if (groupToDelete) {
      const { data } = await editGroup({
        variables: { 
          groupId: groupToDelete, 
          teamId: teamId, 
          input: { status: Group_Status.Archived }, 
          filterInput: {},
        }
      });
      if (data?.editGroup.success) {
        dispatch({ type: 'discardGroup', payload: { groupId: groupToDelete } });
        toast.success('Group deleted successfully');
        closeCallback();
        deleteCallback?.();
        return;
      }
      if (data?.editGroup?.dependencies) {
        setDependencies(data?.editGroup?.dependencies as GroupDependencies);
      } else {
        toast.error('Error deleting group');
        closeCallback();
      }
    }
  }
  
  return (
    <>
      {!!groupToDelete ? (
        <DeleteGroupModal
          modalOpen={!!groupToDelete}
          callbackModal={closeCallback}
          confirmButton={confirmDeleteGroup}
          loadingConfirm={loadingDelete}
          dependencies={dependencies}
        />
      ) : null}
    </>
  );
};

