import { useState } from 'react';
import { CheckCircleIcon, MinusCircleIcon } from '@heroicons/react/24/solid';
import { Integration_Type, Integration_Type_Requirement } from '../../../../generated/graphql';
import Modal from '../../../baseComponents/Modal';
import { ChatBubbleLeftIcon, ChatBubbleLeftRightIcon, ChevronRightIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { ReplyIntegrationForm } from './components/ReplyIntegrationFormDeprecated';
import { IntegrationForm } from './components/IntegrationFormDeprecated';

interface EditIntegrationModalProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  integration: Integration_Type;
  requirements: Integration_Type_Requirement[];
  setRequirements: (reqs: Integration_Type_Requirement[]) => void;
  setSuccessModalOpen?: (open: boolean) => void;
}

const EditIntegrationModal = ({ modalOpen, setModalOpen, requirements, setRequirements, integration, setSuccessModalOpen }: EditIntegrationModalProps) => {
  return (
    <Modal title={integration.title} open={modalOpen} setOpen={setModalOpen}>
      <div className="w-full h-full">
        <IntegrationTypeRouter
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          requirements={requirements}
          setRequirements={setRequirements}
          integration={integration}
          setSuccessModalOpen={setSuccessModalOpen}
        />
      </div>
    </Modal>
  );
};
export default EditIntegrationModal;

enum Page {
  router,
  import,
  reply,
}
const IntegrationTypeRouter = ({ modalOpen, setModalOpen, requirements, setRequirements, integration, setSuccessModalOpen }: EditIntegrationModalProps) => {
  const showReplyForm = requirements.some((req) => req.category === 'reply') || false;
  const [page, setPage] = useState<Page>(Page.router);

  if (!showReplyForm) {
    return (
      <IntegrationForm
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        requirements={requirements}
        setRequirements={setRequirements}
        integration={integration}
        setSuccessModalOpen={setSuccessModalOpen}
      />
    );
  }

  if (page === Page.router) return <IntegrationTypeSelectionList setPage={setPage} requirements={requirements} />;

  if (page === Page.reply) {
    return (
      <ReplyIntegrationForm
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        requirements={requirements}
        setRequirements={setRequirements}
        integration={integration}
        setSuccessModalOpen={setSuccessModalOpen}
      />
    );
  }

  return (
    <IntegrationForm
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      requirements={requirements}
      setRequirements={setRequirements}
      integration={integration}
      setSuccessModalOpen={setSuccessModalOpen}
    />
  );
};

const IntegrationTypeSelectionList = ({
  setPage,
  requirements,
}: {
  setPage: React.Dispatch<React.SetStateAction<Page>>;
  requirements: Integration_Type_Requirement[];
}) => {
  const hasImport = requirements
    .filter((requirement) => requirement.category === 'import')
    .every((requirement) => (requirement.required ? requirement.value[0]?.value : true));
  const hasReply = requirements.filter((requirement) => requirement.category === 'reply').every((requirement) => requirement.value[0]?.value);

  return (
    <div className="w-full h-64 flex flex-col justify-center items-start gap-4 text-blueberry ">
      <div
        className="w-full h-24 p-6 rounded-lg bg-silver border-2 flex flex-row justify-between items-center gap-x-4 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setPage(Page.import);
        }}
      >
        <div className="flex flex-row justify-start items-center  gap-x-4 ">
          <div className="p-2 border-[3px] border-blueberry rounded-full relative">
            <ChatBubbleLeftIcon className="h-12 w-12 stroke-0.5" />
            <div>
              {requirements
                .filter((requirement) => requirement.category === 'import')
                .every((requirement) => (requirement.required ? requirement.value : true)) ? (
                <CheckCircleIcon className="absolute -top-1 -right-1 h-6 w-6 bg-white rounded-full" />
              ) : (
                <MinusCircleIcon className="absolute -top-1 -right-1 h-6 w-6 bg-white rounded-full" />
              )}
            </div>
          </div>
          <div className="flex flex-col justify-center items-start py-2 ">
            <p className="text-lg font-semibold">Import Reviews</p>
            <div className="text-sm">{hasImport ? 'Connected' : 'Not Connected'}</div>
          </div>
        </div>
        <ChevronRightIcon className="h-8 w-8 stroke-2" />
      </div>

      <div
        className={`w-full h-24 p-6 rounded-lg bg-silver border-2 flex flex-row justify-between items-center gap-x-4 ${
          !hasImport ? 'opacity-50' : 'cursor-pointer'
        }`}
        onClick={(e) => {
          e.stopPropagation();
          if (hasImport) {
            setPage(Page.reply);
          }
        }}
      >
        <div className="flex flex-row justify-start items-center  gap-x-4">
          <div className="p-2 border-[3px] border-blueberry rounded-full relative">
            <ChatBubbleLeftRightIcon className="h-12 w-12 stroke-0.5" />
            <div>
              {hasReply ? (
                <CheckCircleIcon className="absolute -top-1 -right-1 h-6 w-6 bg-white rounded-full" />
              ) : (
                <MinusCircleIcon className="absolute -top-1 -right-1 h-6 w-6 bg-white rounded-full" />
              )}
            </div>
          </div>
          <div className="flex flex-col justify-center items-start pt-2">
            <div className="flex flex-row gap-x-6">
              <p className="text-lg font-semibold">Reply to Reviews</p>
              <div className="flex flex-row gap-x-1 bg-blueberry-lighter px-3 py-1 rounded-md items-center justify-center text-white">
                <SparklesIcon className="h-3 w-3" />
                <p className="text-xs">New</p>
              </div>
            </div>
            <div className="text-sm">{hasReply ? 'Connected' : 'Not Connected'}</div>
          </div>
        </div>
        <div>
          <ChevronRightIcon className="h-8 w-8 stroke-2" />
        </div>
      </div>
    </div>
  );
};
