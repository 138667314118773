import { SegmentGroupInput, SegmentConfigInput, IntegrationFilterInput, MutateFeedbackIntegration } from "../../generated/graphql";
import { FeedbackIntegrationState } from "../reducers/feedbackIntegration";

export const buildFeedbackIntegrationMutationFromState= (feedbackIntegrationState: FeedbackIntegrationState, teamId: number): MutateFeedbackIntegration => {
    
    // Get unique segment groups by displayName
    const uniqueSegmentGroups = Array.from(
        new Map(
            feedbackIntegrationState.segmentConfig.map(segment => 
                [segment.segmentGroup.displayName, segment.segmentGroup]
            )
        ).values()
    );

    let segmentGroupsInput: SegmentGroupInput[] = uniqueSegmentGroups.map(segmentGroup => {
        let configs: SegmentConfigInput[] = [];
        for (const config of feedbackIntegrationState.segmentConfig) {
            if (config.segmentGroup.displayName === segmentGroup.displayName) {
                let newConfig: SegmentConfigInput = {
                    segmentGroupId: config.segmentGroup.id,
                    fieldPath: config.path,
                    valueOverride: config.override,
                };
                if (config.id && config.id > 0) {
                    newConfig.segmentConfigId = config.id;
                }
                configs.push(newConfig);
            }
        }
        let filters: IntegrationFilterInput[] = [];
        for (const filter of feedbackIntegrationState.filters) {
            if (filter.segmentGroup.displayName === segmentGroup.displayName) {
            let newFilter: IntegrationFilterInput = {
                comparison: filter.comparison,
                value: filter.value,
                }
                if (filter.id && filter.id > 0) {
                    newFilter.filterId = filter.id;
                }
                if (filter.segmentGroup.id) {
                    newFilter.segmentGroupId = filter.segmentGroup.id;
                }
                filters.push(newFilter);
            }
        }
        let segmentGroupInput: SegmentGroupInput = {
            segmentConfigs: configs,
            filters: filters,
            name: segmentGroup.displayName,
        }
        if (segmentGroup.id && segmentGroup.id > 0) {
            segmentGroupInput.segmentGroupId = segmentGroup.id;
        }
        return segmentGroupInput;
    });

    
    let feedbackIntegrationInput: MutateFeedbackIntegration = {
        integrationTypeId: feedbackIntegrationState.type.id,
        teamId: teamId,
        requirements: feedbackIntegrationState.requirementValues.filter(requirement => requirement.value.trim()).map(requirement => ({
            id: requirement.requirement.id,
            value: requirement.value,
            valueId: requirement.valueId,
        })),
        segmentGroups: segmentGroupsInput,
        integrationName: feedbackIntegrationState.integrationName
    }
    if (feedbackIntegrationState.id ) {
        feedbackIntegrationInput.feedbackIntegrationId = feedbackIntegrationState.id;
    }
    return feedbackIntegrationInput;
}

export const buildFeedbackIntegrationMutationForValidation = (feedbackIntegrationState: FeedbackIntegrationState, teamId: number): MutateFeedbackIntegration => {
    // Only difference for validation is that we preserve the ids.
    // map segment groups => configs and filters
    // Get unique segment groups by displayName
    const uniqueSegmentGroups = Array.from(
        new Map(
            feedbackIntegrationState.segmentConfig.map(segment => 
                [segment.segmentGroup.displayName, segment.segmentGroup]
            )
        ).values()
    );
    let segmentGroupsInput: SegmentGroupInput[] = uniqueSegmentGroups.map(segmentGroup => {
        let configs: SegmentConfigInput[] = [];
        for (const config of feedbackIntegrationState.segmentConfig) {
            if (config.segmentGroup.displayName === segmentGroup.displayName) {
                let newConfig: SegmentConfigInput = {
                    segmentGroupId: config.segmentGroup.id,
                    fieldPath: config.path,
                    valueOverride: config.override,
                };
                newConfig.segmentConfigId = config.id;
                configs.push(newConfig);
            }
        }
        let filters: IntegrationFilterInput[] = [];
        for (const filter of feedbackIntegrationState.filters) {
            if (filter.segmentGroup.displayName === segmentGroup.displayName) {
                let newFilter: IntegrationFilterInput = {
                    comparison: filter.comparison,
                    value: filter.value,
                }
                if (filter.id && filter.id > 0) {
                    newFilter.filterId = filter.id;
                }
                newFilter.segmentGroupId = filter.segmentGroup.id;
                filters.push(newFilter);
            }
        }
        let segmentGroupInput: SegmentGroupInput = {
            segmentConfigs: configs,
            filters: filters,
            name: segmentGroup.displayName,
        }
        segmentGroupInput.segmentGroupId = segmentGroup.id;
        return segmentGroupInput;
    });

    
    let feedbackIntegrationInput: MutateFeedbackIntegration = {
        integrationTypeId: feedbackIntegrationState.type.id,
        teamId: teamId,
        requirements: feedbackIntegrationState.requirementValues.filter(requirement => requirement.value.trim()).map(requirement => ({
            id: requirement.requirement.id,
            value: requirement.value,
            valueId: requirement.valueId,
        })),
        segmentGroups: segmentGroupsInput,
        integrationName: feedbackIntegrationState.integrationName
    }
    if (feedbackIntegrationState.id ) {
        feedbackIntegrationInput.feedbackIntegrationId = feedbackIntegrationState.id;
    }
    return feedbackIntegrationInput;
}