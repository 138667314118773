import { useContext, useState } from "react";
import {FilterValueSelectionModal } from "./integrationFilters/FilterValueSelectionModal";
import { FilterFieldSelectionModal } from "./integrationFilters/FilterFieldSelectionModal";
import { FeedbackIntegrationActionType } from "../../actions/feedbackIntegration";
import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../context/FeedbackIntegrationContext";
import { ComparisonType, FeedbackIntegrationFragment } from "../../../generated/graphql";
import LoadingSpinner from "../../baseComponents/LoadingSpinner";
import { toast } from "react-hot-toast";

interface IntegrationFiltersModalProps {
    onClose: () => void;
    editingFilterIndex: number | null;
}

export const IntegrationFiltersModal = (props: IntegrationFiltersModalProps) => {
    const data = useContext(FeedbackIntegrationContext);
    const dispatch = useContext(FeedbackIntegrationDispatchContext);
    
    // Initialize state from existing filter if editing
    const existingFilter = props.editingFilterIndex !== null 
        ? data.feedbackIntegration!.filters[props.editingFilterIndex]
        : null;

    const [selectedSegmentGroup, setSelectedSegmentGroup] = useState<FeedbackIntegrationFragment["segmentConfig"][number]["segmentGroup"] | null>(
        existingFilter ? existingFilter.segmentGroup : null
    );
    const [comparisonValue, setComparisonValue] = useState(existingFilter ? existingFilter.value : '');
    
    // Update the dispatch action in your save/update logic
    const handleUpdateFilter = (comparisonOperator: ComparisonType, comparisonValue: string, segmentGroup: FeedbackIntegrationFragment["segmentConfig"][number]["segmentGroup"]) => {
        const newFilter: FeedbackIntegrationFragment["filters"][number] = {
            comparison: comparisonOperator,
            id: -1,
            segmentGroup: segmentGroup,
            value: comparisonValue
        };
        if (props.editingFilterIndex !== null) {
            newFilter.id = data.feedbackIntegration.filters[props.editingFilterIndex].id ?? -1;
            dispatch({
                type: FeedbackIntegrationActionType.UpdateExistingFilter,
                payload: {
                    filterIndex: props.editingFilterIndex,
                    filter: newFilter,
                }
            });
        } else {
            dispatch({
                type: FeedbackIntegrationActionType.AddFilter,
                payload: {
                    filter: newFilter,
                }
            });
        }
        props.onClose();
    };

    return (
        <>
            <div 
                className="fixed inset-0 z-[100]"
                onClick={props.onClose}
            />
            <div className="relative" id="integration-filters-modal">
                <div className="absolute right-0 bg-white border border-gray-200 shadow-md rounded-xl overflow-visible z-[101]">
                    {data.sourceFieldsLoading ? (
                        <div className="p-4 text-center">
                            <LoadingSpinner/> 
                            <div className="mt-2">Loading available fields...</div>
                        </div>
                    ) : (
                        <div className="flex border-2 bg-white border-gray-200 rounded-lg h-auto min-h-[250px] md:min-h-[400px] max-h-[calc(100vh-200px)] overflow-auto">
                            {!data.dataSourceFields?.length ?? 
                            toast.error("Unable to load sample data. You can still configure custom fields.")}
                            <div className="md:hidden w-80 relative flex flex-col">
                                {!selectedSegmentGroup ? (
                                    <FilterFieldSelectionModal onSelect={(field) => setSelectedSegmentGroup(field)} id="filter-field-selection-small" />
                                ) : (
                                    <div className="w-80 bg-silver rounded-lg relative flex flex-col overflow-hidden">
                                        <div className="p-4 border-b border-gray-200 flex items-center">
                                            <button 
                                                onClick={() => setSelectedSegmentGroup(null)}
                                                className="text-blueberry hover:text-raspberry flex items-center gap-2"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                                </svg>
                                                Back
                                            </button>
                                        </div>
                                        <FilterValueSelectionModal 
                                            handleUpdateFilter={handleUpdateFilter}
                                            segmentGroup={selectedSegmentGroup} 
                                            onClose={props.editingFilterIndex !== null 
                                                ? props.onClose 
                                                : () => setSelectedSegmentGroup(null)} 
                                            comparisonValue={comparisonValue}
                                            setComparisonValue={setComparisonValue}
                                            initialComparisonOperator={existingFilter?.comparison}
                                            id="filter-value-selection-small"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="hidden md:flex">
                                <FilterFieldSelectionModal onSelect={(field) => setSelectedSegmentGroup(field)} id="filter-field-selection-large" />
                                {selectedSegmentGroup && (
                                    <FilterValueSelectionModal 
                                        handleUpdateFilter={handleUpdateFilter}
                                        segmentGroup={selectedSegmentGroup} 
                                        onClose={props.editingFilterIndex !== null 
                                            ? props.onClose 
                                            : () => setSelectedSegmentGroup(null)} 
                                        comparisonValue={comparisonValue}
                                        setComparisonValue={setComparisonValue}
                                        initialComparisonOperator={existingFilter?.comparison}
                                        id="filter-value-selection-large"
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}