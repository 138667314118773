import {
  Cog6ToothIcon,
  XMarkIcon,
  TrashIcon,
  BookmarkIcon,
  ArrowDownTrayIcon,
  LinkIcon, WrenchIcon,
  PaperClipIcon,
  BellAlertIcon,
  RocketLaunchIcon
} from '@heroicons/react/24/outline';
import { Icon } from '@iconify/react';
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/24/solid';
import { IBadge } from '../../baseComponents/Badge';
import { GroupBase, writeToastMessage } from '../../v2/hooks/GroupHook';
import { classNames, getFiltersStatsMode } from '../../v2/util';
import SettingsMenu, { ISettingsItem, MenuAlign } from '../baseComponents/SettingsMenu';
import Badge from '../../baseComponents/Badge';
import Tippy from '@tippyjs/react';
import InlineEditableTextArea from '../../baseComponents/InlineEditableTextArea';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import 'tippy.js/themes/light.css';
import { GroupUIType } from '../pages/ExplorePage';
import { useContext, useState } from 'react';
import { FilterInput, FilterType } from '../../generated/graphql';
import UserContext from '../../v2/contexts/UserContext';
import { GroupSubscriptionPopover } from './Popovers/GroupSubscriptionPopover';
import AppContext from '../../v2/contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { exportGroupWithProgressUpdate } from '../../eventHandlers/exports/groupExport';
import { ProgressState, Status } from '../../exports/ProgressMonitor';
import { ProgressBar } from './ProgressBar';
import { AppRoutes } from '../../Routes';
import { FilterContext } from '../../context/filterStatementContext';
export default function GroupHeader({
  group,
  type,
  margin,
  padding,
  rounded,
  badge,
  isCondensedView,
  showTags,
  percentChange,
  editable = true,
  openAnnouncementModal,
  onClose,
  discardSearchGroup,
  togglePinGroup,
  copyLink,
  editTitle,
  filterInput,
  openInsightModal,
  openTaxonomyModal,
}: {
  group: GroupBase | undefined;
  isCondensedView: boolean;
  showTags: boolean;
  type: GroupUIType;
  onClose?: () => void;
  discardSearchGroup: (groupId: string) => void;
  togglePinGroup: (groupId: string, cb?: () => void) => void;
  margin: any;
  padding: any;
  rounded?: boolean;
  badge?: IBadge;
  percentChange?: number;
  editTitle?: (groupId: string, title: string) => void;
  copyLink: (groupId: string) => string | void;
  editable?: boolean;
  openAnnouncementModal?: () => void;
  filterInput?: FilterInput;
  openInsightModal?: () => void;
  openTaxonomyModal?: () => void;
}) {
  const { curOrgId, curTeamId } = useContext(AppContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [exportProgress, updateExportProgress] = useState<ProgressState>({ status: Status.idle, percent: 0 });
  const filterState = useContext(FilterContext);

  const settings: ISettingsItem[] = [
    {
      name: group?.pinnedByUser ? 'Unpin Group' : 'Pin Group',
      id: 0,
      group: 'pin',
      htmlId: 'pin-group',
      disabled: true,
      icon: <BookmarkIcon className="h-5 w-5" />,
      onClick: () => {
        if (!group) return;
        togglePinGroup(group.id);
      },
    },
    {
      name: 'Export to CSV',
      id: 1,
      group: 'actions',
      htmlId: 'export-group-csv',
      icon: <ArrowDownTrayIcon className="h-5 w-5" />,
      onClick: () => {
        if (group && curTeamId && group.title) {
          exportGroupWithProgressUpdate(
            { groupTitle: group.title!, teamId: curTeamId, groupIds: [group!.id], filterConsumable: filterState.filterConsumable },
            exportProgress,
            updateExportProgress
          );
        } else toast.error('Could not export data: Group is invalid or not defined.');
      },
    },
    {
      name: 'Link to Group',
      id: 2,
      group: 'actions',
      htmlId: 'link-group',
      icon: <PaperClipIcon className="h-6 w-6" />,
      onClick: () => {
        if (!group) return;
        copyLink && copyLink(group.id);
      },
    },
    {
      name: 'Delete Group',
      textColor: 'failure',
      id: 3,
      group: 'delete',
      htmlId: 'delete-group',
      disabled: true,
      icon: <TrashIcon className="h-6 w-6" />,
      onClick: () => {
        if (!group) return;
        discardSearchGroup(group.id);
      },
    },
  ];

  const params = new URLSearchParams(window.location.search);
  const setFilterToGroupAndRedirect = () => {
    const filters = JSON.parse(decodeURIComponent(params.get('group') ?? '{}'));
    filters.groupFilter = [{ filterCondition: FilterType.And, group: [{ id: group!.id }] }];
    const stringifiedFilters = JSON.stringify(filters);
    params.set('group', stringifiedFilters);
    params.set('teamId', curTeamId!.toString());
    params.set('orgId', curOrgId!.toString());
    navigate(`${AppRoutes.v3FullPath.replying}?${params.toString()}`, { replace: false });
  };

  const adminSettings: ISettingsItem[] = [
    {
      name: group!.pinnedByUser ? 'Unpin Group' : 'Pin Group',
      id: 0,
      group: 'pin',
      htmlId: 'pin-group',
      icon: <BookmarkIcon className="h-6 w-6" />,
      onClick: () => {
        togglePinGroup(group!.id);
      },
    },
  ];
  const settingsWhileProcessing = settings.filter((item) => item.group === 'delete');
  const showHeaderIcon = group?.isNew !== undefined && isCondensedView;
  const [pinLoading, setPinLoading] = useState(false);
  const [popoverKey, setPopoverKey] = useState<number>(0);
  const handlePinGroup = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setPinLoading(true);
    if (!group) return;
    togglePinGroup(group.id, () => setPinLoading(false));
  };
  const handleCopyLink = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, groupId: string) => {
    e.stopPropagation();
    copyLink(groupId);
  };

  const filteredStatsMode = getFiltersStatsMode(filterInput);

  return (
    <div
      className={`col-span-12 grid grid-cols-8 bg-blueberry ${rounded ? 'rounded-t-3xl' : null} mx-${margin.x} mt-${margin.t} pb-${padding.b} mb-${
        margin.b
      } px-${padding.x - 3} md:px-${padding.x} pt-${padding.t}`}
    >
      <div className={classNames(`${isCondensedView ? 'col-span-8' : 'col-span-12 md:col-span-5'}`, 'flex flex-col gap-y-2 font-normal text-milk')}>
        {!group ? (
          <div className="mt-3">
            <AdjustableLoadingIcon width={4} height={4} color={'white'} />
          </div>
        ) : type === GroupUIType.PreviewPage ? (
          <h1 className="text-lg font-semibold">{group?.title}</h1>
        ) : (
          <Tippy
            disabled={editable}
            placement="left"
            theme="dark"
            delay={200}
            content={
              <p className="text-center font-sofiapro">
                {group?.title}
                {group?.isExactMatch ? ` - this group is a collection of feedback that exactly matches the word ${group.centroid}` : ''}
              </p>
            }
          >
            <div id="group-title">
              <InlineEditableTextArea
                value={group?.title!}
                editable={editable && !isCondensedView}
                onEditComplete={(value) => {
                  if (!group) return;
                  editTitle && editTitle(group.id, value);
                }}
                additionalClassNames={
                  '-mx-2 -my-4 hover:bg-blueberry-lighter focus:bg-blueberry-lighter bg-transparent transition-[background-color] duration-100 rounded-md font-bold'
                }
              />
            </div>
          </Tippy>
        )}
        {!group ? null : !group?.processing ? (
          <div className="mb-2 flex flex-row flex-wrap items-baseline gap-x-1 text-xs font-normal">
            <p id="header-mentions" className="text-md">
              {group?.totalEntries.toLocaleString('en-US')} mentions
            </p>
            <Tippy
              theme="dark"
              delay={200}
              content={
                <p className="text-center">
                  {filteredStatsMode ? `${(group.relativeShare ?? 0).toFixed(1)}% of filtered feedback, ` : ''}
                  {(group.relativeShareFull ?? 0).toFixed(1)}% of all feedback.
                </p>
              }
            >
              <p className="">
                ({((filteredStatsMode ? group.relativeShare : group.relativeShareFull) ?? 0).toFixed(1)}% of {filteredStatsMode ? 'filtered' : ''} feedback)
              </p>
            </Tippy>
            <div className="flex md:hidden">{badge ? <Badge badge={badge} color={'bg-blueberry-light'} capitalize={false} /> : null}</div>
            {showHeaderIcon && percentChange !== undefined && (
              <div className="-py-1 -my-4 flex w-full flex-row justify-end">
                <Tippy
                  theme="dark"
                  delay={200}
                  content={
                    <p className="text-center">{`Unwrap is highlighting this group because it's ${
                      group.isNew ? 'new' : `had a recent and substantial ${percentChange > 0 ? 'increase' : 'decrease'} in mentions`
                    } `}</p>
                  }
                >
                  <span className="-my-1 inline-flex items-center rounded-md bg-silver px-2 py-1 font-medium text-blueberry">
                    <div className="flex flex-row ">{group.isNew ? 'New' : percentChange > 0 ? 'Growing' : 'Shrinking'}</div>
                  </span>
                </Tippy>
              </div>
            )}
            {showTags &&
              group?.tags
                ?.slice(0, 4)
                .map((tag) => <Badge badge={{ id: tag.id.toString(), text: tag.name }} color="bg-silver" textColor="text-blueberry" capitalize={true} />)}
          </div>
        ) : null}
      </div>
      {!isCondensedView && (
        <div className="relative col-span-3 col-end-9 mt-2 hidden flex-col text-milk md:flex justify-between">
          <div className="flex flex-row justify-end gap-x-2">
            {type !== GroupUIType.PreviewPage && !group?.processing ? (
              <Tippy theme="light" delay={200} content={<p className="text-center">{group?.pinnedByUser ? 'Unpin Group' : 'Pin Group'}</p>}>
                <div id="pin-group-icon" className="rounded-full p-2 transition hover:cursor-pointer hover:bg-slate-900/25 ">
                  {pinLoading ? (
                    <AdjustableLoadingIcon width={5} height={5} color="white" />
                  ) : group?.pinnedByUser ? (
                    <BookmarkIconSolid className="h-5 w-5 self-center" id="unpin-group" onClick={(e) => handlePinGroup(e)} />
                  ) : (
                    <BookmarkIcon className="h-5 w-5 self-center" id="pin-group" onClick={(e) => handlePinGroup(e)} />
                  )}
                </div>
              </Tippy>
            ) : (
              <div className="flex-[0_0_2.25rem]"></div>
            )}
            {user?.isUnwrapper && editable && type !== GroupUIType.PreviewPage ? (
              <div
                className="rounded-full p-2 transition duration-200 hover:cursor-pointer hover:bg-slate-900/25 "
                onClick={(e) => {
                  e.stopPropagation();
                  if (!user?.isUnwrapper) return;
                  openInsightModal?.();
                }}
              >
                <WrenchIcon className="h-5 w-5 text-milk" />
              </div>
            ) : null}
            {type !== GroupUIType.PreviewPage ? (
              <div
                id={type === GroupUIType.Modal ? `group-subscriptions-modal` : 'group-subscriptions'}
                className="cursor-auto"
                onClick={(e) => e.stopPropagation()}
              >
                <GroupSubscriptionPopover
                  groupId={group?.id ?? '-1'}
                  key={popoverKey}
                  updateKey={setPopoverKey}
                  customIconElem={
                    <Tippy content="Configure Subscriptions" theme="light" delay={200}>
                      <div className="rounded-full p-2 transition hover:cursor-pointer hover:bg-slate-900/25">
                        <div>
                          <BellAlertIcon className="h-5 w-5 stroke-2" />
                        </div>
                      </div>
                    </Tippy>
                  }
                />
              </div>
            ) : null}

            {type === GroupUIType.Modal ? (
              <Tippy theme="light" disabled={true} delay={200} content={<p className="text-center">Edit Taxonomy</p>}>
                <div className="rounded-full p-2 transition hover:cursor-pointer hover:bg-slate-900/25">
                  <div>
                    <Icon icon="mdi:file-tree" color={'#ffffff'} width="22" height="22" className="cursor-pointer" onClick={() => openTaxonomyModal?.()} />
                  </div>
                </div>
              </Tippy>
            ) : null}
            {type !== GroupUIType.PreviewPage ? (
              <Tippy theme="light" delay={200} disabled={true} content={<p className="text-center">Reply to users</p>}>
                <div className="rounded-full p-2 transition hover:cursor-pointer hover:bg-slate-900/25 " onClick={(e) => e.stopPropagation()}>
                  <div>
                    <RocketLaunchIcon id="link-group" className="h-5 w-5 focus:outline-none " onClick={(e) => setFilterToGroupAndRedirect()} />
                  </div>
                </div>
              </Tippy>
            ) : null}
            <Tippy theme="light" delay={200} content={<p className="text-center">Copy Group Link</p>}>
              <div className="rounded-full p-2 transition hover:cursor-pointer hover:bg-slate-900/25 " onClick={(e) => e.stopPropagation()}>
                <div>
                  <LinkIcon
                    id="link-group"
                    className="h-5 w-5 focus:outline-none "
                    onClick={(e) => {
                      if (!group) return;
                      handleCopyLink(e, group.id);
                      writeToastMessage('Copied group link to clipboard');
                    }}
                  />
                </div>
              </div>
            </Tippy>
            <div
              id="group-card-settings-menu"
              className="rounded-full p-2 transition duration-200 hover:cursor-pointer hover:bg-slate-900/25 "
              onClick={(e) => e.stopPropagation()}
            >
              <SettingsMenu settings={group?.processing ? settingsWhileProcessing : settings} center={true} align={MenuAlign.RIGHT}>
                <Cog6ToothIcon className="h-5 w-5 text-milk md:h-5 md:w-5" />
              </SettingsMenu>
            </div>
            {onClose ? (
              <div
                className="rounded-full p-2 transition duration-200 hover:cursor-pointer hover:bg-slate-900/25 "
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                <XMarkIcon className="h-5 w-5 text-milk md:h-5 md:w-5" />
              </div>
            ) : null}
          </div>
          <div className="hidden md:flex mb-2 px-2 flex-row justify-end align-bottom">{badge ? <p className="text-xs font-normal">{badge.text}</p> : null}</div>
          <div className="flex flex-row justify-end">
            <div style={{ width: '35%' }}>
              <ProgressBar exportProgress={exportProgress} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
