import { Context, Dispatch, createContext } from 'react';
import { GroupAction, GroupDispatch, GroupState } from '../reducers/group/GroupDispatch';

export const defaultGroupState: GroupState = {
  groupData: null,
  totalEntries: null,
  filteredEntries: null,
  auxillaryGroupDataErrorMessage: null,
  auxillaryGroupDataLoading: false,
};

export const GroupDataContext = createContext<GroupState>(defaultGroupState);

const defaultProvider: Dispatch<GroupAction> = ({}) => {
  return defaultGroupState;
};

export const GroupDispatchContext: Context<Dispatch<GroupAction>> = createContext<GroupDispatch>(defaultProvider);
