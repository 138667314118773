import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { exportGroupWithProgressUpdate } from '../../eventHandlers/exports/groupExport';
import { ProgressState, Status } from '../../exports/ProgressMonitor';
import { GroupState } from '../../reducers/group/GroupDispatch';
import { ISettingsItem } from './SettingsMenu';

interface ExportSettingsItemProps {
  groupState: GroupState;
  id: number;
  curTeamId: number;
  filterConsumable: string;
  exportProgress: ProgressState;
  updateExportProgress: React.Dispatch<React.SetStateAction<ProgressState>>;
}

function isExporting(exportProgress: ProgressState): boolean {
  return exportProgress.status === Status.initializing || exportProgress.status === Status.processing;
}

/**
 * A settings item that exports to CSV. Can be reused in different settings menus.
 * @returns
 */
export const createExportSettingsItem = ({ groupState, id, curTeamId, filterConsumable, exportProgress, updateExportProgress }: ExportSettingsItemProps): ISettingsItem => {
  const exportText = getExportText(exportProgress);

  if (!groupState.groupData) {
    throw new Error('Group data is not loaded');
  }

  return {
    id,
    name: exportText,
    group: 'actions',
    htmlId: 'export-group-csv',
    keepMenuOpen: true,
    disabled: isExporting(exportProgress),
    icon:
      exportProgress.status === Status.initializing || exportProgress.status === Status.processing ? (
        <div className="h-5 w-5  animate-spin rounded-full border-t-2 border-b-2 border-blueberry" />
      ) : (
        <ArrowDownTrayIcon className="h-5 w-5" />
      ),
    onClick: () => {
      exportGroupWithProgressUpdate(
        {
          filterConsumable,
          groupTitle: groupState.groupData!.title ?? '',
          teamId: curTeamId,
          groupIds: [groupState.groupData!.id],
        },
        exportProgress,
        updateExportProgress
      );
    },
  };
};

const getExportText = (exportProgress: ProgressState): string => {
  switch (exportProgress.status) {
    case Status.initializing:
      return 'Exporting...0%';
    case Status.processing:
      return `Exporting... ${exportProgress.percent}%`;
    default:
      return 'Export to CSV';
  }
};
