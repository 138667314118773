import { IntegrationTypeCard } from '../components/integrations/IntegrationTypeCard';
import { AddNewIntegrationCard } from '../components/integrations/AddNewIntegrationCard';
import { useIntegrationsQuery, Feedback_Integration_Type } from '../../generated/graphql';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { useState, useEffect } from 'react';
import { SearchInput } from '../baseComponents/SearchInput';
import { useLocation } from 'react-router-dom';
import InformationModal from '../components/Modals/InformationModal';

export interface IIntegrationRedirect {
  authToken: string;
  scraperKey: string;
  teamId: number;
  url_zendesk?: string;
  additionalObjects: { [key: string]: string };
}

interface IntegrationsPageProps {
  pageName: string;
}

/**
 * This is pulled from the old integrations page.
 * It's used for external tickets integrations.
 */
export enum IntegrationModalState {
  NoModal = 'no-modal',
  Success = 'success',
  Warning = 'warning',
  Card = 'card',
}

export default function IntegrationsPage (props: IntegrationsPageProps) {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const navigationState = useLocation().state;
  const [displayModalState, setDisplayModalState] = useState(
    navigationState?.displayModal ?? false
  );

  const { data, loading, error } = useIntegrationsQuery({
    variables: { teamId: teamId, orgId, feedbackIntegrationType: Feedback_Integration_Type.DataImport },
  });
  const [searchValue, setSearchValue] = useState('');
  return (
    loading ? <LoadingSpinner /> : (
    <div id="page-wrapper" className="flex flex-col gap-y-2 bg-white undefined mx-8">
      <InformationModal
        modalOpen={displayModalState}
        callbackModal={() => {
          setDisplayModalState(false);
        }}
        text={'Integration connected successfully!'}
        subtext={'Your integration was created successfully! Please check your dashboard for insights in ~60 minutes.'}
      />
      <div className="flex flex-col gap-y-8 bg-white"></div>
          <div className="flex flex-row justify-between items-start">
              <h1 className="text-4xl flex flex-col font-semibold text-blueberry">Integrations</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-[3fr,minmax(440px,2fr)] gap-6">
            <div className="flex flex-col gap-6">
            <div className="relative w-full max-w-md">
              <SearchInput 
                placeholder="Search for integrations..."
                onChange={(e) => setSearchValue(e.target.value)} 
                onSearch={(text) => setSearchValue(text || '')} 
              />
            </div>
                <div className="flex flex-col gap-y-6">
                  {data?.integrations?.map((integration, index) => (
                    (integration.teamIntegration.length > 0) && 
                    (integration.requirements.length > 0) && (
                      <IntegrationTypeCard 
                        //@ts-ignore
                        integrationType={integration} 
                        key={index} 
                        filterString={searchValue} 
                      />
                    )
                  ))}
                </div> 
            </div>
            <div className="lg:block">
              {/* @ts-ignore */}
              <AddNewIntegrationCard integrations={data?.integrations ?? []} />
            </div>
          </div>
      </div>)
  );
};


export function paramsToObject(entries: IterableIterator<[string, string]>) {
  const result: Record<string, string> = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}