import { useEffect, useState } from 'react';
import { ArrayElement } from '../../../utilities';
import { Breakdown, Chart, ChartFragment, Chart_Type, GetCustomChartsQuery, SeriesData, Y_Axis_Data } from '../../../generated/graphql';
import ChartView from '../../baseComponents/ChartView';
import { YAxisUnit } from '../../../v2/hooks/ChartHook';
import { ChartOptions, CoreScaleOptions, Scale, Tick } from 'chart.js';
import { getTooltipCallbacks } from './chartLabelStrategies';
import { truncateAndEllipsis } from '../../../v2/util';

const isHorizontalChart = (chartType: Chart_Type) => {
  return chartType === Chart_Type.HorizontalBar;
};
const isPercentageChart = (yAxisData: Y_Axis_Data) => yAxisData === Y_Axis_Data.Favorability || yAxisData === Y_Axis_Data.RelativeShare;
export const chartColors = [
  '#E06D87',
  '#414880',
  '#FEC6A3',
  '#C29ADD',
  '#F49EC4',
  '#D2D3FF',
  '#887AB9',
  '#C0BCD0',
  '#FCE7B4',
  '#F8A691',
  '#34495E',
  '#F5B7B1',
  '#7D3C98',
  '#F8C471',
  '#F0B27A',
  '#1ABC9C',
  '#E67E22',
  '#5D6D7E',
  '#AF7AC5',
];

export const sentimentColorMap: Record<string, string> = {
  Negative: '#E06D87',
  Neutral: '#F5DB9C',
  Positive: '#4BACB2',
};

export const npsBucketColorMap: Record<string, string> = {
  detractor: '#E06D87',
  passive: '#F5DB9C',
  promoter: '#4BACB2',
};

const isCategoricalChart = (chartType: Chart_Type) => chartType === Chart_Type.Pie || chartType === Chart_Type.HorizontalBar;
const isStackedChart = (chartType: Chart_Type) => chartType === Chart_Type.StackedBar || chartType === Chart_Type.StackedArea;

const generateDataset = (
  data: SeriesData,
  type: 'normalized' | 'aggregate',
  overallChartData: ArrayElement<GetCustomChartsQuery['getCharts']>,
  index: number,
  fill?: boolean,
  differentColorPerBucket?: boolean
) => {
  const dataToMap = type === 'normalized' ? data.normalizedData : data.aggregateData;
  return dataToMap?.slice(0, 10).map((curData, idx) => {
    let labelName: string | undefined = data.breakdownLabels?.[idx]?.name;
    const npsNames = ['detractor', 'passive', 'promoter'];
    const isNpsBucket =
      data.breakdownLabels?.length === 3 &&
      data.breakdownLabels
        ?.map((obj) => obj.name)
        .sort()
        .every((name, idx) => name.toLowerCase() === npsNames[idx]);
    let color: string = chartColors[idx + index];
    if (labelName) {
      if (data.breakdown === 'sentiment') color = sentimentColorMap[labelName];
      else if (data.breakdown === 'segment' && isNpsBucket) color = npsBucketColorMap[labelName.toLowerCase()];
    }
    let colorBucket;
    if (differentColorPerBucket) {
      if (data.breakdown === Breakdown.Sentiment) {
        colorBucket = data.chartLabels.map((label) => sentimentColorMap[label]) || [];
      } else if (data.breakdown === Breakdown.Segment && isNpsBucket) {
        colorBucket = data.chartLabels.map((label) => npsBucketColorMap[label.toLowerCase()]) || [];
      } else if (differentColorPerBucket) {
        colorBucket = chartColors;
      } else {
        colorBucket = [color];
      }
    }

    return {
      label: labelName ?? '',
      data: curData,
      backgroundColor: differentColorPerBucket ? colorBucket : color,
      borderColor: differentColorPerBucket ? colorBucket : color,
      fill,
    };
  });
};

export const CustomChart = ({ customChartData, showLegend = true }: { customChartData: ChartFragment; showLegend?: boolean }) => {
  const [chartData, setChartData] = useState<any>();
  const [isChartEmpty, setIsChartEmpty] = useState<boolean>(false);
  /*
  We're using a randKey to force a re-render of the chart when the data changes.
  This is a workaround because Charts end up in broken states if we just pass updated data. Needs to be investigated further.
  */
  const [randKey, setRandKey] = useState<number>(Math.random());

  useEffect(() => {
    if (customChartData.seriesData && customChartData.seriesData.length > 0) {
      let labels: string[] = customChartData.seriesData[0].chartLabels; //Labels should be the same for all. Maybe we can add a check?
      let datasets: any[] = [];
      customChartData.seriesData?.map((data, i) => {
        //Absolute Chart -> Aggregate Data ||| Percentage Chart -> Normalized Data
        if (isPercentageChart(customChartData.y_axis_data!))
          datasets.push(
            ...generateDataset(
              data as SeriesData,
              'normalized',
              customChartData as Chart,
              i,
              customChartData.type === Chart_Type.StackedArea,
              customChartData.type === Chart_Type.Pie
            )
          );
        else
          datasets.push(
            ...generateDataset(
              data as SeriesData,
              'aggregate',
              customChartData as Chart,
              i,
              customChartData.type === Chart_Type.StackedArea,
              customChartData.type === Chart_Type.Pie
            )
          );
      });
      const newChartData = { labels, datasets };
      const areAllChartValuesZero = customChartData.seriesData?.every((serData) => serData.aggregateData.every((aggData) => aggData.every((val) => val === 0)));
      setIsChartEmpty(areAllChartValuesZero);
      setChartData(newChartData);
      if (JSON.stringify(newChartData) !== JSON.stringify(chartData)) {
        setRandKey(Math.random());
      }
    }
  }, [customChartData]);

  return (
    <div className="h-full">
      {isChartEmpty ? (
        <div className="flex flex-col items-center justify-center h-full px-2">
          <div className="text-center text-gray-500">No data matches the selected configurations and filters</div>
        </div>
      ) : chartData ? (
        <ChartView
          key={randKey}
          type={customChartData.type}
          data={chartData}
          options={getChartOptions(
            customChartData.y_axis_data === Y_Axis_Data.RelativeShare
              ? YAxisUnit.CustomPercentage
              : customChartData.y_axis_data === Y_Axis_Data.Favorability
              ? YAxisUnit.Favorability
              : YAxisUnit.CustomAbsolute,
            customChartData.type,
            customChartData.seriesData?.[0]?.tooltipLabels ?? [],
            customChartData.seriesData?.[0]?.chartLabels ?? [],
            false,
            showLegend
          )}
          hideLegends={!showLegend}
        />
      ) : null}
    </div>
  );
};

const getChartOptions = (
  yAxisUnit: YAxisUnit,
  chart_type: Chart_Type,
  fullLabels: string[],
  chartLabels: string[],
  disableAnnotations?: boolean,
  showLegend: boolean = true
): ChartOptions => {
  return {
    animations: {
      numbers: { duration: 500 },
    },
    layout: {
      padding: {
        top: chart_type === Chart_Type.Pie ? 10 : 0,
        bottom: 0,
      },
    },
    indexAxis: chart_type === Chart_Type.HorizontalBar ? 'y' : 'x',

    //@ts-ignore
    lineTension: 0.3,
    interaction: {
      mode: 'nearest',
      //This prevents incorrect tooltip positioning for horizontal bar charts
      axis: chart_type === Chart_Type.HorizontalBar ? 'y' : 'xy',
      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      annotation: {
        interaction: {
          intersect: false,
        },
      },
      tooltip: {
        // control tooltip visibility if all values are zero
        filter: function (tooltipItem) {
          const dataset = tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
          const data = dataset.data[tooltipItem.dataIndex];
          if (!data) {
            return false;
          }
          // If all values are zero, return false to hide tooltip.
          for (let i = 0; i < Number(data.valueOf()); i++) {
            if (data !== 0) {
              return true;
            }
          }
          return false;
        },
        backgroundColor: '#FFF',
        bodyColor: '#292E5B',
        titleColor: '#292E5B',
        footerColor: '#292E5B',
        mode: 'index',
        position: 'custom',
        intersect: false,
        enabled: true,
        titleFont: {
          family: 'SofiaPro',
          size: 10,
        },

        titleMarginBottom: 1,
        bodyFont: {
          family: 'SofiaPro',
          size: 12,
        },
        padding: 10,
        footerFont: {
          size: 10,
          family: 'SofiaPro',
          style: 'italic',
          weight: 'normal',
        },
        borderWidth: 1,
        borderColor: '#292E5B',
        footerMarginTop: 1,
        // sort visible tooltip items in descending order
        itemSort(a, b, data) {
          return Number(b.formattedValue) - Number(a.formattedValue);
        },
        callbacks: getTooltipCallbacks(chart_type, yAxisUnit, fullLabels),
      },
    },
    elements: {
      point: {
        radius: 3,
      },
      bar: {
        borderRadius: 4,
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: chart_type === Chart_Type.StackedBar,
        display: chart_type === Chart_Type.Pie ? false : true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 7,
          display: true,
          maxRotation: 0,
          color: '#6B7280',
          font: {
            size: 12,
            family: 'SofiaPro',
          },
          callback: function (value: string | number, index: number, values: Tick[]) {
            // we do this because the 'x' axis for horizontal bar charts is actually the 'y' axis in the data
            // so we need to display the value from the data array instead of the labels array
            // for other chart types we just pass the chart labels
            if (isHorizontalChart(chart_type)) {
              if (yAxisUnit === YAxisUnit.CustomPercentage || yAxisUnit === YAxisUnit.Favorability) {
                return `${value}%`;
              } else {
                return value.toLocaleString();
              }
            } else {
              return chartLabels[index];
            }
          },
        },
      },

      ...(chart_type === Chart_Type.HorizontalBar && {
        y: {
          display: true,

          grid: {
            display: false,
          },

          ticks: {
            display: true,
            color: '#6B7280',
            font: {
              size: 12,
              family: 'SofiaPro',
            },
            callback: function (value: string | number, index: number, values: Tick[]) {
              const label = this.getLabelForValue(Number(value));
              return truncateAndEllipsis(label, 40);
            },
            // Add these options to force all labels to show
            autoSkip: false,
          },
        },
      }),
      ...(yAxisUnit === YAxisUnit.CustomAbsolute &&
        !isCategoricalChart(chart_type) && {
          mentions: {
            stacked: isStackedChart(chart_type),
            position: 'left',
            grid: {
              display: true,
              color: '#f1f1f1',
              drawBorder: false,
            },
            ticks: {
              padding: 5,
              precision: 0,
              autoSkip: false,
              color: '#6B7280',
              font: {
                size: 12,
                family: 'SofiaPro',
              },
            },
            beginAtZero: true,
          },
        }),
      ...((yAxisUnit === YAxisUnit.CustomPercentage || yAxisUnit === YAxisUnit.Favorability) &&
        !isCategoricalChart(chart_type) && {
          percentage: {
            stacked: isStackedChart(chart_type),
            position: 'left',
            grid: {
              display: true,
              color: '#f1f1f1',
              drawBorder: false,
            },
            display: true,
            ticks: {
              padding: 15,
              autoSkip: false,
              color: '#6B7280',
              font: {
                size: 12,
                family: 'SofiaPro',
              },
              callback: function (value: string | number, index: number, values: Tick[]) {
                return value + '%';
              },
            },
            beginAtZero: true,
            afterDataLimits: (scale: Scale<CoreScaleOptions>) => {
              const datasets = scale.chart.data.datasets;

              //If there's a breakdown, get the max but with each point's values added together
              if (datasets && datasets.length >= 1 && isStackedChart(chart_type)) {
                const dataValues = datasets[0].data.map((_: any, index: number) =>
                  datasets.reduce((acc: number, cur: any) => {
                    return acc + cur.data[index];
                  }, 0)
                );
                const maxDataPoint = +Math.max(...dataValues).toFixed(1);

                scale.max = Math.max(maxDataPoint, 100);
                scale.min = 0;
              } else {
                //If no breakdown, get the max of all datasets
                const dataValues = datasets.flatMap((dataset) => dataset.data) as number[];
                const maxDataPoint = Math.max(...dataValues);
                const minDataPoint = Math.min(...dataValues);

                let padding;
                if (maxDataPoint < 10 && maxDataPoint - minDataPoint < 3) {
                  padding = 0.1;
                } else {
                  padding = 5;
                }

                scale.max = Math.min(maxDataPoint + padding, 100);
                scale.min = 0;
              }
            },
          },
        }),
    },
  };
};
