import { Popover, Transition } from '@headlessui/react';
import { ChartBarSquareIcon, DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ChartBarIcon, EllipsisVerticalIcon, LinkIcon, PencilIcon } from '@heroicons/react/24/solid';
import { Icon } from '@iconify/react';
import Tippy from '@tippyjs/react';
import { Fragment, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';
import {
  Action,
  Chart_Bin_Type,
  Chart_Type,
  DataSeriesConfigurationFragment,
  DataSeriesConfigurationOutput,
  FilterInput,
  GetCustomChartQuery,
  PlotFragment,
  Resource,
  useDataForFiltersLazyQuery,
  useGetPlotQuery,
} from '../../../generated/graphql';
import { AppRoutes } from '../../../Routes';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';
import { classNames, truncateAndEllipsis } from '../../../v2/util';
import SettingsMenu, { ISettingsItem } from '../../baseComponents/SettingsMenu';
import { FilterCategory, IBadgeFilter, IFilter } from '../../sections/Filters/FiltersTypes';
import { getBadgeText, setUiFilters } from '../../sections/Filters/FiltersUtil';
import Badge from '../Badge';
import { Plot } from './Plot';
import { FilterContext } from '../../../context/filterStatementContext';
import { FilterState } from '../../../reducers/filterStatement/filterStatementReducer';
import { FilterNode } from '../../lib/filterNode';
import { useChartDispatch } from '../../../context/chartContext';
import { ChartActionType } from '../../../reducers/charts/chartReducer';
import { FilterTree } from '../../lib/filterTree';
import { DisabledFilterDisplay } from '../filters/filterBar/DisabledFilterDisplay';
import { InitialFilterStateProvider } from '../../filters/utilities/InitialFilterStateProvider';

interface LoadableCustomChartCardProps {
  chartId: number;
  chartTitle: string;
  deleteChart?: () => void;
  binType?: Chart_Bin_Type;
  duplicateWidget?: () => void;
  boardId?: number;
}

interface FullCustomChartCardProps {
  chartData: PlotFragment;
  loading?: boolean;
  newCard?: boolean;
  hideHeader?: boolean;
  deleteChart?: () => void;
  duplicateWidget?: () => void;

  boardId?: number;
}

export const LoadableCustomChartCard = ({ chartId, chartTitle, deleteChart, duplicateWidget, binType, boardId }: LoadableCustomChartCardProps) => {
  const filterState = useContext(FilterContext);
  const { curTeamId: teamId } = useValidTeamAppContext();
  const { data: plotData, error } = useGetPlotQuery({
    variables: { pageLevelFilterNode: filterState.filterConsumable, teamId, chartBinType: binType, chartId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  if (plotData?.getPlot) {
    return <FullCustomChartCard chartData={plotData.getPlot} deleteChart={deleteChart} key={chartId} duplicateWidget={duplicateWidget} boardId={boardId} />;
  }
  if (error)
    return <FailedCustomChartCard chartId={chartId} chartTitle={chartTitle} deleteChart={deleteChart} duplicateWidget={duplicateWidget} boardId={boardId} />;
  return <SkeletonCustomChartCard chartId={chartId} chartTitle={chartTitle} deleteChart={deleteChart} duplicateWidget={duplicateWidget} boardId={boardId} />;
};

export const BaseChartCard = ({ children }: { children: React.ReactNode }) => {
  return <div className="custom-chart-card flex flex-col border-2 border-gray-200 rounded-lg w-full h-full">{children}</div>;
};

export const BaseChartCardBody = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="h-full bg-white rounded-lg">
      <div className={classNames('w-full', 'h-full')} id="chart-container">
        {children}
      </div>
    </div>
  );
};

const SkeletonCustomChartCard = ({ chartId, chartTitle, deleteChart, duplicateWidget, boardId }: LoadableCustomChartCardProps) => {
  return (
    <BaseChartCard>
      <CustomChartCardHeader
        title={chartTitle}
        id={chartId}
        newCard={false}
        deleteChart={deleteChart}
        chartData={undefined}
        dataSeriesConfigs={[]}
        duplicateWidget={duplicateWidget}
        boardId={boardId}
      />
      <BaseChartCardBody>
        <div
          data-testid="custom-chart-card-skeleton"
          className={`custom-chart-card-skeleton relative 
        before:absolute before:inset-0
        before:-translate-x-full  
        before:animate-[shimmer_1.5s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-gray-500  before:opacity-[0.2]
        isolate
        overflow-hidden opacity-70 w-full h-full`}
        >
          <div className="space-y-3 justify-center items-center flex py-8 h-full bg-white">
            <ChartBarIcon className="h-32 w-32 text-gray-300" />
          </div>
        </div>
      </BaseChartCardBody>
    </BaseChartCard>
  );
};

const FailedCustomChartCard = ({ chartId, chartTitle, deleteChart, duplicateWidget, boardId }: LoadableCustomChartCardProps) => {
  return (
    <BaseChartCard>
      <CustomChartCardHeader
        title={chartTitle}
        id={chartId}
        newCard={false}
        chartData={undefined}
        deleteChart={deleteChart}
        dataSeriesConfigs={[]}
        duplicateWidget={duplicateWidget}
        boardId={boardId}
      />
      <BaseChartCardBody>
        <div className="flex flex-col items-center justify-center h-full px-2">
          <p className="text-center text-gray-500">Failed to load chart.</p>
        </div>
      </BaseChartCardBody>
    </BaseChartCard>
  );
};

export const FullCustomChartCard = ({ chartData, deleteChart, duplicateWidget, loading, newCard, hideHeader, boardId }: FullCustomChartCardProps) => {
  return (
    <BaseChartCard>
      {!hideHeader ? (
        <CustomChartCardHeader
          title={chartData?.title}
          chartData={chartData}
          id={chartData?.id}
          newCard={newCard ?? false}
          deleteChart={deleteChart}
          loading={loading}
          dataSeriesConfigs={chartData.plotConfiguration.seriesConfig}
          duplicateWidget={duplicateWidget}
          boardId={boardId}
        />
      ) : null}
      <BaseChartCardBody>
        <Plot plotData={chartData} loading={loading ?? false} showLegend={chartData.plotConfiguration.chartType !== Chart_Type.HorizontalBar} />
      </BaseChartCardBody>
    </BaseChartCard>
  );
};

interface CustomChartCardHeaderProps {
  dataSeriesConfigs: DataSeriesConfigurationFragment[];
  chartData: PlotFragment | undefined;
  id: number;
  title: string;
  newCard: boolean;
  loading?: boolean;
  deleteChart?: () => void;
  duplicateWidget?: () => void;

  boardId?: number;
}

const CustomChartCardHeader = ({
  dataSeriesConfigs,
  chartData,
  id,
  title,
  newCard,
  loading,
  deleteChart,
  duplicateWidget,
  boardId,
}: CustomChartCardHeaderProps) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const { hasPermission } = useContext(PermissionsContext);
  const chartDispatch = useChartDispatch();

  const filterState = useContext(FilterContext);

  let navigate = useNavigate();
  const navigateToEdit = () => {
    /**
     * Set the chartState to the chartData
     * This makes it so when we navigate to the edit page we already have the chartData
     * and don't need to refetch from the backend.
     */
    if (chartData) {
      chartDispatch({ type: ChartActionType.SetCurrentChart, payload: { currentChart: chartData } });
      chartDispatch({ type: ChartActionType.SetChartConfigs, payload: { chartConfigs: chartData.plotConfiguration } });
    }

    navigate(generateChartNavigationURL({ teamId, orgId, chartId: id, boardId, filterState }));
  };

  const settings: ISettingsItem[] = [
    {
      name: 'Edit Chart',
      id: 0,
      disabled: !hasPermission(Resource.Charts, Action.Update),
      group: 'action',
      htmlId: 'edit-chart-widget',
      onClick: () => navigateToEdit(),
      icon: <PencilIcon className="w-5 h-5" />,
    },
    {
      name: 'Copy Chart URL',
      id: 1,
      group: 'action',
      htmlId: 'copy-chart-widget-url',
      onClick: () => copyChartLink({ chartId: id, teamId, orgId, boardId, filterState }),
      icon: <LinkIcon className="w-5 h-5" />,
    },
    {
      name: 'Duplicate Chart',
      id: 2,
      group: 'action-2',
      htmlId: 'duplicate-chart-widget',
      disabled: !hasPermission(Resource.Charts, Action.Create),
      icon: <DocumentDuplicateIcon className="h-5 w-5" />,

      onClick: () => duplicateWidget?.(),
    },
    {
      name: 'Delete Chart',
      textColor: 'failure',
      id: 3,
      disabled: !hasPermission(Resource.Charts, Action.Delete),
      group: 'action-2',
      htmlId: 'delete-chart-widget',
      icon: <TrashIcon className="h-5 w-5" />,
      onClick: () => {
        deleteChart?.();
      },
    },
  ];

  useEffect(() => {
    if (dataSeriesConfigs) {
      Promise.all(
        dataSeriesConfigs?.map(async (series) => {
          // const convertedFilter = buildFilterInputFromSavedFilterInput(series.filterInput ?? {});
          // // I don't know what this does...
          // await setUiFilters(
          //   convertedFilter,
          //   (filter) => {
          //     let withTeamName = filter.map((f) => {
          //       return { ...f, teamName: series.filterInput?.teamName };
          //     });
          //     withTeamName = withTeamName.sort((a, b) => {
          //       const textA = getBadgeText(a, new Set(withTeamName?.map((fltr) => fltr.teamName)).size < 2 && a.teamName === currentTeam.name);
          //       const textB = getBadgeText(b, new Set(withTeamName?.map((fltr) => fltr.teamName)).size < 2 && b.teamName === currentTeam.name);
          //       return textA.length - textB.length;
          //     });
          //     setHeaderFilters(withTeamName);
          //   },
          //   () => getDataForFilters({ variables: { teamId: series.team.id, orgId } })
          // );
        })
      ).then(() => {});
    }
  }, []);

  return (
    <div className={`bg-silver justify-center text-white flex flex-col rounded-t-md px-4 py-2 cursor-pointer`} onClick={navigateToEdit}>
      <div className="flex flex-row justify-between items-center gap-x-2">
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-row gap-x-2 items-center">
            <ChartBarSquareIcon className="h-5 w-5 xl:h-6 xl:w-6 text-gray-400" />
            <div className="flex flex-col justify-start">
              <h1 className="font-semibold text-sm 2xl:text-lg text-blueberry line-clamp-1">{title}</h1>
            </div>
          </div>
        </div>
        <div>
          {!newCard ? (
            <div className="flex flex-row gap-x-3 2xl:gap-x-4 items-center">
              <div className="min-w-fit">
                <FiltersPopover dataSeriesConfigs={dataSeriesConfigs} />
              </div>
              <div className="flex flex-row items-center gap-x-3">
                {/* <Tippy theme="dark" delay={200} content={'Drag to rearrange widget'}> */}
                <div className="widget-drag-handle cursor-pointer hover:scale-110 duration-100">
                  <Icon icon="la:arrows-alt" color={'#292E5B'} className="w-4 h-4 2xl:h-5 2xl:w-5" onClick={(e) => e.stopPropagation()} />
                </div>
                {/*  </Tippy> */}
                <div
                  id="chart-card-settings-menu"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SettingsMenu settings={settings} center>
                    <Tippy theme="dark" delay={200} content={'Settings'}>
                      <EllipsisVerticalIcon className="w-5 h-5 2xl:h-6 2xl:w-6 text-blueberry -mr-1 focus:outline-none hover:scale-110 duration-100" />
                    </Tippy>
                  </SettingsMenu>
                </div>
              </div>
            </div>
          ) : null}
          {loading ? (
            <div className="flex flex-row gap-x-2 items-center text-white">
              <AdjustableLoadingIcon color={'white'} width={4} height={4} />
              <p className="text-md">Updating chart...</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const copyChartLink = ({
  chartId,
  teamId,
  orgId,
  boardId,
  filterState,
}: {
  chartId: number;
  boardId?: number;
  teamId: number;
  orgId: number;
  filterState: FilterState;
}) => {
  const chartNavigation = generateChartNavigationURL({ teamId, orgId, chartId, boardId, filterState });
  const chartUrl = window.location.protocol + '//' + window.location.host + chartNavigation;
  navigator.clipboard.writeText(chartUrl);
  toast.success('Link successfully copied!');
  return chartUrl;
};

const generateChartNavigationURL = ({
  teamId,
  orgId,
  chartId,
  boardId,
  filterState,
}: {
  teamId: number;
  orgId: number;
  chartId: number;
  boardId?: number;
  filterState: FilterState;
}) => {
  const filterNode = new FilterNode(filterState);
  const urlParams = new URLSearchParams();
  urlParams.set('teamId', teamId.toString());
  urlParams.set('orgId', orgId.toString());
  urlParams.set('filterSet', filterNode.getUrlEncodedFilterNode());

  return `${AppRoutes.v3FullPath.boards}/${boardId}/charts/${chartId}` + '?' + urlParams.toString();
};

const FilterBadge = ({
  filters,
  index,
  badgeRef,
  trimGroupTitle,
}: {
  filters: IFilter[];
  index: number;
  badgeRef?: React.RefObject<HTMLElement>;
  trimGroupTitle?: boolean;
}) => {
  const filter = filters[index];
  const { currentTeam } = useValidTeamAppContext();
  let badgeValue: IBadgeFilter = {
    text: getBadgeText(filter, new Set(filters?.map((fltr) => fltr.teamName)).size < 2 && filter.teamName === currentTeam.name),
    id: index.toString(),
    filter: filter,
  };
  const fullText = badgeValue.text;
  if (trimGroupTitle && badgeValue.filter.filterCategory === FilterCategory.GroupTitle) badgeValue.text = truncateAndEllipsis(badgeValue.text, 40);
  return (
    <Tippy disabled={fullText === badgeValue.text} content={trimGroupTitle ? fullText : ''} theme="dark">
      <div id="applied-filter" className="" key={index}>
        <Badge
          badge={badgeValue}
          ref={badgeRef}
          key={index}
          smaller
          color="bg-gray-300"
          textColor="text-blueberry"
          textFont="font-semibold"
          capitalize={false}
        />
      </div>
    </Tippy>
  );
};

const FiltersPopover = ({ dataSeriesConfigs }: { dataSeriesConfigs: DataSeriesConfigurationFragment[] }) => {
  const [isShowing, setIsShowing] = useState(false);
  const totalFilters = dataSeriesConfigs.reduce((acc, curr) => {
    const filterTree = FilterTree.fromConsumable(curr.filterNode);
    return acc + filterTree.getAppliedFields().length;
  }, 0);

  if (totalFilters === 0) return <div></div>;
  return (
    <Popover className="relative">
      <Popover.Group>
        <Popover.Button className="flex" onMouseEnter={() => setIsShowing(true)} onMouseLeave={() => setIsShowing(false)}>
          <div className="flex flex-row gap-x-2 items-center bg-gray-200 rounded-full px-3 text-blueberry">
            {/* <AdjustmentsHorizontalIcon className="h-4 w-4" /> */}
            <div className="flex flex-row text-xs 2xl:text-sm font-semibold  divide-x divide-opacity-30 divide-blueberry">
              <p className=" pr-2">Filters</p>
              <p className="pl-2">{totalFilters}</p>
            </div>
          </div>
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          show={isShowing}
        >
          <Popover.Panel className={`absolute z-10 w-96 right-0 pt-1`} onMouseEnter={() => setIsShowing(true)} onMouseLeave={() => setIsShowing(false)}>
            <div className="overflow-auto max-h-32 bg-white border-2 border-gray-300 shadow-md rounded-lg p-3 flex flex-row gap-y-1 overflow-y-auto gap-x-1 flex-wrap">
              {dataSeriesConfigs.map((config, index) => {
                const filterState = InitialFilterStateProvider.getStateFromFilterNode(config.filterNode, config.team.id);
                return (
                  <>
                    {dataSeriesConfigs.length > 1 ? (
                      <div className="flex flex-col w-full">
                        <div className="font-light text-gray-500 text-sm pt-2">{config.team.name}</div>
                        <hr className="border-gray-200 w-full pb-2" />
                      </div>
                    ) : (
                      <></>
                    )}

                    <FilterContext.Provider value={filterState}>
                      <DisabledFilterDisplay filter={filterState.appliedFilter} key={index} />
                    </FilterContext.Provider>
                  </>
                );
              })}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover.Group>
    </Popover>
  );
};
