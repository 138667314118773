import { ApolloError } from '@apollo/client';
import {
  FeedbackIntegrationFragment,
  Integration_Category,
  Integration_Type,
  Integration_Type_Requirement,
  useFeedbackIntegrationMutation,
  useIntegrationConnectedLazyQuery,
} from '../../../../../../generated/graphql';
import { BaseIntegrationForm } from './BaseIntegrationForm';
import { useValidTeamAppContext } from '../../../../../../v2/contexts/AppContext';
import { getIntegrationRequirementsToSend } from './helpers';


interface QueryRes {
  success: boolean;
  error: string | null;
}

interface IntegrationFormComponentProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  integrationType: Integration_Type;
  requirements: Integration_Type_Requirement[];
  setRequirements: (reqs: Integration_Type_Requirement[]) => void;
  setSuccessModalOpen?: (open: boolean) => void;
  updateIntegration: (integrationType: Integration_Type, requirements: Integration_Type_Requirement[], name: string) => void;
  name: string;
  setName: (name: string) => void;
}
export const DataImportIntegrationForm = ({
  modalOpen,
  setModalOpen,
  integrationType,
  requirements,
  setRequirements,
  setSuccessModalOpen,
  updateIntegration,
  name,
  setName,
}: IntegrationFormComponentProps) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const [testIntegration, testIntegrationMutation] = useIntegrationConnectedLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [setFeedbackIntegration, setFeedbackIntegrationMutation] = useFeedbackIntegrationMutation();

  const handleTestIntegration = async (): Promise<QueryRes> => {
    let res: QueryRes = { success: false, error: null };

    await testIntegration({
      variables: {
        input: {
          integrationTypeId: integrationType.id,
          teamId,
          requirements: getIntegrationRequirementsToSend(requirements.filter((req) => req.category === Integration_Category.Import)),
        },
      },
      onCompleted: (data) => {
        if (data.testIntegrationConnected?.success) res = { success: true, error: null };
        else res = { success: false, error: 'Error validating credentials - make sure they are correct and try again' };
      },
      onError: (error: ApolloError) => {
        console.error(error);
        res = { success: false, error: null }; //Unknown error, letting default form handle generic message.
      },
    });
    return res;
  };

  return (
    <BaseIntegrationForm
      name={name}
      setName={setName}
      existingIntegrations={integrationType.orgExternalTicketsIntegration ?? []}
      integrationType={integrationType}
      modalOpen={modalOpen}
      mutationLoading={setFeedbackIntegrationMutation.loading}
      requirements={requirements}
      setModalOpen={setModalOpen}
      setRequirements={setRequirements}
      setSuccessModalOpen={setSuccessModalOpen}
      testConnection={handleTestIntegration}
      testConnectionError={testIntegrationMutation.error}
      testConnectionLoading={testIntegrationMutation.loading}
      updateIntegration={updateIntegration}
      key={integrationType.id}
    />
  );
};

