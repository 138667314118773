import { AddNewIntegrationAction } from "../actions/addNewIntegration";

export const addNewIntegrationReducer = (state: AddNewIntegrationReducerResult, action: AddNewIntegrationAction): AddNewIntegrationReducerResult => {
    switch (action.type) {
        case 'openIntegrationScroller': {
            return {
                ...state,
                isIntegrationScrollerOpen: true,
            };
        }
        case 'closeIntegrationScroller': {
            return {
                ...state,
                isIntegrationScrollerOpen: false,
            };
        }
    }
}

export interface AddNewIntegrationReducerResult {
    isIntegrationScrollerOpen: boolean;
}
