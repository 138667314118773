import Tippy from '@tippyjs/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../v2/util';
interface TooltipIconProps {
  tooltipContent: string;
  extraClassNames?: string;
  styles?: string;
}
const TooltipIcon = ({ tooltipContent, extraClassNames, styles }: TooltipIconProps) => {
  return (
    <Tippy content={tooltipContent} duration={300} appendTo={'parent'}>
      <div className={styles}>
        <QuestionMarkCircleIcon className={classNames('h-4 w-4 text-blueberry', extraClassNames)} aria-hidden="true" />
      </div>
    </Tippy>
  );
};
export default TooltipIcon;
