import { Routes, Route } from 'react-router-dom';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { ExplorePage } from '../ExplorePage';
import GroupPage from '../GroupPage';
import { FilterablePage } from '../FilterablePage';

interface ExplorePageRouterProps {
  pageName: string;
}
export const pageSize = 1000;

export const ExplorePageRouter = ({ pageName }: ExplorePageRouterProps) => {
  const { curTeamId: teamId } = useValidTeamAppContext();

  return (
    <div>
      <FilterablePage teamId={teamId}>
        <ExplorePage pageName={pageName} />
        <Routes>
          <Route path="/group/:groupId" element={<GroupPage />} />
        </Routes>
      </FilterablePage>
    </div>
  );
};
