import { useMemo } from 'react';
import { Breakdown, PlotFragment, SentimentType, Y_Axis_Data } from '../../generated/graphql';
import { chartColors, sentimentColorMap, npsBucketColorMap } from '../components/charts/CustomChart';
import {
  FilterNodeSchema,
  FilterStatementSchema,
  FilterNodeState,
  FilterState,
  computeFilterConsumable,
} from '../../reducers/filterStatement/filterStatementReducer';
import uuid from 'react-uuid';

interface TabItem {
  id: string;
  title: string;
  color: string;
  filterState: FilterState;
  teamId: number;
}

const addIdsToFilterNode = (node: FilterNodeSchema): FilterNodeState => {
  if (node.type === 'statement') {
    return {
      ...node,
      id: uuid(),
    };
  }

  return {
    ...node,
    id: uuid(),
    items: node.items.map((item) => addIdsToFilterNode(item)),
  };
};

const buildFilterState = (baseFilter: FilterNodeSchema, statements: FilterStatementSchema[], teamId: number): FilterState => {
  // Start with an empty collection if no base filter
  let filterNode: FilterNodeSchema = baseFilter || {
    type: 'collection',
    operator: 'AND',
    items: [],
  };

  // If we have statements to add, ensure we have a collection to add them to
  if (statements.length > 0) {
    if (filterNode.type === 'statement') {
      filterNode = {
        type: 'collection',
        operator: 'AND',
        items: [filterNode],
      };
    }

    if (filterNode.type === 'collection') {
      filterNode.items = [...filterNode.items, ...statements];
    }
  }

  // Convert to FilterState
  return {
    teamId,
    appliedFilter: addIdsToFilterNode(filterNode),
    staticConditions: [],
    filterConsumable: computeFilterConsumable([], addIdsToFilterNode(filterNode)),
  };
};

/**
 * Hook to generate feedback tabs and their corresponding filters based on chart configuration
 * @param plot The plot fragment containing series data and breakdown information
 * @returns Array of tab items with their filters
 */
export const useChartFeedback = (plot: PlotFragment): TabItem[] => {
  return useMemo(() => {
    const breakdown = plot.plotConfiguration?.breakdown;
    const yAxisMetric = plot.plotConfiguration?.yAxisMetric;
    const seriesConfig = plot.plotConfiguration?.seriesConfig;

    // If there's no breakdown, each series becomes a tab
    if (!breakdown && plot.series) {
      return plot.series
        .map((series, index) => {
          let teamId: number | undefined;

          if (plot.series!.length === 1) {
            teamId = seriesConfig?.[0]?.team?.id;
          } else {
            // The series label IDs are in format "index-teamId"
            const [, extractedTeamId] = (series.seriesLabel?.id || '').split('-');
            teamId = extractedTeamId ? parseInt(extractedTeamId) : undefined;
          }

          const matchingConfig = seriesConfig?.find((config) => config.team?.id === teamId);

          // Return null if we don't have a valid team ID
          if (!matchingConfig?.team?.id) return null;

          const baseFilter = matchingConfig?.filterNode ? JSON.parse(matchingConfig.filterNode) : undefined;

          const statements: FilterStatementSchema[] = [];

          if (yAxisMetric === Y_Axis_Data.Favorability) {
            statements.push({
              type: 'statement',
              fieldName: 'Entry.sentiment',
              operator: '==',
              value: SentimentType.Positive,
            });
          }

          return {
            id: series.seriesLabel?.id || index.toString(),
            title: series.seriesLabel?.name || 'All Feedback',
            color: getColorForIndex(index),
            filterState: buildFilterState(baseFilter, statements, matchingConfig.team.id),
            teamId: matchingConfig.team.id, // Use the actual team ID
          };
        })
        .filter((tab): tab is TabItem => tab !== null); // Filter out null tabs
    }

    // Breakdown case
    if (!plot.series || !seriesConfig?.[0]?.team?.id) return []; // Return empty if no valid team ID

    const teamId = seriesConfig[0].team.id; // We know this exists from the check above

    return plot.series
      .map((series, index) => {
        const baseFilter = seriesConfig?.[0]?.filterNode // Always use first series config
          ? JSON.parse(seriesConfig[0].filterNode)
          : undefined;

        const statements: FilterStatementSchema[] = [];
        const label = series.seriesLabel;
        if (!label) return null;

        // Add breakdown statement
        switch (breakdown) {
          case Breakdown.Sentiment:
            statements.push({
              type: 'statement',
              fieldName: 'Entry.sentiment',
              operator: '==',
              value: mapNumberToSentiment(Number(label.id)),
            });
            break;

          case Breakdown.Source:
            statements.push({
              type: 'statement',
              fieldName: 'Entry.source',
              operator: '==',
              value: label.id,
            });
            break;

          case Breakdown.Group:
          case Breakdown.LeafGroup:
            statements.push({
              type: 'statement',
              fieldName: 'Entry.Group.id',
              operator: '==',
              value: label.id,
            });
            break;

          case Breakdown.Segment: {
            const segmentGroupId = seriesConfig[0]?.segmentGroupId;
            if (segmentGroupId) {
              statements.push({
                type: 'statement',
                fieldName: `Entry.Segment.${segmentGroupId}.value`,
                operator: '==',
                value: label.id,
              });
            }
            break;
          }
        }

        // Add favorability filter if needed
        if (yAxisMetric === Y_Axis_Data.Favorability) {
          statements.push({
            type: 'statement',
            fieldName: 'Entry.sentiment',
            operator: '==',
            value: SentimentType.Positive,
          });
        }

        // Get the appropriate color based on breakdown type and label
        let color = chartColors[index];
        if (label.name) {
          if (breakdown === Breakdown.Sentiment) {
            color = sentimentColorMap[label.name];
          } else if (breakdown === Breakdown.Segment) {
            // Check if this is an NPS breakdown (3 buckets: detractor, passive, promoter)
            const npsNames = ['detractor', 'passive', 'promoter'];
            const isNpsBucket =
              plot.series?.length === 3 &&
              plot.series
                .map((s) => s.seriesLabel?.name?.toLowerCase())
                .sort((a, b) => (a || '').localeCompare(b || ''))
                .every((name, idx) => name === npsNames[idx]);

            if (isNpsBucket) {
              color = npsBucketColorMap[label.name.toLowerCase()];
            }
          }
        }

        return {
          id: label.id,
          title: label.name,
          color,
          filterState: buildFilterState(baseFilter, statements, teamId),
          teamId,
        };
      })
      .filter((tab): tab is TabItem => tab !== null);
  }, [plot]);
};

/**
 * Returns a color from a predefined palette based on index
 */
const getColorForIndex = (index: number): string => {
  return chartColors[index % chartColors.length];
};

const mapNumberToSentiment = (num: number): SentimentType => {
  switch (num) {
    case -1:
      return SentimentType.Negative;
    case 0:
      return SentimentType.Neutral;
    case 1:
      return SentimentType.Positive;
    default:
      return SentimentType.Neutral;
  }
};
