import { Fragment, useContext, useState } from 'react';
import { FilterField, FilterFieldValue, Operator, useGetFilterFieldsLazyQuery } from '../../../../generated/graphql';
import { FilterStatementSchema } from '../../../../reducers/filterStatement/filterStatementReducer';
import { FieldDropdown } from './FieldDropDown';
import { FieldValueConfiguration } from './FieldValueConfiguration';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { FilterContext } from '../../../../context/filterStatementContext';
import { LoadingDots } from '../filterBar/DateFilterComponent';
import { Popover, Transition } from '@headlessui/react';
import { Float } from '@headlessui-float/react';

export const FilterConfigurationPopup = ({
  onComplete,
  selectedFieldName,
  children,
  prePopulatedValues,
  prePopulatedOperator,
}: {
  onComplete: (node: FilterStatementSchema) => void;
  selectedFieldName?: string;
  children?: JSX.Element;
  prePopulatedValues?: FilterFieldValue[];
  prePopulatedOperator?: Operator;
}) => {
  const { curTeamId } = useValidTeamAppContext();
  const { filterConsumable } = useContext(FilterContext);

  const [selectedField, setSelectedField] = useState<FilterField | null>(null);
  const [getFilterFields, { data, loading }] = useGetFilterFieldsLazyQuery();

  const resetState = () => {
    setSelectedField(null);
  };

  const handleMenuOpen = () => {
    getFilterFields({
      variables: {
        teamId: curTeamId,
        fieldStatementFilters: filterConsumable,
      },
      onCompleted: (data) => {
        if (selectedFieldName) {
          const field = data.getFilterFields.find((field) => field.fieldName === selectedFieldName);
          if (field) {
            setSelectedField(field);
          }
        }
      },
    });
  };

  return (
    <Popover className="relative flex inline-block text-left items-center">
      {({ open, close }) => (
        <Float
          strategy="fixed"
          placement="bottom-start"
          offset={6}
          shift={true}
          flip={{
            fallbackPlacements: ['top-start', 'right-start'],
            padding: 8,
          }}
          portal={true}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Button className="focus:outline-none" onClick={handleMenuOpen}>{children}</Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="font-sofiapro border-2 border-gray-200 bg-white z-50 rounded-lg w-96 shadow-lg">
              {loading ? (
                <div className="flex flex-col justify-center items-center h-10 p-4">
                  <LoadingDots />
                </div>
              ) : (
                <div className="w-full">
                  {selectedField ? (
                    <FieldValueConfiguration
                      selectedField={selectedField}
                      setSelectedField={setSelectedField}
                      resetFunction={resetState}
                      onComplete={(node) => {
                        onComplete(node);
                        close();
                      }}
                      prePopulatedValues={prePopulatedValues}
                      prePopulatedOperator={prePopulatedOperator}
                    />
                  ) : (
                    <FieldDropdown fields={data?.getFilterFields ?? []} setSelectedField={setSelectedField} selectedField={selectedField} />
                  )}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </Float>
      )}
    </Popover>
  );
};
