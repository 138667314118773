import { ChevronRightIcon } from '@heroicons/react/24/solid';

export const FilterRow = (props: { selected: boolean; filterName: string; filterType: string; onClick: () => void }) => {
  return (
    <div
      id={`filter-row-${props.filterType}`}
      className={`cursor-pointer hover:bg-gray-100 flex flex-row justify-between w-full px-4 py-2 transition-all duration-200 ${
        props.selected ? 'bg-gray-100' : ''
      }`}
      onClick={props.onClick}
    >
      <div className="flex flex-col">
        <div className="text-blueberry-light font-semibold">{props.filterName}</div>
      </div>
      <div className="text-gray-500">
        <ChevronRightIcon className="w-4 h-4 mt-1" />
      </div>
    </div>
  );
};
