import { DataFormat } from '../../exports/exporters/DataFormat';
import { EntryExporter } from '../../exports/exporters/EntryExporter';
import { ProgressState, Status } from '../../exports/ProgressMonitor';
import { Operator } from '../../generated/graphql';
import { localDateString } from '../../v2/util';
import { adaptForFilterNode, adaptForOperator } from '../../v3/components/filters/operatorUtils';
import { FilterTree } from '../../v3/lib/filterTree';
import { getExportErrorHandler } from './errorHandling';
import { listenForFileDownload, updateProgressOnInterval } from './progressBar';

/**
 * This function is a UI event handler that is responsible for exporting data and updating the progress of the export.
 * Updating the progress state will in turn update the ProgressBar component.
 *
 * @param params
 * @param exportProgress
 * @param updateExportProgress
 */
export const exportGroupWithProgressUpdate = (
  params: {
    filterConsumable: string;
    groupTitle: string;
    teamId: number;
    groupIds: string[];
  },
  exportProgress: ProgressState,
  updateExportProgress: React.Dispatch<React.SetStateAction<ProgressState>>
) => {
  // const reportParams = { teamId: params.teamId, groupIds: params.groupIds, filterInput: params.filterInput };
  // TODO: Add groupId to the filterState and pipe the consumable to the exporter
  // throw new Error('Not implemented'); - we need to keep this for now to not break exports on the taxonomy page
  const filterTree = FilterTree.fromConsumable(params.filterConsumable);
  filterTree.addNode({
    type: 'statement',
    fieldName: 'Entry.Group.id',
    operator: adaptForFilterNode(Operator.In),
    value: `[${params.groupIds.join(',')}]`,
  });
  const groupExport = EntryExporter.getInstance({ teamId: params.teamId, filterStateConsumable: filterTree.toConsumable() });

  const fileName = `Unwrap Group Export - ${params.groupTitle} - ${localDateString(new Date(), 'YYYY-MM-DD HH:MM:SS Z')}`;

  const observer = listenForFileDownload(fileName, updateExportProgress);
  const timeout = updateProgressOnInterval(groupExport, updateExportProgress);

  const { teamId, groupIds } = params;

  /**
   * Note: When all the promises have resolved we keep the progress bar at 90% since it takes time for the CSV generator to download the report to the browser.
   */
  groupExport
    .export(fileName, DataFormat.CSV)
    .catch(getExportErrorHandler({ teamId, groupIds }, exportProgress, observer, updateExportProgress))
    .finally(() => {
      window.clearInterval(timeout);
      updateExportProgress({ status: Status.finished, percent: 90 });
    });

  updateExportProgress({ status: Status.initializing, percent: 0 });
};
