import { useContext, useEffect, useState } from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { FilterContext, FilterDispatchContext } from '../../../../context/filterStatementContext';
import { AlterStaticFiltersPayload, FilterGroupActionType } from '../../../../reducers/filterStatement/filterStatementReducer';
import { DatePicker } from '../../../baseComponents/DatePicker';
import { useGetEarliestFeedbackDateLazyQuery } from '../../../../generated/graphql';
import { DateFilterUtility, DateRangeOption } from '../../../filters/utilities/DateFilterUtility';
import { format } from 'date-fns';
import { Popover } from '@headlessui/react';
import { Float } from '@headlessui-float/react';

const DateFilterComponent = ({ teamId }: { teamId: number }) => {
  const filterState = useContext(FilterContext);
  const filterDispatch = useContext(FilterDispatchContext);
  const [earliestFeedbackDate, setEarliestFeedbackDate] = useState<Date | undefined>(undefined);

  const [startDate, setStartDate] = useState<Date | undefined>(DateFilterUtility.getStartDate(filterState));
  const endDate = DateFilterUtility.getEndDate(filterState);

  const [earliestFeedbackDateQuery, { loading: earliestFeedbackDateLoading }] = useGetEarliestFeedbackDateLazyQuery();

  useEffect(() => {
    earliestFeedbackDateQuery({
      variables: {
        teamId: teamId,
      },
      onCompleted: (data) => {
        setEarliestFeedbackDate(new Date(data.earliestFeedbackDate));
      },
    });
  }, [teamId]);

  return (
    <div className="relative w-auto">
      <div className="flex items-center px-1.5 rounded-full bg-gray-100 text-gray-700">
        {earliestFeedbackDateLoading || !earliestFeedbackDate ? (
          <div className="flex justify-center items-center h-[34px] w-[100px]">
            <LoadingDots />
          </div>
        ) : (
          <DateOptionDropdown setStartDate={setStartDate} earliestFeedbackDate={earliestFeedbackDate!} />
        )}
        <span className="border-r border-gray-300 h-9 w-1 flex flex-shrink relative"></span>
        <span className="flex flex-row items-center gap-x-1">
          <div className="w-24" data-testid="start-date-picker">
            <DatePicker
              date={startDate}
              onChange={(dt: Date) => {
                const payload = DateFilterUtility.getModifyDateFiltersPayload({ start: dt });
                filterDispatch({ type: FilterGroupActionType.AlterStaticFilters, payload });
                setStartDate(dt);
              }}
            />
          </div>
          <p className="text-gray-400 text-xs">to</p>
          <div className="w-24" data-testid="end-date-picker">
            <DatePicker
              date={endDate}
              onChange={(dt: Date) => {
                const payload = DateFilterUtility.getModifyDateFiltersPayload({ end: dt });
                filterDispatch({ type: FilterGroupActionType.AlterStaticFilters, payload });
              }}
              placeholder={format(new Date(), 'MMM d, yyyy')}
            />
          </div>
        </span>
      </div>
    </div>
  );
};

export const LoadingDots = ({ className = '' }: { className?: string }) => (
  <div className={`flex items-center gap-1 ${className}`} data-testid="loading-dots">
    {[0, 1, 2].map((i) => (
      <div
        key={i}
        className="h-1.5 w-1.5 rounded-full bg-gray-600 animate-dot-fade"
        style={{
          animationDelay: `${i * 0.2}s`,
        }}
      />
    ))}
  </div>
);

const DateOptionDropdown = ({ setStartDate, earliestFeedbackDate }: { setStartDate: (date: Date) => void; earliestFeedbackDate: Date }) => {
  const filterState = useContext(FilterContext);
  const filterDispatch = useContext(FilterDispatchContext);

  const date_range_options: DateRangeOption[] = [
    {
      label: '7D',
      getDates: () => ({
        start: new Date(new Date().setDate(new Date().getDate() - 7)),
      }),
    },
    {
      label: '30D',
      getDates: () => ({
        start: new Date(new Date().setDate(new Date().getDate() - 30)),
      }),
    },
    {
      label: '90D',
      getDates: () => ({
        start: new Date(new Date().setDate(new Date().getDate() - 90)),
      }),
    },
    {
      label: '1Y',
      getDates: () => ({
        start: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      }),
    },
    {
      label: 'All',
      getDates: () => ({
        start: earliestFeedbackDate,
      }),
    },
  ];

  const [selectedRange, setSelectedRange] = useState<DateRangeOption>(DateFilterUtility.getSelectedOption(filterState, date_range_options));

  useEffect(() => {
    setSelectedRange(DateFilterUtility.getSelectedOption(filterState, date_range_options));
  }, [filterState]);

  const handleSelectRange = (option: DateRangeOption, close: () => void) => {
    let payload: AlterStaticFiltersPayload;
    if (option.label === 'All') {
      payload = DateFilterUtility.getReplaceDateFiltersPayload({ start: earliestFeedbackDate });
    } else {
      payload = DateFilterUtility.getReplaceDateFiltersPayload(option.getDates());
    }
    setSelectedRange(option);
    const startDate = option.getDates().start;
    if (startDate) setStartDate(startDate);
    filterDispatch({ type: FilterGroupActionType.AlterStaticFilters, payload });
    close();
  };

  return (
    <Popover className="relative flex flex-row ">
      {({ open, close }) => (
        <Float
          placement="bottom-start"
          offset={4}
          shift={true}
          flip={{
            fallbackPlacements: ['top-start'],
            padding: 8,
          }}
          portal={true}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Button className="focus:outline-none" id="date-filter-button">
            <span className="flex flex-row group items-center px-1 gap-x-1 rounded-full hover:text-raspberry duration-200">
              <CalendarIcon className="h-5 w-5 text-gray-400" />
              <div className="flex flex-row gap-x-2">
                <span
                  className="font-medium text-sm text-blueberry group-hover:text-raspberry duration-150 flex items-center gap-1 whitespace-nowrap"
                  data-testid="date-filter-dropdown-button"
                >
                  {selectedRange.label}
                  <svg
                    className={`h-4 w-4 transform fill-gray-400 duration-200 transition-all ease-out ${open ? 'rotate-180' : ''}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </span>
          </Popover.Button>

          <Popover.Panel className="w-32 z-50 font-sofiapro" data-testid="date-filter-dropdown-options">
            <div className="flex flex-col rounded-lg border-2 border-gray-200 bg-white shadow-lg">
              {date_range_options.map((option) => (
                <button
                  key={option.label}
                  className={`w-full text-sm px-3 py-2 text-left font-medium hover:bg-gray-100 border-b last:border-none last:rounded-b-lg first:rounded-t-lg border-gray-200 duration-100 ${
                    selectedRange.label === option.label ? 'bg-silver text-blueberry' : 'text-blueberry'
                  }`}
                  onClick={() => handleSelectRange(option, close)}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </Popover.Panel>
        </Float>
      )}
    </Popover>
  );
};

export default DateFilterComponent;
