import { ComparisonType, FeedbackIntegrationFragment, SegmentGroupQuery } from "../../generated/graphql";
import { FeedbackIntegrationAction, FeedbackIntegrationActionType } from "../actions/feedbackIntegration";

export const feedbackIntegrationReducer = (state: FeedbackIntegrationReducerResult, action: FeedbackIntegrationAction): FeedbackIntegrationReducerResult => {
    switch (action.type) {
        case FeedbackIntegrationActionType.UpdateIntegration: {
            const feedbackIntegration = action.payload.feedbackIntegration;
            return {
                ...state,
                feedbackIntegration: feedbackIntegration,
            };
        }
        case FeedbackIntegrationActionType.UpdateOldFeedbackIntegration: {
            const feedbackIntegration = action.payload.feedbackIntegration;
            return {
                ...state,
                oldFeedbackIntegration: feedbackIntegration,
                feedbackIntegration: feedbackIntegration,
            };
        }
        case FeedbackIntegrationActionType.UpdateDataSourceFields: {
            const dataSourceFieldsState = action.payload;
            return {
                ...state,
                dataSourceFields: dataSourceFieldsState.dataSourceFields,
                sourceFieldsLoading: dataSourceFieldsState.sourceFieldsLoading
            };

        }
        case FeedbackIntegrationActionType.AddSegments: {
            // When we update segments, we should do the following:
            // 1. Check if there is a an existing segment group with the same displayName
            // 2. If there is, we get the id of the existing segment group
            // 3. If there is not, we create a new segment group and get the id of the new segment group
            // 4. We then update the segmentConfig with the new segment group id
            // 5. We then update the nextNegativeSegmentGroupId and nextNegativeSegmentConfigId
            let segment = action.payload.segment;
            const currentSegmentGroups = state.segmentGroups;
            const existingSegment = currentSegmentGroups.segments?.find(
                (sg: { displayName: any; }) => sg.displayName === segment?.segmentGroup.displayName
            );
            if (existingSegment?.id) {
                segment!.segmentGroup.id = existingSegment.id;
            }
            else {
                segment!.segmentGroup.id = state.nextNegativeSegmentGroupId;
                state.nextNegativeSegmentGroupId--;
            }
            // Now, create the new segment config
            segment!.id = state.nextNegativeSegmentConfigId;
            state.nextNegativeSegmentConfigId--;
            const newFeedbackIntegration: FeedbackIntegrationState = {
                ...state.feedbackIntegration,
                segmentConfig: [
                    ...(state.feedbackIntegration?.segmentConfig || []),
                    segment!
                ]
            };

            return {
                ...state,
                feedbackIntegration: newFeedbackIntegration
            }
        }
        case FeedbackIntegrationActionType.DeleteSegment: {
            const segmentToDelete = action.payload.segment;
            let newSegmentConfigs = [...state.feedbackIntegration.segmentConfig];
            newSegmentConfigs = newSegmentConfigs.filter(segment => segment.id !== segmentToDelete.id);
            // IFF there are no other custom fields associated with that segmentGroup
            // we need to delete the filters associated with that segmentGroup 
            let newFilters = [...state.feedbackIntegration.filters];
            const customFieldsWithDisplayName = newSegmentConfigs.filter(segment => segment.path !== "OVERRIDE" && segment.segmentGroup.displayName === segmentToDelete.segmentGroup.displayName);
            if (customFieldsWithDisplayName.length === 0) {
                newFilters = newFilters.filter(filter => filter.segmentGroup.displayName !== segmentToDelete.segmentGroup.displayName);
            }
            return {
                ...state,
                feedbackIntegration: { 
                    ...state.feedbackIntegration!, 
                    segmentConfig: newSegmentConfigs, 
                    filters: newFilters 
                }
            };
        }
        case FeedbackIntegrationActionType.UpdateExistingFilter: {
            const filterIndex = action.payload.filterIndex;
            const filter = action.payload.filter;
            const newFilters = [...state.feedbackIntegration!.filters];
            newFilters[filterIndex] = filter;
            return {
                ...state,
                feedbackIntegration: {
                    ...state.feedbackIntegration!,
                    filters: newFilters
                }
            };
        }
        case FeedbackIntegrationActionType.AddFilter: {
            const filter = action.payload.filter;
            return {
                ...state,
                feedbackIntegration: {
                    ...state.feedbackIntegration!,
                    filters: [...state.feedbackIntegration!.filters, filter]
                }
            };
        }
        case FeedbackIntegrationActionType.DeleteFilter: {
            const filterIndex = action.payload.filterIndex;
            const newFilters = [...state.feedbackIntegration!.filters];
            newFilters.splice(filterIndex, 1);
            return {
                ...state,
                feedbackIntegration: { ...state.feedbackIntegration!, filters: newFilters }
            };
        }
        case FeedbackIntegrationActionType.UpdateRequirementsAndName: {
            const requirements = action.payload.requirements;
            const name = action.payload.name;
            return {
                ...state,
                feedbackIntegration: { 
                    ...state.feedbackIntegration!, 
                    requirementValues: requirements, 
                    integrationName: name 
                }
            };
        }
        default: {
            return state;
        }
    }
}

export interface DataSourceField {
    path: string;
    values: string[];
}

export interface FeedbackIntegrationReducerResult {
    feedbackIntegration: FeedbackIntegrationState;
    oldFeedbackIntegration: FeedbackIntegrationState;
    segmentGroups: SegmentGroupQuery;
    dataSourceFields: DataSourceField[];
    sourceFieldsLoading: boolean;
    nextNegativeSegmentGroupId: number;
    nextNegativeSegmentConfigId: number;
}


export interface FeedbackIntegrationState {
    filters: FeedbackIntegrationFilter[];
    id?: number;
    integrationName: string;
    requirementValues: FeedbackIntegrationRequirementValue[],
    segmentConfig: FeedbackSegmentConfig[],
    defaultDistillateDisplay: boolean;
    type: FeedbackIntegrationFragment["type"],
    integrationTypeRequirements: FeedbackIntegrationFragment["requirements"]["requirements"]
}

export interface FeedbackIntegrationFilter {
    comparison: ComparisonType;
    id?: number;
    segmentGroup: FeedbackSegmentGroup;
    value: string;
}

export interface FeedbackSegmentConfig {
    __typename?: "feedback_segment_config" | undefined;
    id: number;
    override?: string | null | undefined;
    path: string;
    segmentGroup: FeedbackSegmentGroup;
}

export interface FeedbackSegmentGroup {
    id: number;
    displayName: string;
}

export interface FeedbackIntegrationRequirementValue {
    __typename?: "integration_type_requirement_value" | undefined
    valueId?: number;
    value: string;
    requirement: {
        id: number,
        key: string
    };
}