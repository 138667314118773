import Button, { ButtonShape, ButtonVariant } from "../../baseComponents/Button";
import LoadingSpinner from "../../baseComponents/LoadingSpinner";
import { useState, useContext } from "react";
import { FeedbackIntegrationContext } from "../../context/FeedbackIntegrationContext";
import { usePollExecutionQuery, useValidateIntegrationConfigQuery } from "../../../generated/graphql";
import {buildFeedbackIntegrationMutationForValidation}  from "../../lib/feedbackIntegration";
import { useValidTeamAppContext } from "../../../v2/contexts/AppContext";
import { toast } from "react-hot-toast";
import { ValidatedDataModal } from "./ValidatedDataModal";
import { FeedbackEntry } from "./ValidatedDataModal";

interface ValidateIntegrationConfigurationCardProps {
    onSave: () => void
}

export const ValidateIntegrationConfigurationCard = (props: ValidateIntegrationConfigurationCardProps) => {
    const data = useContext(FeedbackIntegrationContext);
    const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [validatedData, setValidatedData] = useState<FeedbackEntry[]>([]);
    const [showValidatedDataModal, setShowValidatedDataModal] = useState(false);
    const { data: validateData, refetch: validateIntegration } = useValidateIntegrationConfigQuery({
        skip: true // Prevents query from running automatically
    });
    const { data: pollData, refetch: pollValidation } = usePollExecutionQuery({
        skip: true // Prevents query from running automatically
    });

    const handleValidateIntegration = async () => {
        setIsLoading(true);
        try {
            const validationResult = await validateIntegration({
                input: buildFeedbackIntegrationMutationForValidation(data.feedbackIntegration!, teamId)
            });

            if (validationResult.data?.validateIntegrationConfig?.validationUuid) {
                const uuid = validationResult.data.validateIntegrationConfig.validationUuid;
                
                while (true) {
                    const pollResult = await pollValidation({
                        executionUuid: uuid,
                        teamId: teamId,
                        orgId: orgId
                    });
                    
                    console.log('Poll Result:', pollResult);

                    if (pollResult.error) {
                        throw new Error("Polling failed");
                    }

                    const status = pollResult.data?.pollExecution?.status;
                    if (status === "SUCCEEDED") {
                        const validatedData = JSON.parse(pollResult.data?.pollExecution?.responsePayload || '[]');
                        console.log('Validated Data:', validatedData);
                        
                        toast.success("Your integration settings are valid. You can now proceed to save your integration.");
                        setShowValidatedDataModal(true);
                        setValidatedData(validatedData);
                        break;
                    } else if (status === "RUNNING") {
                        await new Promise(resolve => setTimeout(resolve, 5000)); // Wait 5 seconds
                        continue;
                    } else {
                        // throw new Error("Validation failed");
                        toast.error("There seems to be an issue with the validation process. Please double check your integration settings and try again.");
                        break;
                    }
                }
            }
        } catch (error) {
            console.error(error);
            toast.error("There seems to be an issue with the validation process. Please double check your integration settings and try again.");
        } finally {
            setIsLoading(false);
        }
        setShowValidatedDataModal(true);
    };

    return (
        <>
            {isLoading ? (
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
                    <LoadingSpinner/> 
                    <div className="text-white mt-4">Validating integration settings. This may take up to 5 minutes...</div>
                </div>
            ) : (
                <div className="mb-10" id="validate-integration-configuration-card">
                    <div className="bg-silver rounded-md pt-8 pb-11 px-8 text-center flex flex-col items-center gap-6">
                        <div className="text-center flex flex-col justify-center items-center gap-2 max-w-96">
                            <h3 className="font-semibold text-2xl text-blueberry">Validate results of this integration</h3>
                            <p className="text-blueberry-lighter leading-5">to make sure your integration returns results you're expecting.</p>
                            <p className="italic text-gray-400 text-sm">Last validation: 12th May 2024, 11.30AM</p>    
                        </div>
                        <div className="text-center flex flex-col justify-center items-center gap-2">
                            <Button 
                                text="Validate Integration"
                                shape={ButtonShape.Pill}
                                onClick={handleValidateIntegration}
                                id="validate-integration-button"
                            />
                            <p className="italic text-gray-400 text-sm">*The process might take up to 5 minutes</p>    
                        </div>
                    </div>
                </div>
            )}
            {showValidatedDataModal && <ValidatedDataModal onClose={() => setShowValidatedDataModal(false)} onSave={props.onSave} validatedData={validatedData} />}
        </>
    )
}