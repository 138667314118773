import { BookmarkIcon } from '@heroicons/react/24/outline';
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/24/solid';
import { ToolbarBaseButton } from './ToolbarBaseButton';
import { useContext, useState } from 'react';
import { Resource, Action, useTogglePinGroupMutation } from '../../../../../generated/graphql';
import { PermissionsContext } from '../../../../../v2/contexts/PermissionsContext';
import { GroupDataContext, GroupDispatchContext } from '../../../../../context/groupContext';
import { useValidTeamAppContext } from '../../../../../v2/contexts/AppContext';
import { GroupActions } from '../../../../../reducers/group/GroupDispatch';
import { TaxonomyDispatchContext } from '../../../../context/TaxonomyDispatchContext';

export function PinGroupButton(props: { buttonRounding?: 'left' | 'right' }) {
  const groupState = useContext(GroupDataContext);
  const { curTeamId } = useValidTeamAppContext();
  const groupDispatch = useContext(GroupDispatchContext);
  const dispatch = useContext(TaxonomyDispatchContext);

  const [togglePinGroupMutation] = useTogglePinGroupMutation();
  const [pinLoading, setPinLoading] = useState(false);
  const { hasPermission } = useContext(PermissionsContext);
  const disabledTooltip = 'You do not have permission to pin groups. Please contact your administrator and request the contributor role.';
  const handlePinGroup = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setPinLoading(true);
    if (!groupState.groupData) return;

    togglePinGroupMutation({
      variables: { groupId: groupState.groupData.id, teamId: curTeamId },
      onCompleted: (data) => {
        setPinLoading(false);
        groupDispatch({ type: GroupActions.SET_PIN_GROUP, payload: { isPinnedByUser: data.togglePinGroup.isPinnedByUser } });
        dispatch({ type: 'togglePinGroup', payload: { groupId: groupState.groupData!.id, pinnedByUser: data.togglePinGroup.isPinnedByUser } });
      },
    });
  };

  let icon = <BookmarkIcon className="h-5 w-5 self-center" id="pin-group" onClick={handlePinGroup} />;
  if (groupState.groupData?.isPinnedByUser) icon = <BookmarkIconSolid className="h-5 w-5 self-center" id="unpin-group" onClick={handlePinGroup} />;

  const tooltipLabel = groupState.groupData?.isPinnedByUser ? 'Unpin Group' : 'Pin Group';
  return (
    <ToolbarBaseButton
      id="toolbar-pin-group-button"
      icon={icon}
      dataCy="toolbar-pin-group-button"
      label={tooltipLabel}
      onClick={handlePinGroup}
      loading={pinLoading}
      disabled={!hasPermission(Resource.PinnedGroups, Action.Update)}
      disabledTooltip={disabledTooltip}
      buttonRounding={props.buttonRounding}
    />
  );
}
