import { Popover, Transition } from '@headlessui/react';
import { Fragment, useContext, useState } from 'react';
import { alphabeticalSort, capitalizeFirstLetter } from '../../../../v2/util';
import Badge from '../../../../baseComponents/Badge';
import { ComboBox } from '../../../../baseComponents/ComboBox';
import toast from 'react-hot-toast';
import { GroupDataContext, GroupDispatchContext } from '../../../../context/groupContext';
import { useGetTagsQuery } from '../../../../generated/graphql';
import { useRemoveTagFromGroupMutation } from '../../../../generated/graphql';
import { useCreateTagMutation } from '../../../../generated/graphql';
import { GetTagsDocument, useTagGroupMutation } from '../../../../generated/graphql';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { GroupActions } from '../../../../reducers/group/GroupDispatch';
import { IDropDownItem } from '../../../../baseComponents/DropDown';

export const TagsPopover = () => {
  const groupState = useContext(GroupDataContext);
  return (
    <Popover className="relative items-center font-sofiapro">
      <Popover.Button className="flex focus:outline-none">
        <div className="flex flex-row gap-x-2 h-8 bg-silver items-center rounded-xl hover:cursor-pointer px-3 hover:bg-silver-darker duration-150">
          <h1 className="font-light text-blueberry w-full flex flex-row gap-x-1">
            Tags: <b className="font-semibold">{groupState.groupData?.tags?.length ?? 0}</b>
          </h1>
        </div>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-150"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute flex-col gap-y-4 right-0 z-50 text-blueberry bg-milk rounded-lg shadow-lg p-4 flex w-[24rem] px-8">
          {({ close }) => <TagsPopoverBody />}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

/**
 * This needs to be refactored to use the GroupDataContext state and manually fetch the tags from the backend.
 * Otherwise this will not update when you change the tags here.
 */
const TagsPopoverBody = () => {
  const { curTeamId } = useValidTeamAppContext();
  const groupState = useContext(GroupDataContext);
  const groupStateDispatch = useContext(GroupDispatchContext);
  const [selectedTag, setSelectedTag] = useState<IDropDownItem | undefined>(undefined);

  const { data, loading, error } = useGetTagsQuery({
    variables: { teamId: curTeamId },
  });
  const [createTagMutation] = useCreateTagMutation({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: GetTagsDocument, variables: { teamId: curTeamId } }],
  });
  const [tagGroupMutation] = useTagGroupMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });

  if (loading) return <div>Loading...</div>;

  return (
    <div id="tags-popover-body" className="flex flex-col gap-y-3">
      <div className="flex w-full">
        <ComboBox
          placeholder="Search for tag or create one..."
          comboBoxData={
            data?.getTags
              ?.filter((tag) => !groupState.groupData!.tags?.some((t) => t.id === tag.id))
              .sort((a, b) => alphabeticalSort(a.name, b.name))
              .map((tag) => {
                return { ...tag, name: capitalizeFirstLetter(tag.name) };
              }) ?? []
          }
          defaultOption={true}
          selectedItem={selectedTag}
          setSelectedItem={(item) => {
            setSelectedTag(item);
            if (!groupState.groupData) return;

            if (item) {
              if (!data?.getTags?.some((tag) => tag.id === item.id)) {
                const toastId = toast.loading('Creating tag...');
                createTagMutation({
                  variables: { teamId: curTeamId, name: item.name },
                  onCompleted: () => {
                    groupStateDispatch({ type: GroupActions.ADD_TAG_TO_GROUP, payload: { tag: item } });
                    toast.dismiss(toastId);
                    setSelectedTag(undefined);
                  },
                });
              } else {
                const toastId = toast.loading('Adding tag to group...');
                tagGroupMutation({
                  variables: { teamId: curTeamId, groupId: groupState.groupData.id, tagId: item.id },
                  onCompleted: () => {
                    groupStateDispatch({ type: GroupActions.ADD_TAG_TO_GROUP, payload: { tag: item } });
                    toast.dismiss(toastId);
                    setSelectedTag(undefined);
                  },
                });
              }
            }
          }}
        />
      </div>
      <div className="flex flex-row flex-wrap gap-x-2">
        {groupState.groupData?.tags?.map((tag) => {
          return <TagItem tag={tag} />;
        })}
      </div>
    </div>
  );
};

export const TagItem = (props: { tag: { id: number; name: string } }) => {
  const [removeTagLoading, setRemoveTagLoading] = useState<boolean>(false);
  const { curTeamId } = useValidTeamAppContext();
  const groupState = useContext(GroupDataContext);
  const groupStateDispatch = useContext(GroupDispatchContext);
  const [removeTagMutation] = useRemoveTagFromGroupMutation({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: GetTagsDocument, variables: { teamId: curTeamId } }],
  });
  return (
    <div className="flex-none">
      <Badge
        color="bg-silver-darker"
        textColor="text-blueberry"
        key={props.tag.id}
        capitalize={true}
        badge={{ id: props.tag.id.toString(), text: props.tag.name }}
        onRemove={() => {
          const toastId = toast.loading('Removing tag...');
          setRemoveTagLoading(true);
          removeTagMutation({
            variables: { teamId: curTeamId, groupId: groupState.groupData!.id, tagId: props.tag.id },
            onCompleted: () => {
              groupStateDispatch({ type: GroupActions.REMOVE_TAG_FROM_GROUP, payload: { tag: props.tag } });
              toast.dismiss(toastId);
              setRemoveTagLoading(false);
            },
          });
        }}
        loading={removeTagLoading}
      />
    </div>
  );
};
