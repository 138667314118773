import { checkExactMatch } from '../../lib/searchPreview';
import EmployeeOnlyComponent from '../../baseComponents/EmployeeOnlyComponent';
import NewGroupToggle from './NewGroupToggle';
import { SearchPreviewDispatchContext } from '../../context/SearchPreviewDispatchContext';
import { SearchPreviewContext } from '../../context/SearchPreviewContext';
import toast from 'react-hot-toast';
import { useAlertGroupFailedMutation, useCreateGroupMutation, useGetGroupLazyQuery } from '../../../generated/graphql';
import { TaxonomyDispatchContext } from '../../context/TaxonomyDispatchContext';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useContext, useEffect, useState } from 'react';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { classNames } from '../../../v2/util';
import { FilterContext } from '../../../context/filterStatementContext';

const SearchSection = () => {
  const [createGroupMutation, createGroupResult] = useCreateGroupMutation({});
  const [alertGroupFailed] = useAlertGroupFailedMutation({});
  const [getGroupFull] = useGetGroupLazyQuery({});
  const filterState = useContext(FilterContext);

  const { curTeamId: teamId } = useValidTeamAppContext();
  const dispatch = useContext(SearchPreviewDispatchContext);
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const { excludeFromNewFilter, searchTerm } = useContext(SearchPreviewContext);

  const toggleExclude = (exclude: boolean) => {
    dispatch({ type: 'toggleExclude', payload: { exclude } });
  };

  const [isLoading, setIsLoading] = useState(false);

  const createGroup = async (searchTerm: string) => {
    let breakCircuit = false;
    let errorCount = 0;
    while (!breakCircuit) {
      const result = await createGroupMutation({
        variables: {
          teamId,
          queryString: searchTerm,
          excludeFromNewFilter,
        },
      });

      if (createGroupResult.error) {
        errorCount += 1;
        if (errorCount > 2) {
          breakCircuit = true;
          await alertGroupFailed({ variables: { teamId, queryString: searchTerm, wasRetried: true } });
          toast.error("We're sorry, but we were unable to create a group for this search. Please try again later.");
        }
      } else {
        const groupId = result.data?.createGroup?.id;
        if (groupId) {
          breakCircuit = true;
          getGroupFull({
            variables: { teamId, groupId, belongs: true, filterStatement: filterState?.filterConsumable },
            onCompleted: (data) => {
              toast.success(`Successfully created search group for ${data.getGroup.title}`);
              const finalGroup = data.getGroup;
              taxonomyDispatch({
                type: 'addGroup',
                payload: { group: finalGroup },
              });
              dispatch({ type: 'setSearchTerm', payload: { searchTerm: '' } });
            },
          });
        } else {
          breakCircuit = true;
          await alertGroupFailed({ variables: { teamId, queryString: searchTerm, wasRetried: false } });
          toast.error("We're sorry, but we were unable to create a group for this search. Please try again later.");
        }
      }
    }
    setIsLoading(false);
  };

  const finalizeSearch = async (query: string | undefined) => {
    if (!query || query === '') {
      dispatch({ type: 'setSearchTerm', payload: { searchTerm: '' } });
    } else if (checkExactMatch(query)) {
      try {
        setIsLoading(true);
        await createGroup(query);
      } catch (e) {
        toast.error('Failed to execute exact match search. Please try again.');
        setIsLoading(false);
      }
    } else {
      dispatch({ type: 'setSearchTerm', payload: { searchTerm: query } });
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center gap-y-2">
      <SearchBarInput finalizeSearch={finalizeSearch}>
        <EmployeeOnlyComponent>
          <div className="flex flex-row text-sm justify-center items-center gap-x-2">
            <NewGroupToggle exclude={excludeFromNewFilter} toggleExclude={toggleExclude} />
          </div>
        </EmployeeOnlyComponent>
      </SearchBarInput>
      {isLoading && <SearchLoading isExactMatchQuery={checkExactMatch(searchTerm)} />}
    </div>
  );
};

const SearchBarInput = (props: { finalizeSearch: (query: string) => void; children: React.ReactNode }) => {
  const { searchTerm: searchTermContext } = useContext(SearchPreviewContext);
  const dispatch = useContext(SearchPreviewDispatchContext);
  const [localSearchInput, setLocalSearchInput] = useState<string>(searchTermContext);
  const isExactMatchQuery = checkExactMatch(localSearchInput);
  useEffect(() => {
    setLocalSearchInput(searchTermContext ?? '');
  }, [searchTermContext]);
  return (
    <div className="flex flex-row items-center justify-between gap-x-2 w-full">
      <div className="w-full flex-grow">
        <div className="flex w-full flex-row items-center gap-x-2">
          <div className={'relative flex w-full items-stretch text-gray-400 focus-within:text-gray-600'}>
            <input
              id="full-text-search"
              className={classNames(
                'lg:text-md silver-with-shadow block w-full pl-9 text-sm text-blueberry focus:ring-blueberry placeholder:italic',
                'py-4',
                'rounded-full'
              )}
              data-testid="search-input"
              placeholder="Search for Groups of feedback about..."
              type="text"
              name="search"
              autoComplete="off"
              value={localSearchInput}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  props.finalizeSearch(localSearchInput);
                }
              }}
              onChange={(e) => {
                setLocalSearchInput(e.target.value);
              }}
            />
            <div className="absolute inset-y-0 left-3 flex items-center">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
            </div>
            <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2 ">
              {localSearchInput && localSearchInput.length > 0 && (
                <div
                  className=" rounded-full p-1 hover:bg-gray-200"
                  onClick={() => {
                    dispatch({ type: 'clearGroup', payload: {} });
                  }}
                >
                  <XMarkIcon id="search-box-clear" className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <button
        className="bg-blueberry flex w-1/5 flex-row items-center justify-center gap-x-2 rounded-full py-4 px-6 text-sm text-milk duration-200 hover:bg-blueberry-lighter"
        onClick={() => {
          if (localSearchInput) props.finalizeSearch(localSearchInput);
        }}
      >
        {isExactMatchQuery ? 'Exact Search' : 'Search'}
      </button>
      {props.children}
    </div>
  );
};

const SearchLoading = ({ isExactMatchQuery }: { isExactMatchQuery: boolean }) => {
  return (
    <div className="my-2 flex items-center justify-center gap-x-4 text-blueberry" data-testid="search-loading">
      <div className="h-10 w-10 animate-spin rounded-full border-t-2 border-blueberry"></div>
      <h1 className="text-xl">{isExactMatchQuery ? 'Building Group' : 'Generating search preview...'}</h1>
    </div>
  );
};
export default SearchSection;
