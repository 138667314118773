import { SVGProps } from 'react';

// Import all SVG icons
import { ReactComponent as ChartAreaIcon } from '../../../assets/customIcons/chart-area.svg';
import { ReactComponent as ChartBarHorizontalIcon } from '../../../assets/customIcons/chart-bar-horizontal.svg';
import { ReactComponent as ChartBarVerticalIcon } from '../../../assets/customIcons/chart-bar-vertical.svg';
import { ReactComponent as ChartBreakdownIcon } from '../../../assets/customIcons/chart-breakdown.svg';
import { ReactComponent as ChartLineAltIcon } from '../../../assets/customIcons/chart-line-alt.svg';
import { ReactComponent as ChartPieIcon } from '../../../assets/customIcons/chart-pie.svg';
import { ReactComponent as ChartPlotUnitIcon } from '../../../assets/customIcons/chart-plot-unit.svg';
import { ReactComponent as ChartTypeIcon } from '../../../assets/customIcons/chart-type.svg';
import { ReactComponent as ChartFrequencyIcon } from '../../../assets/customIcons/chart-frequency.svg';

const icons = {
  'chart-area': ChartAreaIcon,
  'chart-bar-horizontal': ChartBarHorizontalIcon,
  'chart-bar-vertical': ChartBarVerticalIcon,
  'chart-breakdown': ChartBreakdownIcon,
  'chart-line-alt': ChartLineAltIcon,
  'chart-pie': ChartPieIcon,
  'chart-plot-unit': ChartPlotUnitIcon,
  'chart-type': ChartTypeIcon,
  'chart-frequency': ChartFrequencyIcon,
} as const;

export type IconName = keyof typeof icons;

interface CustomIconProps extends SVGProps<SVGSVGElement> {
  name: IconName;
  className?: string;
}

export const CustomIcon = ({ name, className = '', ...props }: CustomIconProps) => {
  const Icon = icons[name];
  return <Icon className={className} {...props} />;
};
