import { FilterInput } from '../generated/graphql';

import { useRef, useEffect } from 'react';
import { IUser } from './contexts/UserContext';
import { NavigateFunction } from 'react-router-dom';
import { V3_DASHBOARD } from '../Routes';
import moment from 'moment';
import { INavigation } from '../v3/layouts/DashboardLayout/helpers';

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const removeFiltersFromUrl = () => {
  window.history.pushState({}, document.title, window.location.pathname);
  return undefined;
};

export const getFiltersFromUrl = (filterParam: string, teamId: number): FilterInput | undefined => {
  let filtersFromUrl: FilterInput | undefined;
  const urlSearchParams = new URLSearchParams(window.location.search);
  try {
    const decodedUriComponent = decodeURIComponent(urlSearchParams.get(filterParam) ?? '{}');
    // this comes in as string 'undefined' not undefined WTF JS
    if (!decodedUriComponent || decodedUriComponent === 'undefined' || decodedUriComponent === 'null') {
      return undefined;
    }

    filtersFromUrl = JSON.parse(decodedUriComponent ?? '');
    const teamFromUrl = urlSearchParams.get('teamId');
    if (teamFromUrl == null) return undefined;
    if (teamId !== Number(teamFromUrl)) {
      return removeFiltersFromUrl();
    }
    return filtersFromUrl;
  } catch (e) {
    console.error('Error parsing filters from URL', e);
    return removeFiltersFromUrl();
  }
};

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export const truncate = (string: string, lengthToTruncate: number) => {
  if (string.length > lengthToTruncate) {
    return string.slice(0, lengthToTruncate) + '...';
  } else {
    return string;
  }
};

export const ifUnwrapperShowId = (user: IUser | undefined, id: number) => {
  return user?.isUnwrapper ? ` (${id})` : '';
};
/**
 * Returns true/false if all the filters in FilterInput are empty
 * By default, every filter (except for startDate and endDate) are checked.
 * @param filters current FilterInput
 * @param filtersToCheck optional parameter, only check to see if keys in the filtersToCheck array are empty.
 *
 * @returns
 */

export const areFiltersEmpty = (filters: FilterInput, filtersToCheck?: (keyof FilterInput)[], filtersToIgnore?: (keyof FilterInput)[]): boolean => {
  return Object.keys(filters)
    .filter((filterName) =>
      filtersToCheck ? filtersToCheck.includes(filterName as keyof FilterInput) : filterName !== 'startDate' && filterName !== 'endDate'
    )
    .filter((filterName) => (filtersToIgnore ? !filtersToIgnore.includes(filterName as keyof FilterInput) : true))
    .every((filterName) => {
      return filters[filterName as keyof FilterInput]?.length === 0 || filters[filterName as keyof FilterInput] == null;
    });
};

/**
 * Returns true if you should show the filtered stats
 * @param filterInput
 * @returns
 */
export const getFiltersStatsMode = (filterInput: FilterInput | undefined) => {
  const filteredStatsMode = !areFiltersEmpty(filterInput ?? {}, undefined, ['startDate', 'endDate', 'groupFilter', 'groupTitleFilterQuery']);
  return filteredStatsMode;
};

export const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validateImage = (image: string) => {
  return image.match(/\.(jpeg|jpg|gif|png)$/);
};

export const handleUserRedirect = (navigate: NavigateFunction) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const destination = urlSearchParams.get('destination');
  if (destination) {
    urlSearchParams.delete('destination');
    navigate(destination + '?' + urlSearchParams.toString());
  } else {
    navigate(`/${V3_DASHBOARD}`);
  }
};

export const alphabeticalSort = (a: string, b: string) => {
  return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
};

export const capitalizeFirstLetter = (text?: string | undefined | null) => {
  if (!text) {
    return '';
  }
  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
};

export function getTeamChangeRedirectURL(currentURL: string): string | null {
  // Regular expression to match edit URLs
  const editURLPattern = /^\/dashboard\/(.*?)\/(\d+)\/(edit)$/;
  const creatorURLPattern = /^\/dashboard\/(.*?)\/creator$/;
  const match = currentURL.match(editURLPattern) ?? currentURL.match(creatorURLPattern);

  if (match) {
    // Extract the relevant parts of the URL
    const resource = match[1];
    // Return the redirect URL
    return `/dashboard/${resource}`;
  }

  // If the URL doesn't match the pattern, return null
  return null;
}

export function localDateString(date: Date, format: string = 'll'): string {
  return moment.utc(date).local().format(format);
}

export const truncateAndEllipsis = (text: string | null | undefined, length: number) => {
  if (!text) return '';
  return text.length > length ? text.slice(0, length) + '...' : text;
};

export const filterRoutes = (item: INavigation, user?: IUser, validIntegrations?: string[]) => {
  return !(item.unwrapperRequired && !user?.isUnwrapper) && !(item.hasReplyIntegration && validIntegrations?.length === 0 && !user?.isUnwrapper);
};

export const propercaseWord = (str: string) => {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}
