import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import InlineEditableTextArea from '../../../../baseComponents/InlineEditableTextArea';
import { useBoardDispatch, useBoardState } from '../../../../context/boardContext';
import { Action, Resource, useUpdateBoardDescriptionMutation, useUpdateBoardTitleMutation } from '../../../../generated/graphql';
import { BoardActionTypes } from '../../../../reducers/boards/boardReducer';
import { AppRoutes } from '../../../../Routes';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { PermissionsContext } from '../../../../v2/contexts/PermissionsContext';
import Button, { ButtonShape, ButtonSize, ButtonVariant } from '../../../baseComponents/Button';
import { BoardToolbar } from '../../../components/boards/board/BoardToolbar';
import { AddBoardWidgetModal } from '../../../components/Modals/AddBoardWidgetModal';

export const BoardHeaderSection = () => {
  const [addWidgetModalOpen, setAddWidgetModalOpen] = useState(false);
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const state = useBoardState();
  const dispatch = useBoardDispatch();
  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionsContext);
  const [updateBoardTitle, _] = useUpdateBoardTitleMutation();
  const [updateBoardDescription, _2] = useUpdateBoardDescriptionMutation();

  async function handleSaveBoardTitle(value: string) {
    await updateBoardTitle({
      variables: { boardId: state.board.id, title: value, teamId },
      onCompleted: () => {
        toast.success('Title Updated');
        dispatch({ type: BoardActionTypes.SetTitle, payload: { title: value } });
      },
      refetchQueries: ['GetBoards'],
    });
  }

  async function handleSaveBoardDescription(value: string) {
    await updateBoardDescription({
      variables: { boardId: state.board.id, description: value, teamId },
      onCompleted: () => {
        toast.success('Description Updated');
        dispatch({ type: BoardActionTypes.SetDescription, payload: { description: value } });
      },
      refetchQueries: ['GetBoards'],
    });
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-x-1 justify-between mb-1">
        <AddBoardWidgetModal modalOpen={addWidgetModalOpen} setModalOpen={setAddWidgetModalOpen} />
        <div className="flex flex-col w-3/5 gap-y-2">
          <div className="flex flex-row gap-x-4 items-center">
            <Button
              id="back-button"
              variant={ButtonVariant.Tertiary}
              shape={ButtonShape.Pill}
              size={ButtonSize.Small}
              text="Back"
              onClick={() => navigate(AppRoutes.v3FullPath.boards)}
              icon={<p>{'<'}</p>}
              iconPosition="left"
            />
          </div>
        </div>
        <div className="flex flex-row items-start justify-end gap-x-2">
          <div className="">
            <Button
              variant={ButtonVariant.Bordered}
              shape={ButtonShape.Pill}
              size={ButtonSize.Small}
              onClick={() => setAddWidgetModalOpen(true)}
              text="+ Add widget"
            />
          </div>
          <BoardToolbar />
        </div>
      </div>
      <InlineEditableTextArea
        ignorePadding
        editable={hasPermission(Resource.Boards, Action.Update)}
        textAreaId="board-title-text-area"
        value={state.board.title ?? ''}
        onEditComplete={handleSaveBoardTitle}
        additionalClassNames={'h-auto focus:border-none hover:bg-silver focus:bg-silver bg-transparent transition-[background-color] duration-100 rounded-md'}
        textClassName="text-3xl xl:text-4xl text-blueberry font-semibold"
      />
      <InlineEditableTextArea
        ignorePadding
        editable={hasPermission(Resource.Boards, Action.Update)}
        textAreaId="board-description-text-area"
        value={state.board.description ?? ''}
        onEditComplete={handleSaveBoardDescription}
        additionalClassNames={
          'text-md text-blueberry h-auto font-normal focus:border-none hover:bg-silver focus:bg-silver bg-transparent transition-[background-color] duration-100 rounded-md mt-0.5'
        }
        placeholder='Add a description...'
      />
    </div>
  );
};
