import { GroupDataFragment, GroupTaxonomyFragment, TeamGroupsTaxonomyFlatQuery, TeamGroupsTaxonomyTreeQuery } from '../../generated/graphql';
import { TaxonomyGroup } from '../reducers/taxonomy';

type TaxonomyActionType =
  | 'loadTaxonomy'
  | 'loadFlatTaxonomy'
  | 'setTaxonomy'
  | 'updateTaxonomy'
  | 'updateFlatTaxonomy'
  | 'toggleExpand'
  | 'toggleExpandAll'
  | 'addGroup'
  | 'updateGroupTitle'
  | 'buildGroup'
  | 'error'
  | 'togglePinGroup'
  | 'discardGroup'
  | 'updateProgress'
  | 'removeChildFromParent'
  | 'assignChildren';

export interface TaxonomyAction {
  type: TaxonomyActionType;
  payload:
    | AddOrphanPayload
    | SetTaxonomyPayload
    | ToggleExpandPayload
    | TeamGroupsTaxonomyFlatQuery
    | TeamGroupsTaxonomyTreeQuery
    | ToggleExpandAllPayload
    | ErrorPayload
    | SearchGroupsPayload
    | CreateGroupPayload
    | BuildGroupPayload
    | AddGroupPayload
    | TogglePinGroupPayload
    | UpdateGroupTitlePayload
    | DiscardGroupPayload
    | UpdateProgressPayload
    | RemoveChildFromParentPayload
    | AssignChildrenPayload;
}

export interface RemoveChildFromParentPayload {
  parentGroupId: string;
  childGroupId: string;
}

export interface AssignChildrenPayload {
  parentGroupId: string;
  childGroups: GroupTaxonomyFragment[];
}

export interface TogglePinGroupPayload {
  groupId: string;
  pinnedByUser: boolean;
}

export interface DiscardGroupPayload {
  groupId: string;
}

export interface UpdateProgressPayload {
  groupId: string;
  progress: number;
}

export interface AddOrphanPayload {
  getGroups: GroupTaxonomyFragment[];
}
export interface SetTaxonomyPayload {
  taxonomy: Map<string, TaxonomyGroup>;
}
export interface UpdateGroupTitlePayload {
  groupId: string;
  title: string;
}
export interface ToggleExpandPayload {
  groupId: string;
  state: ExpandState;
}

export interface ToggleExpandAllPayload {
  state: ExpandState;
}

export interface ErrorPayload {
  error: Error;
}

export interface SearchGroupsPayload {
  searchInput: string;
}

export interface CreateGroupPayload {
  searchInput: string;
}

export interface BuildGroupPayload {
  group: GroupDataFragment;
}
export interface AddGroupPayload {
  group: GroupDataFragment;
}

export enum ExpandState {
  Expanded = 'expanded',
  Collapsed = 'collapsed',
}
