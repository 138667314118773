import { FeedbackIntegrationFragment, Feedback_Integration_Type, Integration_Type, Integration_Type_Requirement } from '../../../../../generated/graphql';
import { DataImportIntegrationForm } from './forms/DataImportIntegrationForm';
interface IntegrationFormNewProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  integrationType: Integration_Type;
  requirements: Integration_Type_Requirement[];
  setRequirements: (reqs: Integration_Type_Requirement[]) => void;
  setSuccessModalOpen?: (open: boolean) => void;
  updateIntegration: (integrationType: Integration_Type, requirements: Integration_Type_Requirement[], name: string) => void;
  name: string;
  setName: (name: string) => void;
}

export const IntegrationForm = ({ modalOpen, setModalOpen, requirements, setRequirements, integrationType, setSuccessModalOpen, updateIntegration, name, setName }: IntegrationFormNewProps) => {
  if (integrationType.feedbackIntegrationType === Feedback_Integration_Type.DataImport) {
    return (
      <DataImportIntegrationForm
        name={name}
        setName={setName}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        requirements={requirements.filter((req) => req.category === 'import')}
        setRequirements={setRequirements}
        integrationType={integrationType}
        setSuccessModalOpen={setSuccessModalOpen}
        updateIntegration={updateIntegration}
      />
    );
  }
  return <></>;
};
