import { XMarkIcon } from "@heroicons/react/24/outline";
import { useContext, useState, useRef, useEffect } from "react";
import { AddNewIntegrationDispatchContext } from "../../context/AddNewIntegrationDispatchContext";
import { SearchInput } from "../../baseComponents/SearchInput";
import { IntegrationScrollerItem } from "./IntegrationScrollerItem";
import { Integration_Type } from "../../../generated/graphql";
import { useValidTeamAppContext } from "../../../v2/contexts/AppContext";
import { IIntegrationRedirect, paramsToObject } from '../../pages/IntegrationsPage';
import { IntegrationCardNew } from "./IntegrationCardNew";

interface IntegrationScrollerProps {
    integrations: Integration_Type[];
    urlParams: URLSearchParams;
}

export const IntegrationScroller = (props: IntegrationScrollerProps) => {
    const dispatch = useContext(AddNewIntegrationDispatchContext);
    const [searchedIntegrations, setSearchedIntegrations] = useState(props.integrations);
    const [displayCount, setDisplayCount] = useState(20); // Initial number of items to show
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [activeModal, setActiveModal] = useState<{
        integration: Integration_Type;
        redirect?: IIntegrationRedirect;
    } | null>(null);
    const { curTeamId: teamId } = useValidTeamAppContext();
    const hasCheckedOAuth = useRef(false);

    const handleScroll = () => {
        const container = scrollContainerRef.current;
        if (!container) return;

        // Check if we're near the bottom
        if (container.scrollHeight - container.scrollTop <= container.clientHeight + 100) {
            setDisplayCount(prev => Math.min(prev + 20, props.integrations.length));
        }
    };

    useEffect(() => {
        // Reset display count when integrations change (e.g. after search)
        setDisplayCount(20);
    }, [props.integrations]);

    // Check for OAuth redirect only once when component mounts
    useEffect(() => {
        if (hasCheckedOAuth.current) return;
        hasCheckedOAuth.current = true;

        const urlObject = paramsToObject(props.urlParams.entries());
        const { authToken, key: scraperKey, teamId: teamIdQuery, ...others } = urlObject;

        const matchingIntegration = props.integrations.find(
            integration => integration.scraperKey === scraperKey
        );

        if (authToken && matchingIntegration && Number(teamIdQuery) === teamId) {
            let redirect: IIntegrationRedirect = {
                authToken,
                scraperKey,
                teamId,
                additionalObjects: others,
            };

            const url_zendesk = props.urlParams.get('url_zendesk');
            if (url_zendesk) redirect.url_zendesk = url_zendesk;

            setActiveModal({
                integration: matchingIntegration,
                redirect
            });
        }
    }, []);

    const handleOpenModal = (integration: Integration_Type) => {
        if (
            integration.authRedirect &&
            !integration.title.includes('Zendesk') &&
            !integration.requirements.find((req) => req.requiredForAuthRedirect) &&
            (integration.teamIntegration?.length || integration.orgExternalTicketsIntegration?.length) === 0
        ) {
            const redirect = `${integration.authRedirect}&state=${encodeURIComponent(
                JSON.stringify({ 
                    redirect: window.location.href, 
                    teamId: teamId, 
                    scraperKey: integration.scraperKey,
                    showModal: true
                })
            )}`;
            window.location.replace(redirect);
        } else {
            setActiveModal({ integration });
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white border border-gray-200 shadow-md rounded-xl overflow-hidden w-full max-w-[700px] relative z-50">
                <div className="bg-blueberry_lighter bg-opacity-10 relative py-8 px-8 lg:px-14 text-center">
                    <button className="absolute top-3 right-3" onClick={() => {
                        dispatch({ type: 'closeIntegrationScroller' });
                    }}>
                        <XMarkIcon className="w-6 h-6 text-gray-400" />
                    </button>
                    <h3 className="text-2xl font-semibold text-blueberry mb-0">Add integration</h3>
                </div>
                <div className="py-10 px-8 lg:px-14">
                    <div className="mb-10">
                        <div className="relative flex w-full items-stretch text-gray-400 focus-within:text-gray-600">
                            <SearchInput 
                                placeholder="Browse integrations ...." 
                                onChange={(e) => {
                                    const query = e.target.value;
                                    if (query === '') {
                                        setSearchedIntegrations(props.integrations);
                                    } else {
                                        setSearchedIntegrations(props.integrations.filter(integration => integration.title.toLowerCase().includes(query.toLowerCase())));
                                    }
                                }}
                                onSearch={(query) => {
                                    if (query === '' || !query) {
                                        setSearchedIntegrations(props.integrations);
                                    } else {
                                        setSearchedIntegrations(props.integrations.filter(integration => integration.title.toLowerCase().includes(query.toLowerCase())));
                                    }
                                }}
                                onClear={() => {
                                    setSearchedIntegrations(props.integrations);
                                }} 
                                rounded="md"
                            />   
                        </div>
                    </div>
                    <div 
                        ref={scrollContainerRef}
                        onScroll={handleScroll}
                        className="max-h-[400px] overflow-y-auto border-t border-gray-200"
                    >
                        {searchedIntegrations.slice(0, displayCount).map((integration) => (
                            <IntegrationScrollerItem 
                                key={integration.id}
                                integration={integration}
                                onSelect={handleOpenModal}
                            />
                        ))}
                    </div>
                </div>
                {activeModal && (
                    <IntegrationCardNew 
                        modalOpen={true}
                        setModalOpen={(isOpen) => {
                            if (!isOpen) setActiveModal(null);
                        }}
                        integrationType={activeModal.integration}
                        integration={undefined}
                        redirect={activeModal.redirect}
                    />
                )}
            </div>
        </div>
    );
};