import { Digest_Group_Status, InsightLightFragment } from '../../../generated/graphql';

import Badge, { BadgeShape, BadgeSize } from '../../../baseComponents/Badge';
import Button, { ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import { Icon } from '@iconify/react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import InsightChartSection from '../digests/insightCharts/InsightChartSection';
import { toPresentableCase } from '../../../reducers/utilities/enumHandling';
import InsightEntryReview from '../digests/insights/InsightEntryReview';
import { buildFilterInputFromSavedFilterInput } from '../../pages/ChartsPage';
import { getGroupPageUrl } from '../../lib/groups';
import { AppRoutes } from '../../../Routes';

interface InsightSliderCardProps {
  insight: InsightLightFragment;
  onClick: () => void;
}

const InsightSliderCard = (props: InsightSliderCardProps) => {
  const { insight, onClick } = props;
  return (
    <a
      href={getGroupPageUrl(insight.team.id, insight.team.orgId!, insight.collection.id, undefined, [
        `group=${JSON.stringify(buildFilterInputFromSavedFilterInput(insight.filterInput, insight.filterInput.startDate, insight.filterInput.endDate))}`,
      ])}
      id="group-preview-card"
      className="bg-silver text-blueberry rounded-2xl min-h-80 cursor-pointer " /* onClick={onClick} */
    >
      <div className="bg-blueberry px-6 flex row justify-start items-center gap-x-4 pt-3 text-milk rounded-t-xl w-full h-16">
        <h3 className="text-2xl font-semibold leading-6">{insight.title}</h3>
        <Badge
          size={BadgeSize.Small}
          badge={{ id: '0', text: toPresentableCase(insight.feedbackStatus) }}
          capitalize={false}
          color={insight.feedbackStatus === Digest_Group_Status.NeedsImprovement ? 'bg-raspberry' : 'bg-success'}
          shape={BadgeShape.Pill}
          icon={
            insight.feedbackStatus === Digest_Group_Status.NeedsImprovement ? (
              <ExclamationCircleIcon className="h-5 w-5 stroke-2" />
            ) : (
              <CheckCircleIcon className="h-5 w-5 stroke-2" />
            )
          }
        />
      </div>
      <div className="px-6 py-2 flex flex-col gap-x-6 gap-y-1 min-h-[80%] rounded-b-2xl bg-silver">
        <div className="flex flex-row gap-x-2 h-full">
          <div className="w-2/3 gap-y-1">
            <div className="flex flex-col justify-between gap-y-2 h-full ">
              <p className="font-medium text-md md:text-md ">{insight.text}</p>
              <div className="grid grid-cols-[0.5rem_auto] gap-x-3 min-h-[8rem]">
                <div className="bg-blueberry w-[0.175rem] relative">
                  <Icon
                    icon="gridicons:quote"
                    className="py-1 text-blueberry bg-silver absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    width={28}
                    height={28}
                  />
                </div>
                <div className="flex flex-col gap-y-2 justify-evenly">
                  {insight.entries.map((entry) => {
                    return <InsightEntryReview key={entry.id} entry={entry} />;
                  })}
                </div>
              </div>
              <div className="flex flex-row items-start justify-center w-full">
                <Button text={'See all mentions'} variant={ButtonVariant.Secondary} size={ButtonSize.XSmall} />
              </div>
            </div>
          </div>
          <div className="w-1/3 h-[15rem]">
            <InsightChartSection insight={insight} />
          </div>
        </div>
      </div>
    </a>
  );
};

export default InsightSliderCard;
