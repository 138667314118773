import { useEffect, useState, useContext } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import IntegrationsPage from '../v3/pages/IntegrationsPage';
import { ProfilePage as ProfilePageV3 } from '../v3/pages/ProfilePage';
import AdminPage from '../v3/pages/AdminPage';
import InvalidLinkPage from './pages/InvalidLinkPage';
import DeniedAccessPage from './pages/DeniedAccessPage';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { useTeamFlagsLazyQuery, useGetOrganizationsLightLazyQuery, useTeamDefaultsLazyQuery } from '../generated/graphql';
import UserContext, { IUser } from './contexts/UserContext';
import { useIsMount } from './util';
import { AnalyticsTracking } from './AnalyticsUtil';
import { useOrganizationHook } from './hooks/OrganizationHook';
import DashboardLayout from '../v3/layouts/DashboardLayout/DashboardLayout';
import { FeedbackPage } from '../v3/pages/FeedbackPage';
import { AlertsPage as AlertsPageV3 } from '../v3/pages/AlertsPage';
import { AppRoutes, V3_DASHBOARD } from '../Routes';
import AppContext from './contexts/AppContext';
import { ReplyingPage } from '../v3/pages/ReplyingPage';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useReplyHook } from '../v3/hooks/ReplyHook';
import DigestPage from '../v3/pages/DigestPage';
import { ExplorePageRouter } from '../v3/pages/explore/ExplorePageRouter';
import HomePageRouter from '../v3/pages/home/HomePageRouter';
import { HomePageWithNoData } from '../v3/pages/home/HomePageWithNoData';
import { FeedbackAssistantPage } from '../v3/pages/FeedbackAssistantPage';
import { BoardsRouterPage } from '../v3/pages/boards/BoardsRouterPage';
import { OrganizationsRouterPage } from '../v3/pages/OrganizationsRouterPage';
import { FilterablePage } from '../v3/pages/FilterablePage';
import { GroupListPage } from '../v3/pages/GroupListPage';
import IntegrationSettingsPage from '../v3/pages/IntegrationSettingsPage';
import AddNewIntegrationPage from '../v3/pages/AddNewIntegrationPage';

/**
 * This is the Router for our logged-in-user accessible App Pages
 *
 * @returns
 */
export default function DashboardV2() {
  const [customerFacingAlertOpen, setCustomerFacingAlertOpen] = useState(false);
  const { identifyUser } = AnalyticsTracking();
  const { user } = useContext(UserContext);
  const {
    currentTeam,
    curOrgId,
    currentOrg,
    curTeamId,
    setTeamAndOrgFromUrlOrLocalStorage,
    setCurOrgId,
    setCurTeamId,
    orgsHaveLoaded,
    updateTeamDefaultValues,
  } = useContext(AppContext);
  const [fetchOrgs, _] = useGetOrganizationsLightLazyQuery({});
  const { addTeam, addOrganization } = useOrganizationHook();
  const { validIntegrations } = useReplyHook({});

  const navigate = useNavigate();

  const redirectToAccessRequested = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set('destination', window.location.pathname);
    setCurOrgId(null);
    setCurTeamId(null);
    navigate({ pathname: `/${V3_DASHBOARD}${AppRoutes.denied}`, search: urlSearchParams.toString() });
  };

  useEffect(() => {
    fetchOrgs({
      onCompleted: (data) => {
        //You can pass the orgList to set it from this function
        setTeamAndOrgFromUrlOrLocalStorage({
          orgList: data.getOrganizations ?? [],
          invalidTeamCallback: redirectToAccessRequested,
          invalidOrgCallback: redirectToAccessRequested,
        });
      },
    });
  }, []);

  const isFirstRender = useIsMount();

  useEffect(() => {
    identifyUser();
  }, []);

  const flagsLazyQuery = useTeamFlagsLazyQuery();
  const [getFlagsPromise, flags] = flagsLazyQuery;

  useEffect(() => {
    if (curTeamId && curOrgId && !isFirstRender) {
      getFlagsPromise({
        notifyOnNetworkStatusChange: true, //This updates the loading property on refetchs
        variables: { teamId: curTeamId },
      });
    }
  }, [curTeamId]);

  useEffect(() => {
    if (currentOrg && user?.isUnwrapper && currentOrg?.isCustomerFacing) {
      setCustomerFacingAlertOpen(true);
    } else {
      setCustomerFacingAlertOpen(false);
    }
  }, [currentOrg]);

  const [getTeamDefaults] = useTeamDefaultsLazyQuery({});
  useEffect(() => {
    if (!curTeamId || !!currentTeam?.defaultValues?.oldestFeedbackDate) return;
    getTeamDefaults({
      variables: { teamId: curTeamId },
      onCompleted(data) {
        updateTeamDefaultValues(curTeamId, data.teamDefaults);
      },
    });
  }, [curTeamId]);

  return (
    <div>
      {
        <>
          <Toaster
            position="top-right"
            toastOptions={{
              duration: 4000,
              className: '',
              style: {
                border: '1px solid white',
                fontFamily: 'sofiapro',
                backgroundColor: '#292E5B',
                zIndex: 1000,
                color: 'white',
                borderRadius: '1rem',
                minWidth: '8%',
              },
            }}
          >
            {(t) => (
              <ToastBar toast={t}>
                {({ icon, message }) => (
                  <div className="flex flex-row">
                    {icon}
                    {message}
                    {t.type !== 'loading' && (
                      <button className="px-1 pt-0.5" onClick={() => toast.dismiss(t.id)}>
                        <XMarkIcon className="w-4 h-4 outline-2" />
                      </button>
                    )}
                  </div>
                )}
              </ToastBar>
            )}
          </Toaster>
          <DashboardLayout
            customerFacingAlertOpen={customerFacingAlertOpen}
            setCustomerFacingAlertOpen={setCustomerFacingAlertOpen}
            flags={flags}
            addTeam={addTeam}
            addOrganization={addOrganization}
          >
            <Routes>
              <Route path="/*" key="homeWildCard" element={<Navigate to={AppRoutes.v3FullPath.home} />} />
              <Route
                path={`${AppRoutes.v3Pages.home}/*`}
                key="home"
                element={curOrgId && curTeamId ? <HomePageRouter pageName="Home Page" /> : <HomePageWithNoData />}
              />
              <Route
                path={`${AppRoutes.v3Pages.explore}/*`}
                key="explore"
                element={curOrgId && curTeamId ? <ExplorePageRouter pageName="Explore Page" /> : <NavigateToHome />}
              />
              <Route
                path={AppRoutes.v3Pages.integrations}
                key="integrations"
                element={curOrgId && curTeamId ? <IntegrationsPage pageName="Integrations Page" /> : <NavigateToHome />}
              />
              <Route
                path={`${AppRoutes.v3Pages.integrations}/:integrationId`}
                key="integrationSettings"
                element={curOrgId && curTeamId ? <IntegrationSettingsPage pageName="Integrations Settings Page" /> : <NavigateToHome />}
              />
              <Route
                path={AppRoutes.v3Pages.addNewIntegration}
                key="addNewIntegration"
                element={curOrgId && curTeamId ? <AddNewIntegrationPage pageName="Add New Integration Page" /> : <NavigateToHome />}
              />
              <Route
                path={AppRoutes.v3Pages.feedback}
                key="feedback"
                element={
                  curOrgId && curTeamId ? (
                    <FilterablePage teamId={curTeamId}>
                      <FeedbackPage pageName="Feedback Page" />
                    </FilterablePage>
                  ) : (
                    <NavigateToHome />
                  )
                }
              />
              <Route
                path={AppRoutes.v3Pages.replying}
                key="replying"
                element={
                  curOrgId && curTeamId && (validIntegrations.length > 0 || user?.isUnwrapper) ? (
                    <ReplyingPage pageName="Replying Page" />
                  ) : (
                    <Navigate to={`${AppRoutes.v3FullPath.feedback}${window.location.search}`} replace />
                  )
                }
              />
              <Route path={AppRoutes.v3Pages.alerts} key="alerts" element={curOrgId && curTeamId ? <AlertsPageV3 /> : <NavigateToHome />} />
              <Route
                path={`${AppRoutes.v3Pages.organizations}/*`}
                key="organizations"
                element={orgsHaveLoaded ? <OrganizationsRouterPage pageName={'Organizations'} /> : null}
              />
              // this is the boards and charts page the share the same filter context object Ideally you'd only get to the charts page in the context of a board
              <Route path={`${AppRoutes.v3Pages.boards}/*`} key="boards" element={<BoardsRouterPage />} />
              <Route path={AppRoutes.v3Pages.invalid} key="invalid" element={<InvalidLinkPage />} />
              <Route path={AppRoutes.denied} key="denied" element={<DeniedAccessPage />} />
              <Route path={AppRoutes.profile} key="profile" element={<ProfilePageV3 />} />
              <Route path={AppRoutes.v3Pages.assistant} key="assistant" element={<FeedbackAssistantPage />} />
              <Route key="invalid-redirect" element={<Navigate to={AppRoutes.v3FullPath.invalid} />} />
              {createUnwrapperRoutes({ user })}
            </Routes>
          </DashboardLayout>
        </>
      }
    </div>
  );
}

/**
 * This route checks if user is an unwrapper and if so adds the element and path to the route
 * @param user
 * @param element
 * @returns
 */
const createUnwrapperRoutes = (props: { user: IUser | undefined }): JSX.Element[] => {
  if (props.user?.isUnwrapper) {
    return [
      <Route key="admin" element={<AdminPage />} path={AppRoutes.v3Pages.admin} />,
      <Route key="digest" element={<DigestPage />} path={AppRoutes.v3Pages.digest} />,
      <Route key="group-list" element={<GroupListPage />} path={AppRoutes.v3Pages.groupList} />,
    ];
  }
  return [];
};

const NavigateToHome = () => <Navigate to={`${AppRoutes.v3FullPath.home}`} replace />;
