import { useState, useEffect, useRef } from 'react';

interface DropdownProps {
    values: string[];
    filterString: string;
    onSelect: (value: string) => void;
    isOpen: boolean;
    onClose: () => void;
    id: string;
}

export const Dropdown = ({ values, filterString, onSelect, isOpen, onClose, id }: DropdownProps) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    const filteredValues = values.filter(value => 
        filterString === '' || value.toLowerCase().includes(filterString.toLowerCase())
    );

    // Check if there's an exact match
    const hasExactMatch = values.some(value => 
        value.toLowerCase() === filterString.toLowerCase()
    );

    if (!isOpen) return null;

    return (
        <div 
            ref={dropdownRef}
            className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto"
            id={id}
        >
            <ul className="py-1">
                {filterString && !hasExactMatch && (
                    <li 
                        className="px-3 py-2 text-sm text-blueberry hover:bg-gray-100 cursor-pointer font-medium border-b border-gray-100"
                        onClick={() => {
                            onSelect(filterString);
                            onClose();
                        }}
                        id={`${id}-dropdown-item-0`}
                    >
                        Create: {filterString}
                    </li>
                )}
                {filteredValues.length > 0 ? (
                    filteredValues.map((value, index) => (
                        <li 
                            key={index}
                            className="px-3 py-2 text-sm text-blueberry hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                                onSelect(value);
                                onClose();
                            }}
                            id={`${id}-dropdown-item-${index + 1}`}
                        >
                            {value}
                        </li>
                    ))
                ) : filterString ? null : (
                    <li className="px-3 py-2 text-sm text-gray-500 italic">
                        No matches found
                    </li>
                )}
            </ul>
        </div>
    );
};
