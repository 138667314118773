import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { SyntheticEvent, useContext, useState } from 'react';
import { TaxonomyDispatchContext } from '../../context/TaxonomyDispatchContext';
import { ExpandState } from '../../actions/taxonomy';
import { Events, logEvent } from '../../../v2/AnalyticsUtil';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';
import { TaxonomyGroup } from '../../reducers/taxonomy';

export function ToggleExpandGroup(props: { taxonomyGroup: TaxonomyGroup; depth: number | undefined }) {
  const { currentTeam, currentOrg } = useValidTeamAppContext();
  const [loadingChildrenId, setLoadingChildrenId] = useState<string | undefined>();
  const taxonomyGroup = props.taxonomyGroup;
  const dispatch = useContext(TaxonomyDispatchContext);
  const onClick = (event: SyntheticEvent) => {
    const expandState = taxonomyGroup.showChildren ? ExpandState.Collapsed : ExpandState.Expanded;
    event.stopPropagation();
    setLoadingChildrenId(taxonomyGroup.id);
    dispatch({ type: 'toggleExpand', payload: { groupId: taxonomyGroup.id, state: expandState } });
    logEvent(Events.TaxonomyToggled, {
      View_ID: currentTeam.id,
      View_Name: currentTeam.name,
      Org_ID: currentOrg.id,
      Org_Name: currentOrg.name,
      Toggle_Type: taxonomyGroup.showChildren ? 'Close' : 'Open',
      Depth: props.depth,
    });
    setLoadingChildrenId(undefined);
  };

  if (taxonomyGroup.totalDescendents === 0) return <></>;

  return (
    <div onClick={onClick} className="flex items-center absolute -left-16 top-1/2 transform -translate-y-1/2 select-none">
      <div className="flex opacity-100 duration-[150ms] text-blueberry transition-all mr-2 w-[36px] justify-end">({taxonomyGroup.totalDescendents})</div>
      <div
        className={`text-sm hover:scale-[120%] ${taxonomyGroup.showChildren ? 'mt-2' : 'transform -rotate-90'} transition-all duration-[100ms] cursor-pointer `}
      >
        {loadingChildrenId === taxonomyGroup.id ? <AdjustableLoadingIcon width={4} height={4} /> : <p>▼</p>}
      </div>
    </div>
  );
}
