import { Operator } from '../../../generated/graphql';

/**
 * Adapts the operator string to a more human readable format for displaying on the frontend.
 * @param operator
 * @returns
 */
export const adaptForDisplay = (operator: string) => {
  switch (operator) {
    case '==':
      return 'is';
    case '!=':
      return 'is not';
    case '>=':
      return '>=';
    case '<=':
      return '<=';
    case '~=':
      return 'is like';
    case 'in':
      return 'is one of';
    case '!in':
      return 'is not one of';
    default:
      return operator;
  }
};

/**
 * Adapts the operator to a format that can be used in the filter node.
 * This is what is sent to the backend.
 * example: IN -> in
 * @param operator
 * @returns
 */
export const adaptForFilterNode = (operator: Operator) => {
  switch (operator) {
    case Operator.In:
      return 'in';
    case Operator.NotIn:
      return '!in';
    case Operator.Equal:
      return '==';
    case Operator.NotEqual:
      return '!=';
    case Operator.GreaterThanOrEqual:
      return '>=';
    case Operator.LessThanOrEqual:
      return '<=';
    case Operator.Like:
      return '~=';
    default:
      return operator;
  }
};

/**
 * Adapts the string operator to a format that can be used in the filter node.
 * This is what is used on the frontend.
 * example: in -> IN
 * @param operator
 * @returns
 */
export const adaptForOperator = (operator: string): Operator => {
  switch (operator) {
    case '==':
      return Operator.Equal;
    case '!=':
      return Operator.NotEqual;
    case '>=':
      return Operator.GreaterThanOrEqual;
    case '<=':
      return Operator.LessThanOrEqual;
    case '~=':
      return Operator.Like;
    case 'in':
      return Operator.In;
    case '!in':
      return Operator.NotIn;
    default:
      return operator as Operator;
  }
};

/**
 * Normalizes the operator to a more human readable format.
 * example: NOT_EQUAL -> Not Equal
 * @param operator
 * @returns
 */
export const normalizeOperator = (operator: Operator) => {
  switch (operator) {
    case Operator.In:
      return 'Is One Of';
    case Operator.NotIn:
      return 'Is Not One Of';
    case Operator.Equal:
      return 'Is';
    case Operator.NotEqual:
      return 'Is Not';
    case Operator.GreaterThanOrEqual:
      return 'Greater Than Or Equal';
    case Operator.LessThanOrEqual:
      return 'Less Than Or Equal';
    case Operator.Like:
      return 'Is Like';
    default:
      return operator;
  }
};

/**
 * Checks if the operator supports multiple values.
 * example: Entry.source in ('a', 'b')
 * @param operator
 * @returns
 */
export const operatorSupportsMultipleValues = (operator: Operator) => {
  return [Operator.In, Operator.NotIn].includes(operator);
};
