import { IDropDownItem } from '../../baseComponents/DropDown';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { groupBy } from 'underscore';
import React from 'react';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import { classNames } from '../../v2/util';
import { Float } from '@headlessui-float/react';
import toast from 'react-hot-toast';

export interface ISettingsItem extends IDropDownItem {
  icon?: JSX.Element;
  onClick: () => void;
  group: string;
  textColor?: 'normal' | 'failure';
  keepMenuOpen?: boolean;
  disabled?: boolean;
}

const TextColorMap = {
  normal: 'text-blueberry',
  failure: 'text-failure',
};

export enum MenuAlign {
  RIGHT = 0,
  LEFT = 1,
}

interface SettingsMenuProps {
  settings: ISettingsItem[];
  children: JSX.Element;
  align?: MenuAlign;
  disable?: boolean;
  showLock?: boolean;
  center?: boolean;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({ settings, align, disable, children, showLock, center }) => {
  const groupedSettings = groupBy(settings, (setting) => setting.group);

  // example for how to capture menu closed https://github.com/tailwindlabs/headlessui/issues/239
  // We might want to do this to darken the screen behind the menu and undarken when menu is closed.

  return (
    <div className={classNames('flex', center ? 'flex items-center justify-center' : '')}>
      <div className={classNames('text-right', center ? 'flex items-center justify-center' : '')} aria-hidden="true" data-cy="settings-menu">
        <Menu as="div" className={classNames('relative inline-block text-left', center ? 'flex items-center justify-center' : '')}>
          <Float
            strategy="fixed"
            placement={align === MenuAlign.RIGHT || !align ? 'bottom-end' : 'bottom-start'}
            zIndex={9999}
            offset={4}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className={classNames(center ? 'flex items-center justify-center' : '')}>
              <Menu.Button className="inline-flex justify-center" disabled={disable}>
                {children}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={classNames(
                  'z-50 selection:w-60 divide-y divide-gray-100 border-2 border-gray-200 shadow-md rounded-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none lg:w-60',
                  align === MenuAlign.RIGHT || !align ? 'right-0 ' : ''
                )}
              >
                {Object.keys(groupedSettings).map((key, index) => {
                  return (
                    <div key={index}>
                      {groupedSettings[key].map((setting, idx) => {
                        let textColor = TextColorMap[setting.textColor || 'normal'];
                        return (
                          <Menu.Item key={idx} disabled={setting.disabled}>
                            {({ active }) => (
                              <button
                                data-testid={setting.htmlId}
                                name={setting.htmlId}
                                disabled={setting.disabled}
                                className={`${
                                  active ? 'bg-silver' : ''
                                } text-md group flex w-full select-none items-center justify-between rounded-md px-4 py-2 duration-150 ${
                                  setting.disabled ? 'bg-grey-2 cursor-not-allowed' : ''
                                } ${textColor}`}
                                onClick={(e) => {
                                  if (setting.keepMenuOpen) {
                                    e.preventDefault();
                                  }
                                  if (setting.disabled) {
                                    toast.error('You do not have permission to perform this action.');
                                  }
                                  setting.onClick();
                                }}
                                key={idx}
                              >
                                <div id={setting.htmlId} className="text-md lg:text-md flex flex-row items-center gap-x-3">
                                  {setting.icon && (
                                    <div id={setting.htmlId} className={textColor}>
                                      {setting.icon}
                                    </div>
                                  )}
                                  {setting.name}
                                </div>
                                {showLock ? <LockClosedIcon className="h-4 w-4 opacity-60" /> : undefined}
                              </button>
                            )}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  );
                })}
              </Menu.Items>
            </Transition>
          </Float>
        </Menu>
      </div>
    </div>
  );
};

export default SettingsMenu;
