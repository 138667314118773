import { useContext, useEffect, useState } from 'react';

import { FilterContext } from '../../../../context/filterStatementContext';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { FilterBar } from './FilterBar';
import { FilterNode } from '../../../lib/filterNode';

/**
 * The filter bar presents filter configuration to the user in a minimalistic way by progressively revealing the configuration options.
 * The user can select a field, an operator, and then add values to the filter.
 * The filter configuration is then saved to the filter statement tree.
 *
 * The filter bar needs to know what filters are currently applied so that it can exclude them from the list of available fields.
 * The filter bar should always be rendered in a component tree that is wrapped in a FilterablePage HOC.
 */
const FilterBarWrapper = () => {
  const filterState = useContext(FilterContext);
  const { curTeamId, curOrgId } = useValidTeamAppContext();

  useEffect(() => {
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);

    const filterNode = new FilterNode(filterState);
    urlParams.set('teamId', curTeamId.toString());
    urlParams.set('orgId', curOrgId.toString());
    urlParams.set('filterSet', filterNode.getUrlEncodedFilterNode());

    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }, [filterState.appliedFilter, filterState.staticConditions, curTeamId, curOrgId]);

  return <FilterBar />;
};

export default FilterBarWrapper;
