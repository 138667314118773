import { useContext, useEffect } from 'react';
import { FilterInput, GroupDataFragment, useGetGroupLazyQuery, useGetGroupStatusLazyQuery } from '../../generated/graphql';
import AppContext from '../contexts/AppContext';
import { TaxonomyDispatchContext } from '../../v3/context/TaxonomyDispatchContext';

export const useSearchGroupPolling = ({
  teamId,
  searchGroupId,
  replaceOrAddToSearchGroups,
  //This searchProcessing is only to return from the hook in already completed searches. Kinda hacky.
  searchProcessing,
  updateProgress,
  filterInput,
}: {
  teamId: number;
  searchGroupId: string;
  replaceOrAddToSearchGroups: (newSearchGroup: GroupDataFragment) => void;
  searchProcessing: boolean;
  updateProgress: (searchGroupId: string, newProgress: number) => void;
  filterInput?: FilterInput;
}) => {
  const { app } = useContext(AppContext);
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const [getGroupQuery, _] = useGetGroupLazyQuery({});

  const fetchAndAddSearchGroup = (searchGroupId: string) => {
    getGroupQuery({
      variables: { teamId, groupId: searchGroupId, teamUuid: app?.currentUuid, filterInput: filterInput ?? {} },
      onCompleted(data) {
        if (data?.getGroup) {
          taxonomyDispatch({ 
            type: 'addGroup', 
            payload: { ...data.getGroup, groupId: data.getGroup.id }
          });
        }
      },
    });
  };

  const [getSearchGroupStatus, { startPolling, stopPolling }] = useGetGroupStatusLazyQuery({
    variables: { teamId, groupId: searchGroupId, teamUuid: app?.currentUuid },
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (data.getGroup.processing) {
        updateProgress(searchGroupId, data.getGroup.progress);
      } else {
        //Processing finished, fetch and store the SearchGroup
        fetchAndAddSearchGroup(searchGroupId);
        stopPolling();
      }
    },
  });

  useEffect(() => {
    if (searchProcessing) startPolling(10_000);
  }, []);
};
